import DrawerWrapper from "antd/lib/drawer";
import style from "./style.module.scss";
import yamBlue from "../../assets/images/yamBlue.png";
import jillogo from "../../assets/img/hero-img.png";
import QRCode from "react-qr-code";
import ReactToPrint from "react-to-print";
import { useRef } from "react";
import { AiFillPrinter } from "react-icons/ai";
import ebudgeQr from "../../assets/images/ebudgeQr.jpg";
export default function QrBack() {
  const componentRef = useRef();

  const encodeTest = (e) => {
    if (e) {
      e = e.replace(/\r\n/g, "\n");
      var t = "";
      for (var n = 0; n < e.length; n++) {
        var r = e.charCodeAt(n);
        if (r < 128) {
          t += String.fromCharCode(r);
        } else if (r > 127 && r < 2048) {
          t += String.fromCharCode((r >> 6) | 192);
          t += String.fromCharCode((r & 63) | 128);
        } else {
          t += String.fromCharCode((r >> 12) | 224);
          t += String.fromCharCode(((r >> 6) & 63) | 128);
          t += String.fromCharCode((r & 63) | 128);
        }
      }
      return t;
    }
  };
  const backBody = (
    <tbody>
      {[...Array(72)].map((item, idx) => {
        if (idx % 2 === 1) {
          return (
            <tr>
              <td>
                <div key={idx} className={style.card}>
                  <div className={style.backTitleCon}>
                    <p>Сорил:</p>
                    <div>
                      <span> EXAM.EDU.MN</span>
                      <span>
                        нэвтрэх нэр:
                        <strong> esis{idx < 10 ? `0${idx}` : idx}</strong>
                      </span>
                      <span>
                        нууц үг:
                        <strong> 123456</strong>
                      </span>
                    </div>
                  </div>
                  <div className={style.backTitleCon}>
                    <p>Асуулга:</p>
                    <div>
                      <span>IFORM.ESIS.EDU.MN</span>
                    </div>
                  </div>
                  <div className={style.cardTitle}>
                    <p>Сургалт:</p>
                    <div>
                      <img src={ebudgeQr} />
                      <span>EBUDGET.EDU.MN</span>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div
                  key={idx}
                  //   style={{ backgroundImage: ` url(${mandatBack})` }}
                  className={style.card}
                >
                  <div className={style.backTitleCon}>
                    <p>Сорил:</p>
                    <div>
                      <span> EXAM.EDU.MN</span>
                      <span>
                        нэвтрэх нэр:
                        <strong>
                          {" "}
                          esis{idx < 10 ? `0${idx + 1}` : idx + 1}
                        </strong>
                      </span>
                      <span>
                        нууц үг:
                        <strong> 123456</strong>
                      </span>
                    </div>
                  </div>
                  <div className={style.backTitleCon}>
                    <p>Асуулга:</p>
                    <div>
                      <span>IFORM.ESIS.EDU.MN</span>
                    </div>
                  </div>
                  <div className={style.cardTitle}>
                    <p>Сургалт:</p>
                    <div>
                      <img src={ebudgeQr} />
                      <span>EBUDGET.EDU.MN</span>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          );
        }
      })}
    </tbody>
  );
  return (
    <div className={style.container}>
      <ReactToPrint
        // onBeforePrint={() =>
        //   // setState({
        //   //   ...state,
        //   //   loader: true,
        //   // })
        // }
        trigger={() => <button icon={<AiFillPrinter />}>Хэвлэх</button>}
        content={() => componentRef.current}
      />
      <table ref={componentRef}>{backBody}</table>
    </div>
  );
}
