import React, { useEffect } from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

import Home from "./pages/Home";
import Home24 from "./pages/Home2024";
import InfoGraphic from "./pages/InfoGraphic";
import QrCode from "./components/QrCode/QrCode";
import QrBack from "./components/QrBack/QrBack";
import "./App.scss";
import Home25 from "./pages/Home2025";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
    //AOS.refresh();
  }, []);

  return (
    <div className="App">
      {/* <Header /> */}
      <HashRouter>
        <Routes>
          <Route path="/" element={<Home25 />} />
          <Route path="/2024" element={<Home24 />} />
          <Route path="/2022" element={<Home />} />
          <Route path="/qr" element={<QrCode />} />
          <Route path="/qr/back" element={<QrBack />} />
          <Route path="/infoGraphic" element={<InfoGraphic />} />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
