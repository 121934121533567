import React, { useState } from "react";
import map3 from "../../../assets/img/locations2023/map3.png"
import moment from "moment";
import locIcon from "../../../assets/img/location-icon.png";
import _ from "lodash";

const schedule = [
  {
    name: "Дундговь",
    start: "11/17/2022",
    end: "11/18/2022",
  },
  {
    name: "Өмнөговь",
    start: "11/14/2022",
    end: "11/15/2022",
  },
  {
    name: "Ховд",
    start: "11/14/2022",
    end: "11/15/2022",
  },
  {
    name: "Говьсүмбэр",
    start: "11/17/2022",
    end: "11/18/2022",
  },
  {
    name: "Говь-Алтай",
    start: "11/17/2022",
    end: "11/18/2022",
  },
  {
    name: "Баянхонгор",
    start: "11/14/2022",
    end: "11/15/2022",
  },

  {
    name: "Дорнод",
    start: "11/14/2022",
    end: "11/15/2022",
  },
  {
    name: "Хөвсгөл",
    start: "11/14/2022",
    end: "11/15/2022",
  },
  {
    name: "Архангай",
    start: "11/17/2022",
    end: "11/18/2022",
  },
  {
    name: "Завхан ",
    start: "11/14/2022",
    end: "11/15/2022",
  },
  {
    name: "Дорноговь ",
    start: "11/17/2022",
    end: "11/18/2022",
  },
  {
    name: "Булган ",
    start: "11/17/2022",
    end: "11/18/2022",
  },
  {
    name: "Төв  ",
    start: "11/14/2022",
    end: "11/15/2022",
  },
  {
    name: "Өвөрхангай ",
    start: "11/17/2022",
    end: "11/18/2022",
  },
  {
    name: "Сүхбаатар ",
    start: "11/17/2022",
    end: "11/18/2022",
  },
  {
    name: "Увс ",
    start: "11/14/2022",
    end: "11/15/2022",
  },
  {
    name: "Сэлэнгэ ",
    start: "11/17/2022",
    end: "11/18/2022",
  },
  {
    name: "Баян-Өлгий",
    start: "11/17/2022",
    end: "11/18/2022",
  },
  {
    name: "Орхон  ",
    start: "11/17/2022",
    end: "11/18/2022",
  },
  {
    name: "Хэнтий  ",
    start: "11/14/2022",
    end: "11/15/2022",
  },
  {
    name: "Дархан-Уул  ",
    start: "11/14/2022",
    end: "11/15/2022",
  },
];

// const arr = _.sortBy(schedule, function (dateObj) {
//   return new Date(dateObj.start);
// });
// console.log("arr", arr);

export default function Map() {
  // const [data, setData] = useState([]);

  return (
    <div className="container mb-5">
      <div class="section-title" data-aos="fade-up">
        <h2>Уулзалт</h2>
        <p>{moment().format("YYYY-MM-DD")}</p>
      </div>
      <img src={map3} className="w-100" alt=""/>

      <div className="w-100 d-flex justify-conten-center">
        <div className="m-auto mt-2">
          <img src={locIcon} height={40} className="me-2 " alt=""></img>
          Өнөөдрийн уулзалт.
        </div>
      </div>
      <div class="section-title mt-5" data-aos="fade-up">
        <h2>Хуваарь</h2>
        <p>ТӨСВИЙН ШИНЭЧЛЭЛ 2022 УУЛЗАЛТ</p>
      </div>
      <div className="att-table-container ">
        <table>
          <thead>
            <tr>
              <th style={{ textAlign: "left" }}>№</th>
              <th style={{ textAlign: "left" }}>Аймаг</th>
              <th colSpan={2} style={{ textAlign: "left" }}>
                Уулзалт болох өдөр
              </th>
            </tr>
          </thead>
          <tbody>
            {schedule.map((el, i) => (
              <tr>
                <td>{i + 1}</td>
                <td>{el.name}</td>
                <td>{el.start}</td>
                <td>{el.end}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
