import React from "react";
// import video1 from "../../../assets/video/115r-surguuli.mp4";
// import video2 from "../../../assets/video/2022 oni HZ podcast.mp4";
// import video3 from "../../../assets/video/Бцс Төсвийн Шинэчлэл1.mp4";
// import video4 from "../../../assets/video/1.mp4";
// import video5 from "../../../assets/video/71 сургууль.mp4";
// import video6 from "../../../assets/video/142.mp4";
// import video7 from "../../../assets/video/munguntsetseg.mp4";
// import video8 from "../../../assets/video/munkhjargal.mp4";
// import video9 from "../../../assets/video/Мэргэд.mp4";
// import video10 from "../../../assets/video/Орхон Лаборатори 8-р сургууль төсөв.mp4";
// import video11 from "../../../assets/video/Сэлэнгэ аймаг лаборатори IV сургууль.mp4";
// import video12 from "../../../assets/video/143 сургууль (1).mp4";

const videos = [
  // {
  //   name: "1",
  //   src: video1

  // },
  // {
  //   name: "2",
  //   src: video2
  // },
  // {
  //   name: "3",
  //   src: video3
  // },
  // {
  //   name: "4",
  //   src: video4,
  // },
  // {
  //   name: "5",
  //   src: video5,
  // },
  // {
  //   name: "6",
  //   src: video6
  // },
  // {
  //   name: "7",
  //   src: video7
  // },
  // {
  //   name: "8",
  //   src: video8
  // },
  // {
  //   name: "9",
  //   src: video9
  // },
  // {
  //   name: "10",
  //   src: video10
  // },
  // {
  //   name: "11",
  //   src: video11
  // },
  {
    name: "12",
    src: "https://cdn.esis.edu.mn/ebudget/video/01.mp4",
  },
  {
    name: "13",
    src: "https://cdn.esis.edu.mn/ebudget/video/02.mp4",
  },
  {
    name: "14",
    src: "https://cdn.esis.edu.mn/ebudget/video/03.mp4",
  },
  {
    name: "15",
    src: "https://cdn.esis.edu.mn/ebudget/video/04.mp4",
  },
  {
    name: "16",
    src: "https://cdn.esis.edu.mn/ebudget/video/05.mp4",
  },
  {
    name: "17",
    src: "https://cdn.esis.edu.mn/ebudget/video/06.mp4",
  },
  {
    name: "18",
    src: "https://cdn.esis.edu.mn/ebudget/video/07.mp4",
  },
  {
    name: "19",
    src: "https://cdn.esis.edu.mn/ebudget/video/08.mp4",
  },
  {
    name: "20",
    src: "https://cdn.esis.edu.mn/ebudget/video/09.mp4",
  },
  // {
  //   name: "21",
  //   src: video12,
  // },
];
export default function Videos() {
  return (
    <div className="container">
      <div class=" section-title" data-aos="fade-up">
        <h2>Бичлэг</h2>
        <p>ТӨСВИЙН ШИНЭЧЛЭЛ 2022 УУЛЗАЛТ</p>
      </div>
      <div className="row gy-4">
        {videos.map((el, i) => (
          <div className="col-sm-3 col-video d-flex flex-column">
            <video
              key={i}
              onMouseOver={(event) => event.target.play()}
              onMouseOut={(event) => event.target.pause()}
              controls
            >
              <source src={el.src} type="video/mp4"></source>
            </video>
          </div>
        ))}
      </div>
    </div>
  );
}
