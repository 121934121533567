import React from "react";
import logoFooter from "../../../assets/img/logo_footer.png";

export default function Footer() {
  return (
    <footer id="footer">
      <div class="footer-top">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-6 footer-newsletter">
              <img src={logoFooter} class="testimonial-img" alt="" />
            </div>

            <div class="col-lg-6 col-md-6">
              <div class="footer-info">
                <h3>ХОЛБОО БАРИХ</h3>
                <p class="pb-3">
                  Улаанбаатар хот, Сүхбаатар дүүрэг, Бага тойруу-44, Засгийн
                  газрын III байр
                </p>
                <p>
                  <strong>Phone:</strong>{" "}
                  <a href="tel:+976 1800 1001"> +976 1800 1001</a>
                  <br></br>
                  <strong>Email:</strong>{" "}
                  <a href="mailto:letter@mecs.gov.mn">letter@mecs.gov.mn</a>
                  <br></br>
                </p>
                <div class="social-links mt-3">
                  <a
                    href="https://www.facebook.com/MinistryofEdu/"
                    class="facebook"
                    target="_blank"
                  >
                    <i class="bx bxl-facebook"></i>
                  </a>
                  <a
                    href="https://twitter.com/MEDSMongolia"
                    class="twitter"
                    target="_blank"
                  >
                    <i class="bx bxl-twitter"></i>
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCIgop7wqZmSWHczm9wM1IPw/videos"
                    class="youtube"
                    target="_blank"
                  >
                    <i class="bx bxl-youtube"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="copyright">
          &copy; 2022 Боловсролын мэдээллийн технологийн төвд бүтээв
        </div>
      </div>
    </footer>
  );
}
