import React, { useState, useEffect } from "react";
import map1 from "../../../assets/img/locations2023/map1.png";
import moment from "moment";
import locIcon from "../../../assets/img/location-icon.png";
import _ from "lodash";
import GeoJSONMap from "../../../components/2024/map";
import PieChart from "../../../components/PieChart";
import scheduleData from "../../../components/2024/schedule.json";
import schedule from "./scheduleData";

export default function Map() {
  const [getId, setGetId] = useState({
    name: "Улсын хэмжээнд",
    id: "",
  });
  const [getInfo, setGetInfo] = useState({
    name: "",
    school_cover: "",
    kinder_cover: "",
  });
  const [lat, setLang] = useState({
    lattitude: "",
    longitude: "",
  });

  const handleFeatureMouseOver = (e) => {
    const name = e.target.feature.properties.name;
    const sub = e.target.feature.properties.sub_coverage;
    const ebs = e.target.feature.properties.ebs_coverage;

    setLang({
      lattitude: e.latlng.lat,
      longitude: e.latlng.lng,
    });

    setGetInfo({
      name: name,
      school_cover: ebs,
      kinder_cover: sub,
    });
  };

  const handleFeatureMouseOut = () => {
    setGetInfo({
      name: null,
      school_cover: null,
      kinder_cover: null,
    });
  };

  const handleFeatureMouseClick = (e) => {
    console.log("eeeee", e);

    const info_name = e.target.feature.properties.name;
    const info_id = e.target.feature.properties.aimag_city_ID;

    setGetId({
      name: info_name,
      id: info_id,
    });
  };

  return (
    <div className="container">
      <div class="section-title" data-aos="fade-up">
        <h2>Уулзалт</h2>
        <p>2024/02/28-2024/03/06</p>
      </div>
      <div style={{ marginBottom: "5px" }}>
        <GeoJSONMap
          handleFeatureMouseOver={handleFeatureMouseOver}
          setGetInfo={setGetInfo}
          getInfo={getInfo}
          handleFeatureMouseOut={handleFeatureMouseOut}
          lat={lat}
          handleFeatureMouseClick={handleFeatureMouseClick}
        />
      </div>
      <div>
        <h2 style={{ color: "#010483" }}>
          {getId.name ? getId.name : "Улсын хэмжээнд"}
        </h2>
      </div>
      <div className="mb-3">
        {scheduleData?.map(
          (item) =>
            item.aimag_name == getId.name && (
              <div key={item.aimag_name}>
                <div
                  className="att-chart-container"
                  style={{ display: "flex", direction: "row" }}
                >
                  <PieChart
                    label={"Цэцэрлэг"}
                    percent={100}
                    count={item.data.sub}
                    color="#ec4899"
                  />
                  <PieChart
                    label={"Сургууль"}
                    percent={100}
                    count={item.data.ebs}
                    color="#a78bfa"
                  />
                  <PieChart
                    label={"ЕБС оролцогч "}
                    percent={100}
                    count={item.data.ebs_participant}
                    color="#10b981"
                  />
                  <PieChart
                    label={"СӨБ оролцогч"}
                    percent={100}
                    count={item.data.sub_participant}
                    color="#6366f1"
                  />
                </div>
                <h2 style={{ color: "#010483" }}>{"Хамрагдалт"}</h2>
                <div
                  className="att-chart-container"
                  style={{ display: "flex", direction: "row" }}
                >
                  <PieChart
                    label={"Цэцэрлэг"}
                    percent={100}
                    count={item.coverage.sub}
                    color="#ec4899"
                  />
                  <PieChart
                    label={"Сургууль"}
                    percent={100}
                    count={item.coverage.ebs}
                    color="#a78bfa"
                  />
                  <PieChart
                    label={"ЕБС оролцогч "}
                    percent={100}
                    count={item.coverage.ebs_participant}
                    color="#10b981"
                  />
                  <PieChart
                    label={"СӨБ оролцогч"}
                    percent={100}
                    count={item.coverage.sub_participant}
                    color="#6366f1"
                  />
                </div>
              </div>
            )
        )}
      </div>

      <div class="section-title mt-5" data-aos="fade-up">
        <h2>Хуваарь</h2>
        <p>ТӨСВИЙН ШИНЭЧЛЭЛ 2024 УУЛЗАЛТ</p>
      </div>
      <div className="att-table-container ">
        <table>
          <thead>
            <tr>
              <th style={{ textAlign: "left" }}>№</th>
              <th style={{ textAlign: "left" }}>Аймаг</th>
              <th style={{ textAlign: "left" }}>Уулзалт болох өдөр</th>
              <th style={{ textAlign: "left" }}>Цаг</th>
              <th style={{ textAlign: "left" }}>Линк</th>
            </tr>
          </thead>
          <tbody>
            {schedule.map((el, i) => (
              <tr>
                <td>{i + 1}</td>
                <td>{el.name}</td>
                <td>{el.dateFrom}</td>
                <td>{el.dateTo}</td>
                <td>
                  <a
                    href={
                      el.link != ""
                        ? el.link
                        : "javascript:alert('Уулзалтын онлайн хурал эхлэх болоогүй');"
                    }
                    target={el.link != "" && "_blank"}
                  >
                    Оролцох
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
