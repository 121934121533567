import React, { useEffect, useState } from "react";
import Charts from "./Charts";
import Schedule from "./Schedule";
import imgLogo from "../../assets/img/yamLogo.png";
import logoFooter from "../../assets/img/logo_footer.png";
import { BsArrowUpShort } from "react-icons/bs";
import "react-vertical-timeline-component/style.min.css";
import "react-image-lightbox/style.css";
import bgMain from "../../assets/2025_img/1.png";
import imgTitle from "../../assets/2025_img/2.png";
import Said from "../../components/2025/newsaid.mp4";
import footerBg from "../../assets/img/footer_bg.jpg";
import Map from "./Map";
import { Link } from "react-router-dom";
import { Button, Select } from "antd";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export default function Home25() {
  const navigate = useNavigate();
  const [value, setValue] = useState("/");
  const handleChange = (value) => {
    console.log(value);
    if (value) {
      setValue(value);
      navigate(value);
    }
  };

  useEffect(() => {
    /**
     * Easy selector helper function
     */
    const select = (el, all = false) => {
      el = el.trim();
      if (all) {
        return [...document.querySelectorAll(el)];
      } else {
        return document.querySelector(el);
      }
    };

    /**
     * Easy event listener function
     */
    const on = (type, el, listener, all = false) => {
      let selectEl = select(el, all);
      if (selectEl) {
        if (all) {
          selectEl.forEach((e) => e.addEventListener(type, listener));
        } else {
          selectEl.addEventListener(type, listener);
        }
      }
    };

    /**
     * Easy on scroll event listener
     */
    const onscroll = (el, listener) => {
      el.addEventListener("scroll", listener);
    };

    /**
     * Navbar links active state on scroll
     */
    let navbarlinks = select("#navbar .scrollto", true);
    const navbarlinksActive = () => {
      let position = window.scrollY + 200;
      navbarlinks.forEach((navbarlink) => {
        if (!navbarlink.hash) return;
        let section = select(navbarlink.hash);
        if (!section) return;
        if (
          position >= section.offsetTop &&
          position <= section.offsetTop + section.offsetHeight
        ) {
          navbarlink.classList.add("active");
        } else {
          navbarlink.classList.remove("active");
        }
      });
    };
    window.addEventListener("load", navbarlinksActive);
    onscroll(document, navbarlinksActive);

    /**
     * Scrolls to an element with header offset
     */
    const scrollto = (el) => {
      let header = select("#header");
      let offset = header.offsetHeight;

      if (!header.classList.contains("header-scrolled")) {
        offset -= 20;
      }

      let elementPos = select(el).offsetTop;
      window.scrollTo({
        top: elementPos - offset,
        behavior: "smooth",
      });
    };

    /**
     * Toggle .header-scrolled class to #header when page is scrolled
     */
    let selectHeader = select("#header");
    if (selectHeader) {
      const headerScrolled = () => {
        if (window.scrollY > 100) {
          selectHeader.classList.add("header-scrolled");
        } else {
          selectHeader.classList.remove("header-scrolled");
        }
      };
      window.addEventListener("load", headerScrolled);
      onscroll(document, headerScrolled);
    }

    /**
     * Back to top button
     */
    let backtotop = select(".back-to-top");
    if (backtotop) {
      const toggleBacktotop = () => {
        if (window.scrollY > 100) {
          backtotop.classList.add("active");
        } else {
          backtotop.classList.remove("active");
        }
      };
      window.addEventListener("load", toggleBacktotop);
      onscroll(document, toggleBacktotop);
    }

    /**
     * Mobile nav toggle
     */
    on("click", ".mobile-nav-toggle", function (e) {
      select("#navbar").classList.toggle("navbar-mobile");
      this.classList.toggle("bi-list");
      this.classList.toggle("bi-x");
    });

    /**
     * Mobile nav dropdowns activate
     */
    on(
      "click",
      ".navbar .dropdown > a",
      function (e) {
        if (select("#navbar").classList.contains("navbar-mobile")) {
          e.preventDefault();
          this.nextElementSibling.classList.toggle("dropdown-active");
        }
      },
      true
    );

    /**
     * Scrool with ofset on links with a class name .scrollto
     */
    on(
      "click",
      ".scrollto",
      function (e) {
        if (select(this.hash)) {
          e.preventDefault();

          let navbar = select("#navbar");
          if (navbar.classList.contains("navbar-mobile")) {
            navbar.classList.remove("navbar-mobile");
            let navbarToggle = select(".mobile-nav-toggle");
            navbarToggle.classList.toggle("bi-list");
            navbarToggle.classList.toggle("bi-x");
          }
          scrollto(this.hash);
        }
      },
      true
    );

    window.addEventListener("load", () => {
      if (window.location.hash) {
        if (select(window.location.hash)) {
          scrollto(window.location.hash);
        }
      }
    });

    let preloader = select("#preloader");
    if (preloader) {
      window.addEventListener("load", () => {
        preloader.remove();
      });
    }
  }, []);

  return (
    <div>
      <header
        id="header"
        class="fixed-top d-flex align-items-center header-transparent"
      >
        <div class="container d-flex align-items-center justify-content-between">
          <div class="logo">
            <a href="/">
              <img
                src={"https://cdn.esis.edu.mn/logo/bshuy1.png"}
                alt=""
                class="img-fluid"
              />
            </a>
          </div>

          <nav id="navbar" class="navbar">
            <ul>
              <li>
                <a class="nav-link scrollto active" href="#hero">
                  Эхлэл
                </a>
              </li>
              <li>
                <a class="nav-link scrollto" href="#schedule">
                  Хөтөлбөр
                </a>
              </li>

              <li>
                <a class="nav-link scrollto" href="#features">
                  Статистик
                </a>
              </li>

              <li>
                <Link to="/infoGraphic" className="nav-link">
                  Инфографик
                </Link>
              </li>

              <li>
                <a class="nav-link scrollto" href="#footer">
                  Холбоо барих
                </a>
              </li>
            </ul>

            <i class="bi bi-list mobile-nav-toggle"></i>
          </nav>
        </div>
        <div style={{ display: "flex", alignItems: "center", marginRight: 5 }}>
          <Select
            value={value}
            style={{ width: 120 }}
            onChange={handleChange}
            options={[
              { value: "/", label: "2025 TT" },
              { value: "/2024", label: "2024" },
              { value: "/2022", label: "2022" },
            ]}
          />
        </div>
      </header>

      <section id="hero" style={{ backgroundImage: `url(${bgMain})` }}>
        <div class="hero-title-container">
          <div
            className="w-100 d-flex justify-content-center"
            style={{ direction: "row" }}
          >
            <img src={imgTitle} className="img-title"></img>
          </div>
        </div>

        <svg
          class="hero-waves"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28 "
          preserveAspectRatio="none"
        >
          <defs>
            <path
              id="wave-path"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            />
          </defs>
          <g class="wave1">
            <use
              xlinkHref="#wave-path"
              x="50"
              y="3"
              fill="rgba(255,255,255, .1)"
            />
          </g>
          <g class="wave2">
            <use
              xlinkHref="#wave-path"
              x="50"
              y="0"
              fill="rgba(255,255,255, .2)"
            />
          </g>
          <g class="wave3">
            <use xlinkHref="#wave-path" x="50" y="9" fill="#fff" />
          </g>
        </svg>
      </section>
      <section>
        <div
          class="container"
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div class="section-title" data-aos="fade-up">
            <h2>Олон нийтийн хэлэлцүүлэг</h2>
            <p>{moment().format("YYYY-MM-DD hh:mm")}</p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <video
                src={
                  "https://cdn.esis.edu.mn/econtent/econtent-files/PRODUCTION/tusuv.mp4"
                }
                controls
                preload="auto"
                width="50%"
                height="auto"
                style={{
                  borderRadius: "10px",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                }}
              >
                Your browser does not support the video tag.
              </video>
              <div style={{ marginLeft: 2, width: "40%" }}>
                <p style={{ fontSize: "18px" }}>
                  БОЛОВСРОЛЫН САЛБАРЫН УРСГАЛ ЗАРДАЛ, ХӨРӨНГӨ ОРУУЛАЛТЫН ТӨСӨЛ
                  АРГА ХЭМЖЭЭНИЙ 2025 ОНЫ ТӨСВИЙН ТӨСЛИЙН САНАЛАА ИЛГЭЭНЭ ҮҮ.
                </p>
                <p style={{ fontSize: "18px" }}>
                  <Button
                    type="primary"
                    target="_blank"
                    rel="noreferrer"
                    href="https://iform.esis.edu.mn/form/66baff8803d6c956b0786a1e"
                  >
                    Хөрөнгө оруулалт
                  </Button>
                </p>
                <p style={{ fontSize: "18px" }}>
                  <Button
                    type="primary"
                    target="_blank"
                    rel="noreferrer"
                    href="https://forms.office.com/r/NMwwiQjCTX?origin=lprLink
"
                  >
                    Урсгал зардал
                  </Button>
                </p>
                <p style={{ fontSize: "16px", color: "#cfa600" }}>
                  САНАЛ АСУУЛГА 2024 ОНЫ 08 САРЫН 15-НЫ ӨДӨР ДУУСНА.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <main id="main">
        <section id="schedule" className="mt-5">
          <Map />
        </section>
      </main>
      <footer id="footer" style={{ backgroundImage: `url(${footerBg})` }}>
        <div class="footer-top">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 col-md-6 footer-newsletter">
                <img
                  src={"https://cdn.esis.edu.mn/logo/bshuy1.png"}
                  class="testimonial-img"
                  alt=""
                />
              </div>

              <div class="col-lg-6 col-md-6">
                <div class="footer-info">
                  <h3>ХОЛБОО БАРИХ</h3>
                  <p class="pb-3">
                    Улаанбаатар хот, Сүхбаатар дүүрэг, Бага тойруу-44, Засгийн
                    газрын III байр
                  </p>
                  <p>
                    <strong>Phone:</strong>{" "}
                    <a href="tel:+976 1800 1001"> +976 1800 1001</a>
                    <br></br>
                    <strong>Email:</strong>{" "}
                    <a href="mailto:letter@mecs.gov.mn">letter@mecs.gov.mn</a>
                    <br></br>
                  </p>
                  <div class="social-links mt-3">
                    <a
                      href="https://www.facebook.com/MinistryofEdu/"
                      class="facebook"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i class="bx bxl-facebook"></i>
                    </a>
                    <a
                      href="https://twitter.com/MEDSMongolia"
                      class="twitter"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i class="bx bxl-twitter"></i>
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCIgop7wqZmSWHczm9wM1IPw/videos"
                      class="youtube"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i class="bx bxl-youtube"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="copyright text-uppercase">
            &copy; 2024 Боловсролын мэдээллийн технологийн төв
          </div>
        </div>
      </footer>
      <a
        href="#"
        class="back-to-top d-flex align-items-center justify-content-center"
      >
        <BsArrowUpShort size={28} color="#fff" />
      </a>
    </div>
  );
}
