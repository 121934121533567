import s1112 from "../assets/stats/SUB/Statistic_2011_2012_SUB.pdf";
import s1415 from "../assets/stats/SUB/Statistic-2014-2015_SUB.pdf";
import s1516 from "../assets/stats/SUB/Statistic-2015-2016_SUB.pdf";

import e1112 from "../assets/stats/EBS/EBS_2011-2012.pdf";

import d1516 from "../assets/stats/DEED/Stats_deed_2015-2016.pdf";
import d1415 from "../assets/stats/DEED/Statistic_HE_2014-2015.pdf";
import d1314 from "../assets/stats/DEED/Statistic_HE_2013-2014.pdf";
import d1213 from "../assets/stats/DEED/Statistic_HE_2012-2013.pdf";

const statNewsSub = [
  {
    title:
      "СУРГУУЛИЙН ӨМНӨХ БОЛОВСРОЛЫН САЛБАРЫН 2020-2021 ОНЫ ХИЧЭЭЛИЙН ЖИЛИЙН СТАТИСТИК МЭДЭЭ",
    introUrl:
      "https://cdn.greensoft.mn/uploads/users/2649/files/Statistics/SUB/Overview%202020-2021.pdf",
    newsUrl:
      "https://cdn.greensoft.mn/uploads/users/2649/files/Statistics/SUB/Statistic_sub_2020-2021.xlsx",
  },
  {
    title:
      "СУРГУУЛИЙН ӨМНӨХ БОЛОВСРОЛЫН САЛБАРЫН 2019-2020 ОНЫ ХИЧЭЭЛИЙН ЖИЛИЙН СТАТИСТИК МЭДЭЭ",
    introUrl:
      "https://cdn.greensoft.mn/uploads/users/2649/files/Statistics/SUB/Overview%202019-2020.pdf",
    newsUrl:
      "https://cdn.greensoft.mn/uploads/users/2649/files/Statistics/SUB/Statistic_sub%202019-2020.xlsx",
  },
  {
    title:
      "СУРГУУЛИЙН ӨМНӨХ БОЛОВСРОЛЫН САЛБАРЫН 2018-2019 ОНЫ ХИЧЭЭЛИЙН ЖИЛИЙН СТАТИСТИК МЭДЭЭ",
    introUrl:
      "https://cdn.greensoft.mn/uploads/users/2649/files/Statistics/SUB/Overview%202018-2019.pdf",
    newsUrl:
      "https://cdn.greensoft.mn/uploads/users/2649/files/Statistics/SUB/Statistic_SUB_2018-2019.xlsx",
  },
  {
    title:
      "СУРГУУЛИЙН ӨМНӨХ БОЛОВСРОЛЫН САЛБАРЫН 2017-2018 ОНЫ ХИЧЭЭЛИЙН ЖИЛИЙН СТАТИСТИК МЭДЭЭ",
    introUrl:
      "https://cdn.greensoft.mn/uploads/users/2649/files/Statistics/SUB/Overview%202017-2018.pdf",
    newsUrl:
      "https://cdn.greensoft.mn/uploads/users/2649/files/Statistics/SUB/Statistic%202017-2018.pdf",
  },
  {
    title:
      "СУРГУУЛИЙН ӨМНӨХ БОЛОВСРОЛЫН САЛБАРЫН 2016-2017 ОНЫ ХИЧЭЭЛИЙН ЖИЛИЙН СТАТИСТИК МЭДЭЭ",
    introUrl:
      "https://cdn.greensoft.mn/uploads/users/2649/files/Statistics/SUB/Overview_SUB_2016-2017.pdf",
    newsUrl:
      "https://cdn.greensoft.mn/uploads/users/2649/files/Statistics/SUB/Statistic_SUB_2016-2017.pdf",
  },
  {
    title:
      "СУРГУУЛИЙН ӨМНӨХ БОЛОВСРОЛЫН САЛБАРЫН 2015-2016 ОНЫ ХИЧЭЭЛИЙН ЖИЛИЙН СТАТИСТИК МЭДЭЭ",
    introUrl: "",
    newsUrl: s1516,
  },
  {
    title:
      "СУРГУУЛИЙН ӨМНӨХ БОЛОВСРОЛЫН САЛБАРЫН 2014-2015 ОНЫ ХИЧЭЭЛИЙН ЖИЛИЙН СТАТИСТИК МЭДЭЭ",
    introUrl: "",
    newsUrl: s1415,
  },
  {
    title:
      "СУРГУУЛИЙН ӨМНӨХ БОЛОВСРОЛЫН САЛБАРЫН 2013-2014 ОНЫ ХИЧЭЭЛИЙН ЖИЛИЙН СТАТИСТИК МЭДЭЭ",
    introUrl: "",
    newsUrl:
      "https://cdn.greensoft.mn/uploads/users/2649/files/Statistics/SUB/Statistic%202013-2014_SUB.pdf",
  },
  {
    title:
      "СУРГУУЛИЙН ӨМНӨХ БОЛОВСРОЛЫН САЛБАРЫН 2012-2013 ОНЫ ХИЧЭЭЛИЙН ЖИЛИЙН СТАТИСТИК МЭДЭЭ",
    introUrl: "",
    newsUrl:
      "https://cdn.greensoft.mn/uploads/users/2649/files/Statistics/SUB/Statistic%202012-2013_SUB.pdf",
  },
  {
    title:
      "СУРГУУЛИЙН ӨМНӨХ БОЛОВСРОЛЫН САЛБАРЫН 2011-2012 ОНЫ ХИЧЭЭЛИЙН ЖИЛИЙН СТАТИСТИК МЭДЭЭ",
    introUrl: "",
    newsUrl: s1112,
  },
];

const statNewsEbs = [
  {
    title:
      "БАГА ДУНД БОЛОВСРОЛЫН САЛБАРЫН 2020-2021 ОНЫ ХИЧЭЭЛИЙН ЖИЛИЙН СТАТИСТИК МЭДЭЭ",
    introUrl:
      "https://cdn.greensoft.mn/uploads/users/2649/files/Statistics/EBS/Overview_EBS_2020-2021.pdf",
    newsUrl:
      "https://cdn.greensoft.mn/uploads/users/2650/files/%D0%91%D0%94%D0%91%202020-2021.xlsx",
  },
  {
    title:
      "ЕРӨНХИЙ БОЛОВСРОЛЫН САЛБАРЫН 2019-2020 ОНЫ ХИЧЭЭЛИЙН ЖИЛИЙН СТАТИСТИК МЭДЭЭ",
    introUrl:
      "https://cdn.greensoft.mn/uploads/users/2649/files/Statistics/EBS/Overview_EBS_2019-2020.pdf",
    newsUrl:
      "https://cdn.greensoft.mn/uploads/users/2649/files/Statistics/EBS/Statistic_EBS_2019-2020.xlsx",
  },
  {
    title:
      "ЕРӨНХИЙ БОЛОВСРОЛЫН САЛБАРЫН 2018-2019 ОНЫ ХИЧЭЭЛИЙН ЖИЛИЙН СТАТИСТИК МЭДЭЭ",
    introUrl:
      "https://cdn.greensoft.mn/uploads/users/2649/files/Statistics/EBS/Overview_EBS_2018-2019.pdf",
    newsUrl:
      "https://cdn.greensoft.mn/uploads/users/2649/files/Statistics/EBS/Statistic_EBS_2018-2019.xlsx",
  },
  {
    title:
      "ЕРӨНХИЙ БОЛОВСРОЛЫН САЛБАРЫН 2017-2018 ОНЫ ХИЧЭЭЛИЙН ЖИЛИЙН СТАТИСТИК МЭДЭЭ",
    introUrl:
      "https://cdn.greensoft.mn/uploads/users/2649/files/Statistics/EBS/Overview_EBS_2017-2018.pdf",
    newsUrl:
      "https://cdn.greensoft.mn/uploads/users/2649/files/Statistics/EBS/Statistic_EBS_2017-2018.pdf",
  },
  {
    title:
      "БАГА, ДУНД БОЛОВСРОЛЫН САЛБАРЫН 2016-2017 ОНЫ ХИЧЭЭЛИЙН ЖИЛИЙН СТАТИСТИК МЭДЭЭ",
    introUrl: "",
    newsUrl:
      "https://cdn.greensoft.mn/uploads/users/2649/files/Statistics/EBS/Statistic_EBS_2016-2017.pdf",
  },
  {
    title:
      "БАГА, ДУНД БОЛОВСРОЛЫН САЛБАРЫН 2015-2016 ОНЫ ХИЧЭЭЛИЙН ЖИЛИЙН СТАТИСТИК МЭДЭЭ",
    introUrl: "",
    newsUrl:
      "https://cdn.greensoft.mn/uploads/users/2649/files/Statistics/EBS/Statistic_EBS_2015-2016.pdf",
  },
  {
    title:
      "БАГА, ДУНД БОЛОВСРОЛЫН САЛБАРЫН 2014-2015 ОНЫ ХИЧЭЭЛИЙН ЖИЛИЙН СТАТИСТИК МЭДЭЭ",
    introUrl: "",
    newsUrl:
      "https://cdn.greensoft.mn/uploads/users/2649/files/Statistics/EBS/Statistic_EBS_2014-2015.pdf",
  },
  {
    title:
      "БАГА, ДУНД БОЛОВСРОЛЫН САЛБАРЫН 2013-2014 ОНЫ ХИЧЭЭЛИЙН ЖИЛИЙН СТАТИСТИК МЭДЭЭ",
    introUrl: "",
    newsUrl:
      "https://cdn.greensoft.mn/uploads/users/2649/files/Statistics/EBS/Statistic_EBS_2013-2014.pdf",
  },
  {
    title:
      "БАГА, ДУНД БОЛОВСРОЛЫН САЛБАРЫН 2012-2013 ОНЫ ХИЧЭЭЛИЙН ЖИЛИЙН СТАТИСТИК МЭДЭЭ",
    introUrl: "",
    newsUrl:
      "https://cdn.greensoft.mn/uploads/users/2649/files/Statistics/EBS/Statistic_EBS_2012-2013.pdf",
  },
  {
    title:
      "БАГА, ДУНД БОЛОВСРОЛЫН САЛБАРЫН 2011-2012 ОНЫ ХИЧЭЭЛИЙН ЖИЛИЙН СТАТИСТИК МЭДЭЭ",
    introUrl: "",
    newsUrl: e1112,
  },
];

const statNewsDeed = [
  {
    title: "ДЭЭД БОЛОВСРОЛЫН САЛБАРЫН 2020-2021 ОНЫ ХИЧЭЭЛИЙН ЖИЛИЙН СТАТИСТИК",
    introUrl:
      "https://cdn.greensoft.mn/uploads/users/2649/files/Statistics/HE/Overview_HE_2020-2021_last.pdf",
    newsUrl:
      "https://cdn.greensoft.mn/uploads/users/2649/files/Statistics/HE/Statistic_HE_2020-2021_last.xlsx",
  },
  {
    title: "ДЭЭД БОЛОВСРОЛЫН САЛБАРЫН 2019-2020 ОНЫ ХИЧЭЭЛИЙН ЖИЛИЙН СТАТИСТИК",
    introUrl:
      "https://cdn.greensoft.mn/uploads/users/2649/files/Statistics/HE/Overview_HE_2019-2020.pdf",
    newsUrl:
      "https://cdn.greensoft.mn/uploads/users/2649/files/Statistics/HE/Statistic_HE_2019-2020.xls",
  },
  {
    title: "ДЭЭД БОЛОВСРОЛЫН САЛБАРЫН 2018-2019 ОНЫ ХИЧЭЭЛИЙН ЖИЛИЙН СТАТИСТИК",
    introUrl:
      "https://cdn.greensoft.mn/uploads/users/2649/files/Statistics/HE/Overview_HE_2018-2019.pdf",
    newsUrl:
      "https://cdn.greensoft.mn/uploads/users/2649/files/Statistics/HE/Statistic_HE_2018-2019.xls",
  },
  {
    title: "ДЭЭД БОЛОВСРОЛЫН САЛБАРЫН 2017-2018 ОНЫ ХИЧЭЭЛИЙН ЖИЛИЙН СТАТИСТИК",
    introUrl:
      "https://cdn.greensoft.mn/uploads/users/2649/files/Statistics/HE/Overview_HE_2017-2018.pdf",
    newsUrl:
      "https://cdn.greensoft.mn/uploads/users/2649/files/Statistics/HE/Statistic_HE_2017-2018.pdf",
  },
  {
    title: "ДЭЭД БОЛОВСРОЛЫН САЛБАРЫН 2016-2017 ОНЫ ХИЧЭЭЛИЙН ЖИЛИЙН СТАТИСТИК",
    introUrl:
      "https://cdn.greensoft.mn/uploads/users/2649/files/Statistics/HE/Overview_HE_2016-2017.pdf",
    newsUrl:
      "https://cdn.greensoft.mn/uploads/users/2649/files/Statistics/HE/Statistic_HE_2016-2017.pdf",
  },
  {
    title: "ДЭЭД БОЛОВСРОЛЫН САЛБАРЫН 2015-2016 ОНЫ ХИЧЭЭЛИЙН ЖИЛИЙН СТАТИСТИК",
    introUrl: "",
    newsUrl: d1516,
  },
  {
    title: "ДЭЭД БОЛОВСРОЛЫН САЛБАРЫН 2014-2015 ОНЫ ХИЧЭЭЛИЙН ЖИЛИЙН СТАТИСТИК",
    introUrl: "",
    newsUrl: d1415,
  },
  {
    title: "ДЭЭД БОЛОВСРОЛЫН САЛБАРЫН 2013-2014 ОНЫ ХИЧЭЭЛИЙН ЖИЛИЙН СТАТИСТИК",
    introUrl: "",
    newsUrl: d1314,
  },
  {
    title: "ДЭЭД БОЛОВСРОЛЫН САЛБАРЫН 2012-2013 ОНЫ ХИЧЭЭЛИЙН ЖИЛИЙН СТАТИСТИК",
    introUrl: "",
    newsUrl: d1213,
  },
  {
    title: "ДЭЭД БОЛОВСРОЛЫН САЛБАРЫН 2011-2012 ОНЫ ХИЧЭЭЛИЙН ЖИЛИЙН СТАТИСТИК",
    introUrl: "",
    newsUrl:
      "https://cdn.greensoft.mn/uploads/users/2649/files/Statistics/HE/Statistic_HE_2011-2012.pdf",
  },
];

const statNewsScience = [
  {
    title: "ШИНЖЛЭХ УХААНЫ САЛБАРЫН 2016-2020 ОНЫ СТАТИСТИК МЭДЭЭЛЭЛ",
    introUrl:
      "https://cdn.greensoft.mn/uploads/users/2649/files/Statistics/Science/Overview_Statistic_Science_2020.pdf",
    newsUrl:
      "https://cdn.greensoft.mn/uploads/users/2650/files/%D0%A8%D0%A3%D0%A2-%D0%90%20%D0%BC%D0%B0%D1%8F%D0%B3%D1%82-2020.xlsx",
  },
  {
    title: "ШИНЖЛЭХ УХААНЫ САЛБАРЫН 2010-2019 ОНЫ СТАТИСТИК МЭДЭЭЛЭЛ",
    introUrl: "",
    newsUrl:
      "https://cdn.greensoft.mn/uploads/users/2649/files/Statistics/Science/Statistic_Science_2010-2019.xlsx",
  },
];
export { statNewsSub, statNewsEbs, statNewsDeed, statNewsScience };
