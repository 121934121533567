const tsetserleg = [
  {
    name: "1921",
    count: 0,
    fill: "#F6D05E",
  },
  {
    name: "1931",
    count: 12,
    fill: "#BCBDC0",
  },
  {
    name: "1941",
    count: 13,
    fill: "#4CA7B1",
  },
  {
    name: "1951",
    count: 52,
    fill: "#EA5846",
  },
  {
    name: "1961",
    count: 202,
    fill: "#547386",
  },
  {
    name: "1971",
    count: 552,
    fill: "#7E7D7B",
  },
  {
    name: "1981",
    count: 624,
    fill: "#50A5AD",
  },
  {
    name: "1991",
    count: 883,
    fill: "#F8D05A",
  },
  {
    name: "2001",
    count: 665,
    fill: "#D6DF21",
  },
  {
    name: "2011",
    count: 879,
    fill: "#24AAE1",
  },
  {
    name: "2021",
    count: 1453,
    fill: "#EE3E35",
  },
  {
    name: "2022",
    count: 1413,
    fill: "#20A355",
  },
  {
    name: "2023",
    count: 1410,
    fill: "#2447C1",
  },
];

const surguuli = [
  {
    name: "1921",
    count: 1,
    fill: "#F6D05E",
  },
  {
    name: "1931",
    count: 109,
    fill: "#BCBDC0",
  },
  {
    name: "1941",
    count: 323,
    fill: "#4CA7B1",
  },
  {
    name: "1951",
    count: 428,
    fill: "#EA5846",
  },
  {
    name: "1961",
    count: 429,
    fill: "#547386",
  },
  {
    name: "1971",
    count: 521,
    fill: "#7E7D7B",
  },
  {
    name: "1981",
    count: 576,
    fill: "#50A5AD",
  },
  {
    name: "1991",
    count: 643,
    fill: "#F8D05A",
  },
  {
    name: "2001",
    count: 700,
    fill: "#D6DF21",
  },
  {
    name: "2011",
    count: 752,
    fill: "#24AAE1",
  },
  {
    name: "2021",
    count: 848,
    fill: "#EE3E35",
  },
  {
    name: "2022",
    count: 859,
    fill: "#20A355",
  },
  {
    name: "2023",
    count: 871,
    fill: "#2447C1",
  },
];

const msut = [
  {
    name: "1921",
    count: 0,
    fill: "#F6D05E",
  },
  {
    name: "1931",
    count: 0,
    fill: "#BCBDC0",
  },
  {
    name: "1941",
    count: 0,
    fill: "#4CA7B1",
  },
  {
    name: "1951",
    count: 0,
    fill: "#EA5846",
  },
  {
    name: "1961",
    count: 0,
    fill: "#547386",
  },
  {
    name: "1971",
    count: 30,
    fill: "#7E7D7B",
  },
  {
    name: "1981",
    count: 37,
    fill: "#50A5AD",
  },
  {
    name: "1991",
    count: 40,
    fill: "#F8D05A",
  },
  {
    name: "2001",
    count: 32,
    fill: "#D6DF21",
  },
  {
    name: "2011",
    count: 44,
    fill: "#24AAE1",
  },
  {
    name: "2021",
    count: 50,
    fill: "#EE3E35",
  },
  {
    name: "2022",
    count: 77,
    fill: "#20A355",
  },
  {
    name: "2023",
    count: 76,
    fill: "#2447C1",
  },
];

const deed = [
  {
    name: "1921",
    count: 0,
    fill: "#F6D05E",
  },
  {
    name: "1931",
    count: 0,
    fill: "#BCBDC0",
  },
  {
    name: "1941",
    count: 1,
    fill: "#4CA7B1",
  },
  {
    name: "1951",
    count: 4,
    fill: "#EA5846",
  },
  {
    name: "1961",
    count: 8,
    fill: "#547386",
  },
  {
    name: "1971",
    count: 5,
    fill: "#7E7D7B",
  },
  {
    name: "1981",
    count: 7,
    fill: "#50A5AD",
  },
  {
    name: "1991",
    count: 7,
    fill: "#F8D05A",
  },
  {
    name: "2001",
    count: 178,
    fill: "#D6DF21",
  },
  {
    name: "2011",
    count: 113,
    fill: "#24AAE1",
  },
  {
    name: "2021",
    count: 88,
    fill: "#EE3E35",
  },
  {
    name: "2022",
    count: 69,
    fill: "#20A355",
  },
  {
    name: "2023",
    count: 64,
    fill: "#2447C1",
  },
];

export { tsetserleg, surguuli, msut, deed };
