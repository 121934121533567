import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell,
  ResponsiveContainer,
} from "recharts";

export default function LollipopChart({ data, variant, name }) {
  const LollipopBar = (props) => {
    const { fill, x, y, width, height } = props;

    let cWidth = 20;
    const variants = {
      1: x < 200 ? (cWidth = 5) : (cWidth = 20),
      2: x < 70 ? (cWidth = 5) : (cWidth = 20),
      3: x < 300 ? (cWidth = 5) : (cWidth = 20),
      4: x < 400 ? (cWidth = 5) : (cWidth = 20),
    };
    cWidth = variants[`${variant}`];

    return (
      <svg fill={fill} xmlns="http://www.w3.org/2000/svg">
        <rect
          x={x + width / 2 - 2}
          y={y + cWidth}
          width={4}
          height={height - cWidth}
          fill={fill}
        />
        <rect
          x={width / 2 + x - cWidth / 2}
          y={y}
          width={cWidth}
          height={cWidth}
          rx={cWidth / 2}
          fill={fill}
        />
      </svg>
    );
  };

  return (
    <ResponsiveContainer width={"100%"} height={300}>
      <BarChart
        data={data}
        margin={{
          top: 15,
          right: 25,
          left: -10,
          bottom: 15,
        }}
        padding={{ right: 0, left: 0 }}
        barSize={20}
      >
        <XAxis
          dataKey="name"
          scale="point"
          padding={{ left: 14 }}
          interval={0}
          fontSize={13}
        />
        <YAxis fontSize={13} />
        <Tooltip />
        {/* <Legend /> */}
        <CartesianGrid strokeDasharray="3 3" />
        <Bar
          dataKey="count"
          name={name}
          fill="#fcfcfc"
          shape={<LollipopBar />}
          background={{ fill: "#fcfcfc" }}
          label={{ position: "top" }}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.fill} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
