import React, { useState } from "react";
import GeoJSONMap from "../../../components/2024/map";
import scheduleData from "../../../components/2024/2025.json";
import { Collapse } from "antd";

export default function Map() {
  const [getId, setGetId] = useState({
    name: "Улсын хэмжээнд",
    id: "",
  });
  const [getInfo, setGetInfo] = useState({
    name: "",
    school_cover: "",
    kinder_cover: "",
  });
  const [lat, setLang] = useState({
    lattitude: "",
    longitude: "",
  });

  const handleFeatureMouseOver = (e) => {
    const name = e.target.feature.properties.name;
    const sub = e.target.feature.properties.sub_coverage;
    const ebs = e.target.feature.properties.ebs_coverage;

    setLang({
      lattitude: e.latlng.lat,
      longitude: e.latlng.lng,
    });

    setGetInfo({
      name: name,
      school_cover: ebs,
      kinder_cover: sub,
    });
  };

  const handleFeatureMouseOut = () => {
    setGetInfo({
      name: null,
      school_cover: null,
      kinder_cover: null,
    });
  };

  const handleFeatureMouseClick = (e) => {
    console.log("eeeee", e);

    const info_name = e.target.feature.properties.name;
    const info_id = e.target.feature.properties.aimag_city_ID;

    setGetId({
      name: info_name,
      id: info_id,
    });
  };

  return (
    <div className="container">
      <div class="section-title" data-aos="fade-up">
        <h2>Санал</h2>
        <p>
          БОЛОВСРОЛЫН САЛБАРЫН ХӨРӨНГӨ ОРУУЛАЛТЫН ТӨСӨЛ АРГА ХЭМЖЭЭНИЙ 2025 ОНЫ
          ТӨСВИЙН ТӨСЛИЙН САНАЛ
        </p>
      </div>
      <div style={{ marginBottom: "5px" }}>
        <GeoJSONMap
          handleFeatureMouseOver={handleFeatureMouseOver}
          setGetInfo={setGetInfo}
          getInfo={getInfo}
          handleFeatureMouseOut={handleFeatureMouseOut}
          lat={lat}
          handleFeatureMouseClick={handleFeatureMouseClick}
        />
      </div>
      <div>
        <h2 style={{ color: "#010483" }}>
          {getId.name ? getId.name : "Улсын хэмжээнд"}
        </h2>
      </div>
      <div className="mb-3">
        {scheduleData?.map((item, i) => {
          if (
            item.aimag_name === getId.name &&
            item.aimag_name !== "Улаанбаатар"
          ) {
            return (
              <div key={item.aimag_name} className="att-table-container">
                <table>
                  <thead>
                    <tr>
                      <th style={{ textAlign: "left" }}>№</th>
                      <th style={{ textAlign: "left" }}>
                        Төсөл, арга хэмжээний нэр, хүчин чадал, байршил
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {item.data.map((el) => (
                      <tr key={el.id}>
                        <td>{el.id}</td>
                        <td>{el.description}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            );
          } else if (
            item.aimag_name === "Улаанбаатар" &&
            getId.name !== "Улсын хэмжээнд" &&
            getId.name === "Улаанбаатар"
          ) {
            return (
              <Collapse accordion key={item.aimag_name}>
                {item.children.map((child) => (
                  <Collapse.Panel header={child.label} key={child.label}>
                    {child.data.map((el) => (
                      <p key={el.id}>
                        {el.id}. {el.description}
                      </p>
                    ))}
                  </Collapse.Panel>
                ))}
              </Collapse>
            );
          } else {
            return null;
          }
        })}
      </div>
      {getId.name != "Улсын хэмжээнд" && (
        <>
          <div>
            <h2 style={{ color: "#010483" }}>{"Улсын хэмжээнд"}</h2>
          </div>
          <div className="mb-3">
            {scheduleData?.map(
              (item, i) =>
                item.aimag_name == "Улсын хэмжээнд" && (
                  <div key={item.aimag_name} className="att-table-container ">
                    <table>
                      <thead>
                        <tr>
                          <th style={{ textAlign: "left" }}>№</th>
                          <th style={{ textAlign: "left" }}>
                            Төсөл, арга хэмжээний нэр, хүчин чадал, байршил
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {item.data.map((el) => (
                          <tr>
                            <td>{el.id}</td>
                            <td>{el.description}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )
            )}
          </div>
        </>
      )}
    </div>
  );
}
