import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import AreaChart from "../../../components/AreaChart";
import LollipopChart from "../../../components/LollipopChart";
import {
  tsetserleg,
  surguuli,
  msut,
  deed,
} from "../../../tmp-data/lollipopChartData";
import std_cnt from "../../../tmp-data/areaChartData";
import CustomBarChart from "../../../components/CustomBarChart";
import teacher_cnt from "../../../tmp-data/customBarChartData";
import iconSchool from "../../../assets/images/iconSchool.png";
import iconStudent from "../../../assets/images/iconStudent.png";
import iconTeacher from "../../../assets/images/iconTeacher.png";

export default function Charts() {
  return (
    <div className="my-2">
      <div className="chart-section ">
        <div className="py-5 section-with-bg">
          <Container>
            <div className="mb-2">
              <div class="section-title" data-aos="fade-up">
                <h2>Статистик</h2>
                <p>БОЛОВСРОЛЫН БАЙГУУЛЛАГЫН ТОО</p>
              </div>

              <Row className="gy-3">
                <Col xs={12} xl={6} xxl={6}>
                  <div className="chart-card">
                    <LollipopChart
                      data={tsetserleg}
                      variant={1}
                      name="Цэцэрлэгийн тоо"
                    />
                    <div className="chart-description">
                      ХҮҮХДИЙН{" "}
                      <span className="text-yellow">ЦЭЦЭРЛЭГИЙН ТОО</span>
                    </div>
                  </div>
                </Col>
                <Col xs={12} xl={6} xxl={6}>
                  <div className="chart-card">
                    <LollipopChart
                      data={surguuli}
                      variant={2}
                      name="Сургуулийн тоо"
                    />
                    <div className="chart-description">
                      ЕРӨНХИЙ БОЛОВСРОЛЫН{" "}
                      <span className="text-yellow">СУРГУУЛИЙН ТОО</span>
                    </div>
                  </div>
                </Col>
                <Col xs={12} xl={6} xxl={6}>
                  <div className="chart-card">
                    <LollipopChart
                      data={msut}
                      variant={3}
                      name="Боловсролын сургалтын байгууллага"
                    />
                    <div className="chart-description">
                      ТЕХНИКИЙН БОЛОН МЭРГЭЖЛИЙН{" "}
                      <span className="text-yellow">
                        БОЛОВСРОЛЫН СУРГАЛТЫН БАЙГУУЛЛАГА
                      </span>
                    </div>
                  </div>
                </Col>
                <Col xs={12} xl={6} xxl={6}>
                  <div className="chart-card">
                    <LollipopChart
                      data={deed}
                      variant={4}
                      name="Их, дээд сургууль"
                    />
                    <div className="chart-description">
                      ИХ, ДЭЭД <span className="text-yellow">СУРГУУЛЬ</span>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <div className="py-5 ">
          <Container>
            <div class="section-title" data-aos="fade-up">
              <h2>Статистик</h2>
              <p>СУРАЛЦАГЧДЫН ТОО</p>
            </div>

            <Row className="gy-3">
              <Col xs={12} xl={6} xxl={6}>
                <AreaChart data={std_cnt.SUB} variant={1} name="Хүүхдийн тоо" />
                <div className="chart-description">
                  СӨБ-Д ХАМРАГДАЖ БУЙ{" "}
                  <span className="text-red">ХҮҮХДИЙН ТОО</span>
                </div>
              </Col>
              <Col xs={12} xl={6} xxl={6}>
                <AreaChart
                  data={std_cnt.EBS}
                  variant={2}
                  name="Сурагчдын тоо"
                />
                <div className="chart-description">
                  ЕБС-ИЙН <span className="text-red">СУРАГЧДЫН ТОО</span>
                </div>
              </Col>
              <Col xs={12} xl={6} xxl={6}>
                <AreaChart
                  data={std_cnt.MSUT}
                  variant={3}
                  name="Суралцагчидын тоо"
                />
                <div className="chart-description">
                  ТЕХНИКИЙН БОЛОН МЭРГЭЖЛИЙН{" "}
                  <span className="text-red">
                    БОЛОВСРОЛЫН СУРГАЛТЫН БАЙГУУЛЛАГАД СУРАЛЦАГЧИД
                  </span>
                </div>
              </Col>
              <Col xs={12} xl={6} xxl={6}>
                <AreaChart
                  data={std_cnt.DEED}
                  variant={4}
                  name="Суралцагчидын тоо"
                />
                <div className="chart-description">
                  ИХ, ДЭЭД СУРГУУЛЬД{" "}
                  <span className="text-red">СУРАЛЦАГЧИД</span>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="py-5 section-with-bg">
          <Container>
            <div className="">
              <div class="section-title" data-aos="fade-up">
                <h2>Статистик</h2>
                <p>
                  {" "}
                  БАГШ НАРЫН ТОО, ХИЧЭЭЛИЙН ЖИЛИЙН ЭХНИЙ БАЙДЛААР, мянган хүн
                </p>
              </div>
              <Row className="gy-3">
                <Col xs={12} xl={6} xxl={6}>
                  <CustomBarChart
                    data={teacher_cnt.SUB}
                    variant={1}
                    name="Нийт багш"
                  />
                  <div className="chart-description">
                    ХҮҮХДИЙН{" "}
                    <span className="text-blue">ЦЭЦЭРЛЭГИЙН НИЙТ БАГШ</span>
                  </div>
                </Col>
                <Col xs={12} xl={6} xxl={6}>
                  <CustomBarChart
                    data={teacher_cnt.EBS}
                    variant={2}
                    name="Нийт багш"
                  />
                  <div className="chart-description">
                    ЕРӨНХИЙ БОЛОВСРОЛЫН{" "}
                    <span className="text-blue">СУРГУУЛИЙН НИЙТ БАГШ</span>
                  </div>
                </Col>
                <Col xs={12} xl={6} xxl={6}>
                  <CustomBarChart
                    data={teacher_cnt.MSUT}
                    variant={3}
                    name="Нийт багш"
                  />
                  <div className="chart-description">
                    ТЕХНИКИЙН БОЛОН МЭРГЭЖЛИЙН{" "}
                    <span className="text-blue">
                      БОЛОВСРОЛЫН СУРГАЛТЫН БАЙГУУЛЛАГЫН НИЙТ БАГШ
                    </span>
                  </div>
                </Col>
                <Col xs={12} xl={6} xxl={6}>
                  <CustomBarChart
                    data={teacher_cnt.DEED}
                    variant={4}
                    name="Нийт багш"
                  />
                  <div className="chart-description">
                    ИХ, ДЭЭД{" "}
                    <span className="text-blue">СУРГУУЛИЙН НИЙТ БАГШ</span>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
}
