import DrawerWrapper from "antd/lib/drawer";
import style from "./style.module.scss";
import yamBlue from "../../assets/images/yamBlue.png";
import jillogo from "../../assets/img/hero-img.png";
import logo from "../../assets/images/logoo.png";
import QRCode from "react-qr-code";
import ReactToPrint from "react-to-print";
import { useEffect, useRef, useState } from "react";
import { AiFillPrinter } from "react-icons/ai";
import dataJson from "../../assets/data/Оролцогчдын нэрс-211113.json";
import axios from "axios";
export default function QrCode() {
  const [data, setData] = useState([]);
  const componentRef = useRef();
  //   const data = [
  //     {
  //       name: "Ж.Амарболд",
  //       ct: "Архангай",
  //       rn: "рм00292711",
  //     },
  //     {
  //       name: "Ж.Амарболд",
  //       ct: "Архангай",
  //       rn: "рм00292711",
  //     },
  //     {
  //       name: "Ж.Амарболд",
  //       ct: "Архангай",
  //       rn: "рм00292711",
  //     },
  //     {
  //       name: "Ж.Амарболд",
  //       ct: "Архангай",
  //       rn: "рм00292711",
  //     },
  //     {
  //       name: "Ж.Амарболд",
  //       ct: "Архангай",
  //       rn: "рм00292711",
  //     },
  //     {
  //       name: "Ж.Амарболд",
  //       ct: "Архангай",
  //       rn: "рм00292711",
  //     },
  //     {
  //       name: "Ж.Амарболд",
  //       ct: "Архангай",
  //       rn: "рм00292711",
  //     },

  //     {
  //       name: "Ж.Амарболд",
  //       ct: "Архангай",
  //       rn: "рм00292711",
  //     },
  //     {
  //       name: "Ж.Амарболд",
  //       ct: "Архангай",
  //       rn: "рм00292711",
  //     },
  //   ];
  useEffect(() => {
    axios
      .get("https://kub.esis.edu.mn/xyp/v1/attendance/list")
      .then((response) => {
        if (response.data.SUCCESS_CODE === 200) {
          const filterData = response.data.RESULT.filter(
            (people, index, arr) => {
              if (people.attendDate.slice(0, 10) === "2021-11-16") {
                return people;
              }
            }
          );
          setData(filterData);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  const encodeTest = (e) => {
    if (e) {
      e = e.replace(/\r\n/g, "\n");
      var t = "";
      for (var n = 0; n < e.length; n++) {
        var r = e.charCodeAt(n);
        if (r < 128) {
          t += String.fromCharCode(r);
        } else if (r > 127 && r < 2048) {
          t += String.fromCharCode((r >> 6) | 192);
          t += String.fromCharCode((r & 63) | 128);
        } else {
          t += String.fromCharCode((r >> 12) | 224);
          t += String.fromCharCode(((r >> 6) & 63) | 128);
          t += String.fromCharCode((r & 63) | 128);
        }
      }
      return t;
    }
  };
  return (
    <div className={style.container}>
      <ReactToPrint
        // onBeforePrint={() =>
        //   // setState({
        //   //   ...state,
        //   //   loader: true,
        //   // })
        // }
        trigger={() => <button icon={<AiFillPrinter />}>Хэвлэх</button>}
        content={() => componentRef.current}
      />
      <table ref={componentRef}>
        <tbody>
          {data &&
            data.length > 0 &&
            data.map((item, idx) => {
              if (idx % 2 === 1) {
                return (
                  <tr>
                    <td>
                      <div
                        key={idx}
                        //   style={{ backgroundImage: ` url(${mandatBack})` }}
                        className={style.card}
                      >
                        <div className={style.logoCon}>
                          <img className={style.yamLogo} src={logo} />
                        </div>
                        <div className={style.title}>
                          <span className={style.titleMain}>
                            “ЦАХИМ ТӨСӨВ - 2022”
                          </span>
                          <span className={style.subTitle}>
                            БОЛОВСРОЛЫН САЛБАРЫН САНХҮҮЖИЛТИЙН ШИНЭЧЛЭЛ СЭДЭВТ
                            СУРГАЛТ
                          </span>
                        </div>
                        <div className={style.qrCodeCon}>
                          <QRCode
                            value={JSON.stringify({
                              rn: data[idx - 1].primaryNidNumber
                                ? encodeTest(data[idx - 1].primaryNidNumber)
                                : null,
                              ct: encodeTest(data[idx - 1].institutionName),
                              pId: data[idx - 1].personId,
                              name: encodeTest(data[idx - 1].fullName),
                            })}
                            size={100}
                          />
                        </div>
                        <div className={style.textCon}>
                          <span>{data[idx - 1].fullName}</span>
                          <span>{data[idx - 1].institutionName}</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div
                        key={idx}
                        //   style={{ backgroundImage: ` url(${mandatBack})` }}
                        className={style.card}
                      >
                        <div className={style.logoCon}>
                          <img className={style.yamLogo} src={logo} />
                        </div>
                        <div className={style.title}>
                          <span className={style.titleMain}>
                            “ЦАХИМ ТӨСӨВ - 2022”
                          </span>
                          <span className={style.subTitle}>
                            БОЛОВСРОЛЫН САЛБАРЫН САНХҮҮЖИЛТИЙН ШИНЭЧЛЭЛ СЭДЭВТ
                            СУРГАЛТ
                          </span>
                        </div>
                        <div className={style.qrCodeCon}>
                          <QRCode
                            value={JSON.stringify({
                              rn: item.primaryNidNumber
                                ? encodeTest(item.primaryNidNumber)
                                : null,
                              ct: encodeTest(item.institutionName),
                              pId: item.personId,
                              name: encodeTest(item.fullName),
                            })}
                            size={100}
                          />
                        </div>
                        <div className={style.textCon}>
                          <span>{item.fullName}</span>
                          <span>{item.institutionName}</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              }
            })}
        </tbody>
      </table>
    </div>
  );
}
