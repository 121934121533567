import React, { useState } from "react";
import img1 from "../../../assets/img/Images/viber_image_2022-05-14_12-20-03-266.jpg";
import img2 from "../../../assets/img/Images/viber_image_2022-05-14_12-20-07-134.jpg";
import img3 from "../../../assets/img/Images/viber_image_2022-05-14_12-20-07-552.jpg";
import img4 from "../../../assets/img/Images/viber_image_2022-05-14_12-20-08-533.jpg";
import img5 from "../../../assets/img/Images/viber_image_2022-05-14_12-20-10-844.jpg";
import img6 from "../../../assets/img/Images/viber_image_2022-05-14_12-20-11-861.jpg";
import img7 from "../../../assets/img/Images/viber_image_2022-05-14_12-20-21-516.jpg";
import img8 from "../../../assets/img/Images/viber_image_2022-05-14_12-20-21-975.jpg";
import img9 from "../../../assets/img/Images/viber_image_2022-05-14_12-20-24-101.jpg";
//new
import img12 from "../../../assets/img/Images/photo_2022-05-15_11-02-29.jpg";
import img13 from "../../../assets/img/Images/photo_2022-05-15_11-02-31.jpg";
import img14 from "../../../assets/img/Images/photo_2022-05-15_11-02-32.jpg";
import img15 from "../../../assets/img/Images/photo_2022-05-15_11-02-33.jpg";
import img16 from "../../../assets/img/Images/photo_2022-05-15_11-02-34.jpg";
import img17 from "../../../assets/img/Images/photo_2022-05-15_11-48-56.jpg";
import img18 from "../../../assets/img/Images/IMG_1140.jpg";
import img19 from "../../../assets/img/Images/IMG_1361.jpg";
import img20 from "../../../assets/img/Images/IMG_1368.jpg";
import img21 from "../../../assets/img/Images/IMG_1375.jpg";
import img22 from "../../../assets/img/Images/IMG_1386.jpg";
import img23 from "../../../assets/img/Images/DSC_0634.JPG";
import img24 from "../../../assets/img/Images/DSC_0638.JPG";
import img25 from "../../../assets/img/Images/DSC_0697.JPG";
import img26 from "../../../assets/img/Images/viber_image_2022-05-25_11-14-37-668.jpg";
import img27 from "../../../assets/img/Images/0K9A1432.jpg";
import img28 from "../../../assets/img/Images/0K9A1480.jpg";
import img29 from "../../../assets/img/Images/0K9A1493.jpg";
import img30 from "../../../assets/img/Images/viber_image_2022-06-02_10-09-21-089.jpg";
import img31 from "../../../assets/img/Images/viber_image_2022-06-02_10-09-50-183.jpg";
import img32 from "../../../assets/img/Images/viber_image_2022-06-02_17-48-46-060.jpg";
import img33 from "../../../assets/img/Images/viber_image_2022-06-03_11-23-30-267.jpg";
import img34 from "../../../assets/img/Images/viber_image_2022-06-03_11-39-59-469.jpg";
import img35 from "../../../assets/img/Images/viber_image_2022-06-03_12-04-25-115.jpg";
import img36 from "../../../assets/img/Images/DSC_0690.JPG";
import img37 from "../../../assets/img/Images/DSC_0709.JPG";
import img38 from "../../../assets/img/Images/viber_image_2022-06-04_14-35-08-841.jpg";
import img39 from "../../../assets/img/Images/viber_image_2022-06-04_14-35-10-168.jpg";
import img40 from "../../../assets/img/Images/viber_image_2022-06-04_09-25-58-207.jpg";
import img41 from "../../../assets/img/Images/viber_image_2022-06-06_11-14-56-074.jpg";
import img42 from "../../../assets/img/Images/viber_image_2022-06-06_11-16-10-625.jpg";
import img43 from "../../../assets/img/Images/0K9A9189.JPG";
import img44 from "../../../assets/img/Images/0K9A9456.JPG";
import img45 from "../../../assets/img/Images/0K9A9616.JPG";

import style from "./style.module.scss";
import Slider from "react-slick";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const images = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  // img10,
  // img11,
   img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
  img21,
  img22,
  img23,
  img24,
  img25,
  img26,
  img27,
  img28,
  img29,
  img30,
  img31,
  img32,
  img33,
  img34,
  img35,
  img36,
  img37,
  img38,
  img39,
  img40,
  img41,
  img42,
  img43,
  img44,
  img45,
];

export default function Gallery() {
  const [imgLightBox, setImgLightBox] = useState({
    photoIndex: 0,
    isOpen: false,
  });

  const { photoIndex, isOpen } = imgLightBox;

  const settings = {
    dots: true,
    slidesToShow: 5,
    //slidesToScroll: 4,
    arrows: true,
    centerMode: true,
    centerPadding: "60px",
    infinite: true,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 4000,
    focusOnSelect: true,
    // cssEase: "linear",
    //initialSlide: 0,
    customPaging: function (i) {
      return <div className="dot"></div>;
    },
    dotsClass: "slick-dots slick-thumb",

    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <Slider {...settings} className="image-slider">
        {images.map((el, i) => (
          <div
            className="img-card"
            onClick={() => setImgLightBox({ isOpen: true, photoIndex: i })}
          >
            <img src={el}></img>
          </div>
        ))}
      </Slider>

      {isOpen && (
        <Lightbox
          //imageTitle={images[photoIndex]}
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setImgLightBox({ isOpen: false })}
          onMovePrevRequest={() =>
            setImgLightBox({
              isOpen,
              photoIndex: (photoIndex + images.length - 1) % images.length,
            })
          }
          onMoveNextRequest={() =>
            setImgLightBox({
              isOpen,
              photoIndex: (photoIndex + 1) % images.length,
            })
          }
        />
      )}
    </div>
  );
}
