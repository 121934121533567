import React, { useEffect, useState } from "react";
import { Tag, Divider, Pagination, Select } from "antd";
import { tmpAtt } from "../../../tmp-data/attendance";
import axios from "axios";
import PieChart from "../../../components/PieChart";
import _ from "lodash";
import moment from "moment";

const { Option } = Select;

export default function Attendance() {
  const [showRowCnt, setShowRowCnt] = useState(15);
  // const [currentPage, setCurrentPage] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  // const [result, setResult] = useState([]);
  const [allAttList, setAllAttList] = useState([]);
  const [attList, setAttList] = useState([]);

  const onChangePageNumber = (page, pageSize) => {
    // console.log("page, pageSize", page, pageSize);
    setAttList(allAttList[page - 1]);
  };

  const options = ["Өмнөговь", "Дундговь", "Говьсүмбэр"];
  return (
    <div className="container">
      <div class="section-title" data-aos="fade-up">
        <h2>Уулзалтын явцын мэдээ</h2>
        <p> {`${moment().format("YYYY-MM-DD")} -ны байдлаар`} </p>
      </div>

      <div className="mb-3 att-chart-container">
        <PieChart label="Аймаг " percent={100} count={21} color="#60a5fa" />
        <PieChart label="Сургууль " percent={100} count={528} color="#ec4899" />
        <PieChart label="Цэцэрлэг" percent={100} count={686} color="#10b981" />
      </div>
      <div className="mb-3 att-chart-container">
        <PieChart
          label="ЕБС оролцогч "
          percent={100}
          count={3150}
          color="#6366f1"
        />
        <PieChart
          label="СӨБ оролцогч "
          percent={100}
          count={2600}
          color="#fbbf24"
        />
        <PieChart
          label="Цахимаар"
          percent={100}
          count={43658}
          color="#a78bfa"
        />
      </div>

      {/* <div class="section-title my-2 mt-4">
        <h2>Уулзалтын оролцогчийн жагсаалт</h2>
        <p>ТӨСВИЙН ШИНЭЧЛЭЛ 2022 УУЛЗАЛТ</p>
      </div> */}

      {/* <div className="mb-3">
        <Select value={"Өмнөговь"}>
          {options.map((el) => (
            <Option value={el}>{el}</Option>
          ))}
        </Select>
      </div> */}

      {/* <div className="att-table-container">
        <table>
          <thead>
            <tr>
              <th style={{ textAlign: "left" }}>№</th>
              <th style={{ textAlign: "left" }}>Аймаг</th>
              <th style={{ textAlign: "left" }}>Байгууллага</th>
              <th style={{ textAlign: "left" }}>Оролцогчийн нэр</th>
              <th style={{ textAlign: "left" }}>Багийн дугаар</th>
            </tr>
          </thead>
          <tbody>
            {attList.length > 0 &&
              attList.map((el, i) => {
                return (
                  <tr>
                    <td>{++i}</td>
                    <td>{`${el.provinceName}-${el.soumName}`}</td>
                    <td>{el.institutionName}</td>
                    <td>
                      {`${
                        el.lastName?.length > 0 &&
                        el.lastName?.substring(0, 1).toUpperCase()
                      }.${el?.firstName} /${el.jobTitle}/`}
                    </td>
                    <td>{el.teamNumber}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div className="d-flex justify-content-end my-3 me-2">
          <Pagination
            defaultCurrent={1}
            total={totalLength}
            pageSize={showRowCnt}
            onChange={onChangePageNumber}
            onShowSizeChange={onShowSizeChange}
          />
        </div>
      </div> */}
    </div>
  );
}
