import React from "react";
import imgLogo from "../../../assets/img/logo.png";

export default function Header() {
  return (
    <header
      id="header-default"
      class="fixed-top d-flex align-items-center header-transparent"
    >
      <div class="container d-flex align-items-center justify-content-between">
        <div class="logo">
          <a href="/">
            <img src={imgLogo} alt="" class="img-fluid" />
          </a>
        </div>

        <nav id="navbar" class="navbar">
          <ul>
            <li>
              <a class="nav-link  " href="/">
                Эхлэл
              </a>
            </li>
            <li>
              <a class="nav-link " href="/">
                Илтгэл
              </a>
            </li>
            <li>
              <a class="nav-link " href="/">
                Бичлэг
              </a>
            </li>
            <li>
              <a class="nav-link " href="/">
                Зургийн цомог
              </a>
            </li>
            <li>
              <a class="nav-link " href="/">
                Он цагийн хэлхээ
              </a>
            </li>
            <li>
              <a class="nav-link " href="/">
                Статистик
              </a>
            </li>

            <li>
              <a class="nav-link active" href="/info-graphic">
                Инфографик
              </a>
            </li>

            <li>
              <a class="nav-link " href="/">
                Холбоо барих
              </a>
            </li>
          </ul>
          <i class="bi bi-list mobile-nav-toggle"></i>
        </nav>
      </div>
    </header>
  );
}
