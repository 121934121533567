import React from "react";
import Footer from "../Layouts/Footer";
import Header from "../Layouts/Header";

export default function InfoGraphic() {
  return (
    <div className="">
      <Header />

      <div className="info-graphic-page">
        <div className="iframe-container">
          <iframe src="https://www.esis.edu.mn/hr/index.html#/infographic"></iframe>
        </div>
      </div>
      <Footer />
    </div>
  );
}
