import React from "react";
import { MapContainer, TileLayer, GeoJSON, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { geodata } from "./prefectures";

export default function GeoJSONMap({
  handleFeatureMouseOver,
  setGetInfo,
  getInfo,
  handleFeatureMouseOut,
  lat,
  handleFeatureMouseClick,
}) {
  return (
    <MapContainer
      center={[46.8625, 103.8467]}
      zoom={6}
      style={{ height: "600px", width: "100%" }}
    >
      {/* <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" /> */}
      <GeoJSON
        data={geodata}
        onEachFeature={(feature, layer) => {
          layer.on({
            mouseover: handleFeatureMouseOver,
            mouseout: handleFeatureMouseOut,
            click: handleFeatureMouseClick,
          });
        }}
        style={(feature) => ({
          color:
            feature.properties.schedule_type == "Y" ? "#71B001" : "#3867D6",
        })}
      />

      {lat.lattitude
        ? getInfo.name && (
            <Popup
              position={[lat.lattitude, lat.longitude] || [46.8625, 103.8467]}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h6 style={{ color: "#3867D6" }}>{getInfo.name}</h6>
                </div>
                {/* <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
               
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginRight: "5px",
                      justifyContent: "center"
                    }}
                  >
                    <h6 style={{marginTop: "5px"}}>Хамрагдалтын хувь</h6>
                    <div style={{
                       display: "flex",
                       flexDirection: "row",
                    }} >
                      <p style={{ fontWeight: "bold", marginRight: "5px" }}>
                      ЕБС:
                    </p>
                    <p style={{ marginRight: "5px" }}>{getInfo.school_cover}%</p>
                    <p style={{ fontWeight: "bold", marginRight: "5px" }}>
                      СӨБ:
                    </p>
                    <p>{getInfo.kinder_cover}%</p>
                    </div>
                    
                  </div>
                  
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    
                  </div>
                </div> */}
              </div>
            </Popup>
          )
        : null}
    </MapContainer>
  );
}
