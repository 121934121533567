import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell,
  ResponsiveContainer,
  AreaChart as RchAreaChart,
  Area,
  Label,
} from "recharts";

export default function AreaChart({ data, name }) {
  return (
    <ResponsiveContainer width={"100%"} height={300}>
      <RchAreaChart
        data={data}
        margin={{
          top: 65,
          right: 25,
          // left: -10,
          bottom: 65,
        }}

        //barSize={20}
        //style={{ background: "#FEF4E9" }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#EB9395" stopOpacity={1} />
            <stop offset="10%" stopColor="#E8818A" stopOpacity={1} />
            <stop offset="90%" stopColor="#FDEBD9" stopOpacity={1} />
            <stop offset="95%" stopColor="#FDEBD9" stopOpacity={0.6} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="name"
          label={{ angle: -90 }}
          interval={0}
          fontSize={13}
        />
        <YAxis label={{ angle: -90 }} fontSize={13} />
        <Tooltip />
        {/* <Legend /> */}
        <CartesianGrid strokeDasharray="3 3" />
        <Area
          name={name}
          type="monotone"
          dataKey="count"
          dot={{
            fill: "#F15929",
            stroke: "#fff",
            fillOpacity: 1,
            r: 6,
          }}
          stroke="#EB9395"
          fillOpacity={1}
          fill="url(#colorUv)"
          // label={CustomizedLabel}
          label={{
            angle: -90,
            position: "top",
            offset: 36,
            textAnchor: "end",
          }}
          // dot
        />
      </RchAreaChart>
    </ResponsiveContainer>
  );
}
