//БАГШИЙН ТОО, хичээлийн жилийн эхний байдлаар, мянган хүн

const SUB = [
  {
    name: "1991",
    count: 5.0,
  },
  {
    name: "2001",
    count: 3.1,
  },
  {
    name: "2011",
    count: 4.7,
  },
  {
    name: "2021",
    count: 8.8,
  },
  {
    name: "2022",
    count: 17.7,
  },
  {
    name: "2023",
    count: 18.3,
  },
];

const EBS = [
  {
    name: "1961",
    count: 5.7,
  },
  {
    name: "1971",
    count: 8.7,
  },
  {
    name: "1981",
    count: 13.9,
  },
  {
    name: "1991",
    count: 20.6,
  },
  {
    name: "2001",
    count: 19.2,
  },
  {
    name: "2011",
    count: 26.4,
  },
  {
    name: "2021",
    count: 35.1,
  },
  {
    name: "2022",
    count: 36.1,
  },
  {
    name: "2023",
    count: 36.9,
  },
];

const MSUT = [
  {
    name: "1961",
    count: 0.6,
  },
  {
    name: "1971",
    count: 0.7,
  },
  {
    name: "1981",
    count: 1.1,
  },
  {
    name: "1991",
    count: 0.8,
  },
  {
    name: "2001",
    count: 0.9,
  },
  {
    name: "2011",
    count: 2.1,
  },
  {
    name: "2021",
    count: 2.2,
  },
  {
    name: "2022",
    count: 2.2,
  },
  {
    name: "2023",
    count: 2.3,
  },
];

const DEED = [
  {
    name: "1961",
    count: 0.6,
  },
  {
    name: "1971",
    count: 0.7,
  },
  {
    name: "1981",
    count: 1.1,
  },
  {
    name: "1991",
    count: 3.7,
  },
  {
    name: "2001",
    count: 4.9,
  },
  {
    name: "2011",
    count: 7.2,
  },
  {
    name: "2021",
    count: 6.1,
  },
  {
    name: "2022",
    count: 12.1,
  },
  {
    name: "2023",
    count: 12.1,
  },
];
const teacher_cnt = {
  MSUT,
  SUB,
  EBS,
  DEED,
};
export default teacher_cnt;
