import React, { useState } from "react";
import { VictoryAnimation, VictoryPie, VictoryLabel } from "victory";
//import { makeStyles } from "@material-ui/core";

function PieChart({ label, percent, color, count }) {
  const [state, setState] = useState({
    percent: percent,
    data: [
      { x: 1, y: percent },
      { x: 2, y: 100 - percent },
    ],
  });

  return (
    <div style={{ width: "300px", height: "300px" }}>
      <svg viewBox="0 0 400 400" width="100%" height="100%">
        <circle
          cx={200}
          cy={200}
          r={134}
          fill="#fff"
          style={{ filter: "drop-shadow(0px 0px 44px rgba(34, 37, 169, 0.1))" }}
        />
        <VictoryPie
          standalone={false}
          animate={{ duration: 1000 }}
          width={400}
          height={400}
          data={state.data}
          innerRadius={118}
          cornerRadius={25}
          labels={() => null}
          style={{
            data: {
              fill: ({ datum }) => {
                return datum.x === 1 ? color : "transparent";
              },
            },
          }}
        />
        <VictoryAnimation duration={1000} data={state}>
          {(newProps) => {
            return (
              <>
                <VictoryLabel
                  textAnchor="middle"
                  verticalAnchor="middle"
                  x={200}
                  y={180}
                  // text={`${Math.round(newProps.percent)}% `}
                  text={`${count} `}
                  // text={`60%`}
                  style={{
                    fontSize: 54,
                    // fill: "#3D41D7",
                    fill: color,
                    // fontFamily: "Nunito",
                    fontWeight: "600",
                  }}
                />
                <VictoryLabel
                  textAnchor="middle"
                  verticalAnchor="middle"
                  x={200}
                  y={228}
                  text={` ${label} `}
                  style={{
                    fontSize: 34,
                    fill: "#8F92A1",
                    // fontFamily: "Nunito",
                    width: "60px",
                    // maxWidth: "50px",
                  }}
                />
                {/* <VictoryLabel
                  textAnchor="middle"
                  verticalAnchor="middle"
                  x={200}
                  y={264}
                  text={"хийлгэсэн"}
                  style={{
                    fontSize: 34,
                    fill: "#8F92A1",
                    fontFamily: "Nunito",
                    width: "60px",
                    // maxWidth: "50px",
                  }}
                /> */}
              </>
            );
          }}
        </VictoryAnimation>
      </svg>
    </div>
  );
}

// const useStyles = makeStyles((theme) => ({
//   container: {
//     width: "300px",
//     height: "300px",
//     //background: "#E5E5E5",
//   },
// }));
export default PieChart;
