import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell,
  LabelList,
  ResponsiveContainer,
} from "recharts";

// const data = [
//   //   {
//   //     name: "1921",
//   //     count: 0,
//   //     fill: "#F6D05E",
//   //   },
//   {
//     name: "1931",
//     count: 12,
//     fill: "#BCBDC0",
//   },
//   {
//     name: "1941",
//     count: 13,
//     fill: "#4CA7B1",
//   },
//   {
//     name: "1951",
//     count: 52,
//     fill: "#EA5846",
//   },
//   {
//     name: "1961",
//     count: 62,
//     fill: "#547386",
//   },
//   {
//     name: "1971",
//     count: 13,
//     fill: "#4CA7B1",
//   },
//   {
//     name: "1981",
//     count: 52,
//     fill: "#EA5846",
//   },
//   {
//     name: "1991",
//     count: 62,
//     fill: "#547386",
//   },
// ];

export default function CustomBarChart({ data, name }) {
  const CustomizedLabel = (props) => {
    const { fill, x, y, width, height, value } = props;
    let cWidth = 20;

    return (
      <svg xmlns="http://www.w3.org/2000/svg">
        <path
          transform={`translate(${x}, ${y - 38})`}
          //   d="M40 0L20 8L0 0V38H13.3333L20 42L26.6667 38H40V0Z"
          d={`M${width} 0L${width / 2} ${width / 5}L0 0V${width - 2}H${
            width / 3
          }L${width / 2} ${width + 2}L${(width / 3) * 2} ${
            width - 2
          }H${width}V0Z`}
          fill="#4C76BB"
        />
        <text
          x={x + width / 2}
          y={y - width / 2 - width / 9}
          fill="#fff"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {value}
        </text>
      </svg>
    );
  };
  const CustomBar = (props) => {
    const { fill, x, y, width, height } = props;
    let cWidth = 20;

    return (
      <svg xmlns="http://www.w3.org/2000/svg">
        <rect
          x={x}
          y={y + cWidth / 2}
          width={width}
          height={height - cWidth / 2}
          fill="#EE2A32"
        />
        <rect
          x={x + width / 2 - cWidth / 2}
          y={y}
          width={cWidth}
          height={cWidth}
          rx={cWidth / 2}
          fill="white"
          stroke="#4C76BB"
          stroke-width={cWidth / 5}
        />
      </svg>
    );
  };

  return (
    <ResponsiveContainer width={"100%"} height={300}>
      <BarChart
        data={data}
        margin={{
          top: 5,
          right: 25,
          left: -10,
          bottom: 5,
        }}
        padding={{ right: 0, left: 0 }}
        barSize={32}
      >
        <XAxis dataKey="name" scale="point" padding={{ left: 40, right: 10 }} />
        <YAxis />
        <Tooltip />
        {/* <Legend /> */}
        <CartesianGrid strokeDasharray="3 3" />
        <Bar
          dataKey="count"
          name={name}
          fill="#8884d8"
          shape={<CustomBar />}
          background={{ fill: "#E2E0E1" }}
          //label={{ position: "top" }}
        >
          <LabelList dataKey={"count"} content={CustomizedLabel} />
          {/* {data.map((entry, index) => (
          <Cell key={`cell-${index}`} />
        ))} */}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
