import React, { useState } from "react";
import imgTeam1 from "../../../assets/img/team/team-1.jpg";
import { Avatar } from "antd";
import pdfBook from "../../../assets/files/book.pdf";
import bookIntro from "../../../assets/img/book-intro.png";

import bagshQR from "../../../assets/img/QR2023/iform_bagsh.jpeg";
import menejQR from "../../../assets/img/QR2023/iform_menej.jpeg";

import pdfsaid from "../../../assets/pdfs/Ажлын хэсэг ахлагч.pdf";
import imgsaid from "../../../assets/pdf-intro/said.png";

import ppt1 from "../../../assets/2024_PDF/СХОГ_ын_дарга.pptx";
import ppt1_intro from "../../../assets/2024_PDF_Intro/ppt1_intro.png";

import ppt2 from "../../../assets/2024_PDF/2_ЗГ_ын_тогтоол,_журмын_танилцуулга.pptx";
import ppt2_intro from "../../../assets/2024_PDF_Intro/ppt2_intro.png";

import ppt3 from "../../../assets/2024_PDF/4_Багшийн_мэргэжил_дээшлүүлэх_зардал.pptx";
import ppt3_intro from "../../../assets/2024_PDF_Intro/ppt3_intro.png";

import ppt4 from "../../../assets/2024_PDF/Аргачлал_2024.pptx";
import ppt4_intro from "../../../assets/2024_PDF_Intro/ppt4_intro.png";

import ppt5 from "../../../assets/2024_PDF/Заавар_Хичээл,_сургалтын_үйл_ажиллагаа_2024.pptx";
import ppt5_intro from "../../../assets/2024_PDF_Intro/ppt5_intro.png";

import ppt6 from "../../../assets/2024_PDF/PPT_2024_02_28.pptx";
import ppt6_intro from "../../../assets/2024_PDF_Intro/ppt6_intro.png";

import ppt7 from "../../../assets/2024_PDF/Цахим_хэрэглээний_зардал.pptx";
import ppt7_intro from "../../../assets/2024_PDF_Intro/ppt7_intro.png";

import legal1 from "../../../assets/2024_PDF/39.pdf";
import legal2 from "../../../assets/2024_PDF/Байгууллагын_гүйцэтгэлийн_үнэлгээ.pdf";
import legal3 from "../../../assets/2024_PDF/Мэргэжлийн боловсрол_466.pdf";

const materials = [
  {
    introImg: ppt1_intro,
    src: ppt1,
  },
  {
    introImg: ppt2_intro,
    src: ppt2,
  },
  {
    introImg: ppt3_intro,
    src: ppt3,
  },
  {
    introImg: ppt4_intro,
    src: ppt4,
  },
  {
    introImg: ppt5_intro,
    src: ppt5,
  },
  {
    introImg: ppt6_intro,
    src: ppt6,
  },
  {
    introImg: ppt7_intro,
    src: ppt7,
  },
];
const pdfLegal = [
  {
    id: "1",
    name: "Монгол улсын Засгийн газрын 2024 оны 01 дүгээр сарын 25-ны өдрийн 39 дүгээр тогтоол",
    source: legal1,
  },
  {
    id: "2",
    name: "Монгол улсын Засгийн газрын 2024 оны 01 дүгээр сарын 17-ны өдрийн 25 дугаар тогтоол",
    source: legal2,
  },
  {
    id: "3",
    name: "Монгол улсын Засгийн газрын 2023 оны 12 дугаар сарын 27-ны өдрийн 466 дугаар тогтоол",
    source: legal3,
  },
];

export default function Schedule() {
  const [scheduleKey, setScheduleKey] = useState("day-1");

  const eventDays = [
    // {
    //   id: "day-1",
    //   label: "Нэгдсэн уулзалт",
    // },
    // {
    //   id: "day-2",
    //   label: "Салбар уулзалт",
    // },
    {
      id: "day-1",
      label: "4-р сарын уулзалтын материал",
    },
    {
      id: "day-2",
      label: "Эрх зүйн баримт бичиг",
    },
    // {
    //   id: "day-3",
    //   label: "Санал  асуулга",
    // },
  ];

  // console.log("DATE: ", activeDate);

  return (
    <div className="container mt-5">
      <div class="section-title" data-aos="fade-up">
        <h2>Хөтөлбөр</h2>
        <p>Төсвийн шинэчлэл 2024 уулзалт</p>
      </div>
      <div className="schedule">
        {/* <h3 class="sub-heading">
          БОЛОВСРОЛЫН САЛБАРЫН САНХҮҮЖИЛТИЙН ШИНЭЧЛЭЛ СЭДЭВТ СУРГАЛТЫН ХӨТӨЛБӨР
        </h3> */}

        <ul
          class="nav nav-tabs "
          role="tablist"
          data-aos="fade-up"
          data-aos-delay="100"
          // className="w-100 d-flex justify-content-center mt-3"
        >
          {eventDays.map((el, i) => (
            <li
              class="nav-item row gy-3 align-middle"
              onClick={() => setScheduleKey(`day-${i + 1}`)}
            >
              <a
                class={`nav-link ${el.id === scheduleKey ? "active" : ""}`}
                role="tab"
                data-bs-toggle="tab"
              >
                {el.label}
              </a>
            </li>
          ))}
        </ul>

        <div
          class="tab-content row justify-content-center"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          {/* {scheduleKey === "day-1" && (
            <div
              role="tabpanel"
              class="col-xxl-9 tab-pane fade show active"
              id="day-1"
            >
              <div className="schedule-title">
                Нэгдсэн уулзалт /эхний хагас/
              </div>
              <div class="row schedule-item">
                <div class="col-md-2">
                  <time>5 минут</time>
                </div>
                <div class="col-md-10">
                  <div className="event-speaker">
                    <Avatar className="avatar bg-blue-500">ОТ</Avatar>
                    <div className="description">
                      <h4> */}
          {/* <a target="_blank" href={pdfsaid}> */}
          {/* Ажлын хэсгийн бүрэлдэхүүн, уулзалтын зорилго,
                          хөтөлбөрийн танилцуулга */}
          {/* </a> */}
          {/* </h4>
                      <p>Модератор – Орон нутгийн дэд бүрэлдэхүүний төлөөлөл</p>
                    </div>
                  </div>
                </div>
              </div> */}
          {/* <div class="row schedule-item">
                <div class="col-md-2">
                  <time className="mb-5">3 минут</time>
                </div>
                <div class="col-md-10">
                  <h4 className="mb-0">
                    Төсвийн шинэчлэлийн видео танилцуулга
                  </h4>
                </div>
              </div> */}
          {/* <div class="row schedule-item">
                <div class="col-md-2">
                  <time>20 минут </time>
                </div>
                <div class="col-md-10">
                  <div className="event-speaker">
                    <Avatar className="avatar bg-indigo-500">АА</Avatar>
                    <div className="description">
                      <h4>
                        <a target="_blank" href={pdfsaid}>
                          Төсвийн шинэчлэл, боловсролын салбарын бодлого, эрх
                          зүйн шинэчлэлийн талаарх товч мэдээлэл
                        </a>
                      </h4>
                      <p>Ажлын хэсгийн ахлагч </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row schedule-item">
                <div class="col-md-2">
                  <time>15 минут</time>
                </div>
                <div class="col-md-10">
                  <div className="event-speaker">
                    <Avatar className="avatar bg-pink-500">ТГ</Avatar>
                    <div className="description">
                      <h4>
                        <a target="_blank" href={pdfHurteemj}>
                          Хувьсах зардал, цалин хөлсний илтгэл
                        </a>
                      </h4>
                      <p> Ажлын хэсгийн төсөв, санхүү хариуцсан гишүүн </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row schedule-item">
                <div class="col-md-2">
                  <time>15 минут</time>
                </div>
                <div class="col-md-10">
                  <div className="event-speaker">
                    <Avatar className="avatar bg-indigo-500">ХГ</Avatar>
                    <div className="description">
                      <h4>
                        <a target="_blank" href={pdfBagshiinHugjil}>
                          Багшийн хөгжил, хичээл практикийн зардлын шинэчлэлийн
                          танилцуулга
                        </a>
                      </h4>
                      <p>
                        Ажлын хэсгийн багшийн хөгжил, хичээл практик хариуцсан
                        гишүүн
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row schedule-item">
                <div class="col-md-2">
                  <time>15 минут</time>
                </div>
                <div class="col-md-10">
                  <div className="event-speaker">
                    <Avatar className="avatar bg-blue-500">БГ</Avatar>
                    <div className="description">
                      <h4>
                        <a target="_blank" href={pdfEruulMend}>
                          Эрүүл мэндийн үйлчилгээ
                        </a>
                        <a target="_blank" href={pdfTsahim} className="ms-2">
                          Цахим дата багцын танилцуулга
                        </a>
                      </h4>
                      <p>
                        Ажлын хэсгийн багш багц хариуцсан ажлын хэсгийн гишүүн
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row schedule-item">
                <div class="col-md-2">
                  <time>15 минут</time>
                </div>
                <div class="col-md-10">
                  <div className="event-speaker">
                    <Avatar className="avatar bg-blue-500">ҮГ</Avatar>
                    <div className="description">
                      <h4>
                        <a target="_blank" href={pdfGuitsetgel}>
                          Гүйцэтгэл, үнэлгээний журам, тогтолцоо, нэмэгдэл,
                          нэмэгдэл хөлсний танилцуулга
                        </a>
                      </h4>
                      <p>
                        Ажлын хэсгийн үнэлгээ хариуцсан ажлын хэсгийн гишүүн
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row schedule-item">
                <div class="col-md-2">
                  <time>15 минут</time>
                </div>
                <div class="col-md-10">
                  <h4 className="mb-0">Завсарлага </h4>
                </div>
              </div>
              <div class="row schedule-item">
                <div class="col-md-2">
                  <time>15 минут</time>
                </div>
                <div class="col-md-10">
                  <div className="event-speaker">
                    <Avatar className="avatar  bg-green-400">СБ</Avatar>
                    <div className="description">
                      <h4>
                        <a target="_blank" href={pdfBEG}>
                          Боловсролын ерөнхий газрын бүтэц, чиг үүрэг, үндсэн
                          үйл ажиллагаа
                        </a>
                      </h4>
                      <p>БЕГ-ын сургагч багш</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row schedule-item">
                <div class="col-md-2">
                  <time>15 минут </time>
                </div>
                <div class="col-md-10">
                  <div className="event-speaker">
                    <Avatar className="avatar bg-green-400">СБ</Avatar>
                    <div className="description">
                      <h4>
                        <a target="_blank" href={pdfYosZui}>
                          Боловсролын байгууллагын багш удирдах ажилтны ёс зүй,
                          манлайлал
                        </a>
                      </h4>
                      <p>БЕГ-ын сургагч багш</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row schedule-item">
                <div class="col-md-2">
                  <time>15 минут </time>
                </div>
                <div class="col-md-10">
                  <div className="event-speaker">
                    <Avatar className="avatar bg-green-400">СБ</Avatar>
                    <div className="description">
                      <h4>
                        <a target="_blank" href={pdfHuuhedHamgaalal}>
                          Хүүхэд хамгаалалд хамтын оролцоог нэмэгдүүлэх нь
                        </a>
                      </h4>
                      <p>БЕГ-ын сургагч багш</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row schedule-item">
                <div class="col-md-2">
                  <time>15 минут </time>
                </div>
                <div class="col-md-10">
                  <div className="event-speaker">
                    <Avatar className="avatar bg-blue-500">ОТ</Avatar>
                    <div className="description">
                      <h4>Асуулт, хариулт хэлэлцүүлэг</h4>
                      <p>Модератор – Орон нутгийн дэд бүрэлдэхүүний төлөөлөл</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row schedule-item">
                <div class="col-md-2">
                  <time className="mb-5">-</time>
                </div>
                <div class="col-md-10">
                  <h4 className="mb-0">Нэгдсэн уулзалтын төгсгөл</h4>
                </div>
              </div>
            </div>
          )} */}

          {/* {scheduleKey === "day-2" && (
            <div
              role="tabpanel"
              class="col-xxl-9 tab-pane fade show active"
              id="day-1"
            >
              <div className="schedule-title">
                
                Салбар уулзалт /сүүлийн хагас/
              </div>
              <div className="schedule-title">Баг тус бүрээр</div>
              <div class="row schedule-item">
                <div class="col-md-2">
                  <time>1 цаг</time>
                </div>
                <div class="col-md-10">
                  <div className="event-speaker">
                    <Avatar className="avatar bg-blue-500">ХГ</Avatar>
                    <div className="description">
                      <h4><a target="_blank" href={pdfUulzalt1}>Багшийн хөгжил, хичээл практикийн зардал</a></h4>
                      <p>
                        Ажлын хэсгийн багшийн хөгжил, хичээл практик хариуцсан
                        гишүүн
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row schedule-item">
                <div class="col-md-2">
                  <time>1 цаг</time>
                </div>
                <div class="col-md-10">
                  <div className="event-speaker">
                    <Avatar className="avatar bg-blue-500">ТГ</Avatar>
                    <div className="description">
                      <h4><a target="_blank" href={pdfUulzalt2}>Төсвийн санхүүжилт, хувьсах зардлын бүрэлдэхүүн</a></h4>
                      <p>Ажлын хэсгийн төсөв, санхүү хариуцсан гишүүн</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row schedule-item">
                <div class="col-md-2">
                  <time>1 цаг</time>
                </div>
                <div class="col-md-10">
                  <div className="event-speaker">
                    <Avatar className="avatar bg-blue-500">БГ</Avatar>
                    <div className="description">
                      <h4> */}
          {/* <a target="_blank" href={pdfUulzalt3}> */}
          {/* Эрүүл мэндийн үйлчилгээ,
                         Цахим дата багцын танилцуулга */}
          {/* </a> */}

          {/* </h4>
                      <p>
                        Ажлын хэсгийн багш багц хариуцсан ажлын хэсгийн гишүүн
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row schedule-item">
                <div class="col-md-2">
                  <time>1 цаг</time>
                </div>
                <div class="col-md-10">
                  <div className="event-speaker">
                    <Avatar className="avatar bg-blue-500">ҮГ</Avatar>
                    <div className="description">
                      <h4>
                        <a target="_blank" href={pdfslabarUnelgee}>
                        Гүйцэтгэл, үр дүнгийн үнэлгээний видео танилцуулга
                        </a>
                      </h4>
                      <p>
                        Ажлын хэсгийн үнэлгээ хариуцсан ажлын хэсгийн гишүүн
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="schedule-title">Бүх багт ижил сэдвээр</div>
              <div class="row schedule-item">
                <div class="col-md-2">
                  <time>15 минут </time>
                </div>
                <div class="col-md-10">
                  <div className="event-speaker">
                    <Avatar className="avatar bg-pink-500">СБ</Avatar>
                    <div className="description">
                      <h4>Мэргэжлийн хөгжлийн бүлгийн ажиллах аргачлал</h4>
                      <p>БЕГ-ын сургагч багш </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row schedule-item">
                <div class="col-md-2">
                  <time>45 минут </time>
                </div>
                <div class="col-md-10">
                  <div className="event-speaker">
                    <Avatar className="avatar bg-pink-500">СБ</Avatar>
                    <div className="description">
                      <h4>
                        Мэргэжлийн хөгжлийн бүлгийн ажиллах аргачлалыг уншиж,
                        судлах, хэлэлцэх
                      </h4>
                      <p>БЕГ-ын сургагч багш </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row schedule-item">
                <div class="col-md-2">
                  <time>1 цаг </time>
                </div>
                <div class="col-md-10">
                  <div className="event-speaker">
                    <Avatar className="avatar bg-pink-500">СБ</Avatar>
                    <div className="description">
                      <h4>
                        Судлагдахуун ба суралцахуйн асуудлыг илрүүлэх,
                        мэргэжлийн хөгжлийн бүлгийн бүтэц, зохион байгуулалтыг
                        тодорхойлох
                      </h4>
                      <p>БЕГ-ын сургагч багш </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row schedule-item">
                <div class="col-md-2">
                  <time>30 минут</time>
                </div>
                <div class="col-md-10">
                  <div className="event-speaker">
                    <Avatar className="avatar bg-pink-500">СБ</Avatar>
                    <div className="description">
                      <h4>Дүгнэлт, ярилцлага</h4>
                      <p>БЕГ-ын сургагч багш </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row schedule-item">
                <div class="col-md-2">
                  <time>10-15 минут</time>
                </div>
                <div class="col-md-10">
                  <div className="event-speaker">
                    <Avatar className="avatar bg-blue-500">БГ</Avatar>
                    <div className="description">
                      <h4>
                        Нэгдсэн цахим санал асуулга хүсэлтийн бүртгэл
                        /Оролцогчид гар утсаар судалгааг бөглөнө/
                      </h4>
                      <p>Ажлын хэсгийн бүх гишүүд</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )} */}

          {scheduleKey === "day-1" && (
            <div
              role="tabpanel"
              class="question-tab col-lg-9 tab-pane fade show active"
            >
              <div className="mt-4 row gy-4">
                {/* <a target="_blank" href={pdfBook}>
                  <img src={bookIntro} width={180}></img>
                </a> */}
                {materials.map((el, i) => (
                  <div className="col-sm-3" key={i}>
                    <a target="_blank" href={el.src}>
                      <img src={el.introImg} className="w-100"></img>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          )}

          {scheduleKey === "day-2" && (
            <div
              role="tabpanel"
              class="question-tab  tab-pane fade show active"
            >
              <div className="d-flex flex-column">
                {/* <a target="_blank" href={pdfBook}>
                  <img src={bookIntro} width={180}></img>
                </a> */}
                {pdfLegal.map((el, i) => (
                  <div
                    className="col-sm-2 w-100 p-2"
                    class="border rounded my-3 p-2"
                    key={i}
                  >
                    <h5 class="text-primary font-weight-bold">
                      {el.id}. {el.name}
                    </h5>
                    <div class="d-flex flex-row-reverse ">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        class="border rounded px-2 "
                        href={el.source}
                      >
                        Дэлгэрэнгүй
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {scheduleKey === "day-3" && (
            <div
              role="tabpanel"
              class="question-tab row gy-2 tab-pane fade show active"
            >
              <div class="d-flex flex-row justify-content-around mt-3">
                <div className="d-flex flex-column w-25 mx-2 text-center">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={
                      "https://iform.esis.edu.mn/form/63719ae9f90a4528a713d4ef"
                    }
                  >
                    <div style={{ height: "80px" }}>
                      <h5>Захирал, менежерийн хариулах асуумж</h5>
                    </div>
                  </a>
                  <img src={menejQR} alt="" />
                </div>
                <div className="d-flex flex-column w-25 mx-2 text-center">
                  <div>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={
                        "https://iform.esis.edu.mn/form/63719b7cf90a4528a713d4f0"
                      }
                    >
                      <div style={{ height: "80px" }}>
                        <h5>Багшийн хариулах асуумж</h5>
                      </div>
                    </a>
                  </div>
                  <img src={bagshQR} alt="" />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
