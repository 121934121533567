//СУРАЛЦАГЧДЫН ТОО

const SUB = [
  {
    name: "1921",
    count: 0,
  },
  {
    name: "1931",
    count: 356,
  },
  {
    name: "1941",
    count: 374,
  },
  {
    name: "1951",
    count: 1963,
  },
  {
    name: "1961",
    count: 12225,
  },
  {
    name: "1971",
    count: 32628,
  },
  {
    name: "1981",
    count: 52278,
  },
  {
    name: "1991",
    count: 95715,
  },
  {
    name: "2001",
    count: 83578,
  },
  {
    name: "2011",
    count: 164263,
  },
  {
    name: "2021",
    count: 190991,
  },
  {
    name: "2022",
    count: 266024,
  },
  {
    name: "2023",
    count: 274236,
  },
];

const EBS = [
  {
    name: "1921",
    count: 100,
  },
  {
    name: "1931",
    count: 8160,
  },
  {
    name: "1941",
    count: 30420,
  },
  {
    name: "1951",
    count: 64328,
  },
  {
    name: "1961",
    count: 115399,
  },
  {
    name: "1971",
    count: 245005,
  },
  {
    name: "1981",
    count: 378888,
  },
  {
    name: "1991",
    count: 411696,
  },
  {
    name: "2001",
    count: 510291,
  },
  {
    name: "2011",
    count: 505409,
  },
  {
    name: "2021",
    count: 712353,
  },
  {
    name: "2022",
    count: 751513,
  },
  {
    name: "2023",
    count: 771722,
  },
];

const MSUT = [
  {
    name: "1921",
    count: 0,
  },
  {
    name: "1931",
    count: 0,
  },
  {
    name: "1941",
    count: 0,
  },
  {
    name: "1951",
    count: 0,
  },
  {
    name: "1961",
    count: 0,
  },
  {
    name: "1971",
    count: 9403,
  },
  {
    name: "1981",
    count: 22676,
  },
  {
    name: "1991",
    count: 19252,
  },
  {
    name: "2001",
    count: 15051,
  },
  {
    name: "2011",
    count: 46071,
  },
  {
    name: "2021",
    count: 37806,
  },
  {
    name: "2022",
    count: 38034,
  },
  {
    name: "2023",
    count: 38294,
  },
];

const DEED = [
  {
    name: "1921",
    count: 0,
  },
  {
    name: "1931",
    count: 0,
  },
  {
    name: "1941",
    count: 262,
  },
  {
    name: "1951",
    count: 1462,
  },
  {
    name: "1961",
    count: 7775,
  },
  {
    name: "1971",
    count: 8525,
  },
  {
    name: "1981",
    count: 24093,
  },
  {
    name: "1991",
    count: 16801,
  },
  {
    name: "2001",
    count: 83200,
  },
  {
    name: "2011",
    count: 170126,
  },
  {
    name: "2021",
    count: 148954,
  },
  {
    name: "2022",
    count: 155454,
  },
  {
    name: "2023",
    count: 145345,
  },
];
const std_cnt = {
  MSUT,
  SUB,
  EBS,
  DEED,
};
export default std_cnt;
