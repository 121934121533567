export const geodata = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        properties: {
          TYPE: "States",
          color: "#3867D6",
          aimag_city_ID: 40296,
          name: "Архангай",
          schedule_type: "Y",
          sub_coverage: "71",
          ebs_coverage: "73"
        },
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [98.41651439666748, 47.61481374692148],
              [98.32489013671875, 47.63023101663225],
              [98.25347900390624, 47.65428791076272],
              [98.19305419921875, 47.706065135695745],
              [98.17108154296875, 47.787325537803106],
              [98.2012939453125, 47.85187391101592],
              [98.20678710937499, 47.91450120703987],
              [98.18756103515625, 47.98440682947526],
              [98.2232666015625, 48.056053763981225],
              [98.27545166015625, 48.09459164290992],
              [98.35784912109375, 48.10559716402152],
              [98.4814453125, 48.100094697973795],
              [98.54736328125, 48.111099041065366],
              [98.6627197265625, 48.16608541901253],
              [98.7066650390625, 48.206371336358906],
              [98.8385009765625, 48.23747967660676],
              [98.953857421875, 48.27588152743497],
              [99.0743637084961, 48.296099474919146],
              [99.07432079315186, 48.29617085179661],
              [99.1680908203125, 48.26674084583965],
              [99.22027587890624, 48.24571107288924],
              [99.26422119140625, 48.23565029755306],
              [99.35760498046875, 48.2420528380672],
              [99.61166381835938, 48.274967532852976],
              [99.70230102539062, 48.295985271707664],
              [99.744873046875, 48.339820521006125],
              [99.7833251953125, 48.420998730129426],
              [99.78607177734375, 48.486576276944774],
              [99.9041748046875, 48.463815894066066],
              [99.96185302734375, 48.44377831058805],
              [100.0469970703125, 48.40550278187468],
              [100.107421875, 48.38909015961602],
              [100.17196655273438, 48.390002110874164],
              [100.21041870117186, 48.412795581099964],
              [100.2117919921875, 48.45561965661709],
              [100.22552490234374, 48.4765629664158],
              [100.28182983398438, 48.4902169906863],
              [100.37796020507812, 48.4965876108066],
              [100.47546386718749, 48.50932644976633],
              [100.39581298828125, 48.58205840283824],
              [100.36148071289062, 48.629278127969414],
              [100.35736083984375, 48.675546901341384],
              [100.38619995117188, 48.75075629617738],
              [100.4315185546875, 48.823140892101684],
              [100.48095703125, 48.866521538507754],
              [100.579833984375, 48.89271247049609],
              [100.64163208007812, 48.923401616679705],
              [100.60317993164062, 48.95948236523749],
              [100.47683715820312, 48.9991410647952],
              [100.535888671875, 49.034266715880854],
              [100.64437866210938, 49.10444366870733],
              [100.70205688476562, 49.20503726723141],
              [100.88607788085938, 49.13095912169914],
              [100.94856262207031, 49.116130191506805],
              [101.14837646484375, 49.050920216584856],
              [101.28021240234375, 49.015355983769666],
              [101.36054992675781, 48.96939999849952],
              [101.44912719726561, 48.95407191575548],
              [101.53770446777344, 48.950915549215985],
              [101.60568237304686, 48.95948236523749],
              [101.66610717773438, 48.963089005540155],
              [101.66559219360352, 48.963089005540155],
              [101.74713134765625, 48.89000369970676],
              [101.81304931640625, 48.866521538507754],
              [101.876220703125, 48.86290791986464],
              [101.93389892578125, 48.832181625698475],
              [101.9586181640625, 48.77429274267509],
              [102.03826904296875, 48.77248263837077],
              [102.12890625, 48.81048112472012],
              [102.2003173828125, 48.6746400785837],
              [102.2332763671875, 48.494767515307295],
              [102.2772216796875, 48.44742209577057],
              [102.337646484375, 48.42191010942875],
              [102.37060546875, 48.32703913063476],
              [102.48596191406249, 48.25759852914997],
              [102.6287841796875, 48.25394114463431],
              [102.755126953125, 48.1367666796927],
              [102.83752441406249, 48.02299832104887],
              [102.843017578125, 47.90529605906089],
              [103.0133056640625, 47.83528342275264],
              [103.084716796875, 47.724544549099676],
              [103.1890869140625, 47.71345768748889],
              [103.4088134765625, 47.47637579720936],
              [103.6669921875, 47.331377157798244],
              [103.67729187011719, 47.250805241535076],
              [103.67729187011719, 47.250805241535076],
              [103.63746643066406, 47.219101747038245],
              [103.59214782714844, 47.201843347946806],
              [103.52073669433594, 47.18737928434322],
              [103.44589233398436, 47.18457933279251],
              [103.34701538085938, 47.18737928434322],
              [103.1890869140625, 47.204642388766935],
              [103.13690185546875, 47.23262467463881],
              [103.03802490234375, 47.31275872224939],
              [102.952880859375, 47.32579231609051],
              [102.886962890625, 47.357431944587034],
              [102.843017578125, 47.37231462056695],
              [102.755126953125, 47.37975438400816],
              [102.71392822265625, 47.41322033016902],
              [102.68096923828124, 47.40764414848437],
              [102.65075683593749, 47.39091206104779],
              [102.6397705078125, 47.366734109127115],
              [102.65350341796875, 47.333238640473475],
              [102.68783569335938, 47.30903424774781],
              [102.72079467773436, 47.28202395793543],
              [102.72628784179688, 47.25779610603277],
              [102.72628784179688, 47.23915174981453],
              [102.68783569335938, 47.18877920473518],
              [102.64251708984374, 47.15517092451596],
              [102.58621215820312, 47.12901644660399],
              [102.557373046875, 47.080409706315635],
              [102.54226684570312, 47.00179736417431],
              [102.51205444335938, 46.953074529521054],
              [102.46536254882812, 46.930572093016316],
              [102.40493774414062, 46.95776134668866],
              [102.32940673828125, 47.01116205043071],
              [102.29644775390625, 47.02614213366051],
              [102.26486206054688, 47.02614213366051],
              [102.14263916015625, 47.01116205043071],
              [102.09869384765625, 46.99524110694596],
              [102.00256347656249, 46.98118927533914],
              [101.92291259765625, 46.961510504873075],
              [101.87759399414062, 46.92963428624288],
              [101.84738159179686, 46.88460024993555],
              [101.81991577148438, 46.87239702052007],
              [101.77047729492188, 46.862069043222895],
              [101.62628173828125, 46.85173907873366],
              [101.41342163085938, 46.84798223530896],
              [101.28849506378174, 46.826199106709744],
              [101.28849506378174, 46.82616974343502],
              [101.26012802124023, 46.82367380645847],
              [101.22905731201172, 46.827197448507164],
              [101.20536804199219, 46.836240405913],
              [101.17652893066405, 46.850330293243495],
              [101.12091064453125, 46.83389173208538],
              [101.06666564941406, 46.82637528602131],
              [100.99113464355469, 46.82637528602131],
              [100.86685180664062, 46.83905867900795],
              [100.75561523437499, 46.830133640447386],
              [100.69381713867186, 46.83717984670198],
              [100.67459106445312, 46.86582490125156],
              [100.60729980468749, 46.931040990245364],
              [100.55992126464844, 46.95213711682888],
              [100.46859741210938, 46.97181933529799],
              [100.43838500976562, 47.00273390667881],
              [100.43426513671875, 47.041118014219414],
              [100.42053222656249, 47.089760603235646],
              [100.447998046875, 47.184112659842015],
              [100.23239135742188, 47.140227084105625],
              [100.0799560546875, 47.11219605898181],
              [99.942626953125, 47.05889641405164],
              [99.920654296875, 47.121541375281744],
              [99.91928100585938, 47.19251214527278],
              [99.90005493164062, 47.25593196573788],
              [99.89456176757812, 47.35650163792198],
              [99.86572265625, 47.408573553097554],
              [99.76821899414062, 47.4921528170241],
              [99.75723266601561, 47.53018378265907],
              [99.75860595703125, 47.619124098197325],
              [99.7174072265625, 47.62004976487241],
              [99.66110229492188, 47.60153331738691],
              [99.46884155273436, 47.5774521343697],
              [99.40567016601562, 47.5561403129434],
              [99.3768310546875, 47.517200697839414],
              [99.33837890625, 47.489368981370724],
              [99.26696777343749, 47.46152250874388],
              [99.21203613281249, 47.454094290400015],
              [99.05685424804688, 47.454094290400015],
              [98.97171020507812, 47.47266286861342],
              [98.93463134765625, 47.50421439972969],
              [98.83575439453125, 47.55521351530769],
              [98.800048828125, 47.646886969413],
              [98.79043579101562, 47.72362073407563],
              [98.55697631835936, 47.69682297134991],
              [98.48281860351562, 47.667237034505156],
              [98.4166431427002, 47.614958395343585],
              [98.41651439666748, 47.61481374692148]
            ]
          ]
        }
      },
      // {
      //   type: "Feature",
      //   properties: {
      //     TYPE: "States",
      //     color: "#3867D6",
      //     aimag_city_ID: 40260,
      //     name: "Baganuur"
      //   },
      //   geometry: {
      //     type: "Polygon",
      //     coordinates: [
      //       [
      //         [108.3526611328125, 47.56355410390809],
      //         [108.30184936523438, 47.57281986733871],
      //         [108.25996398925781, 47.58856790334661],
      //         [108.22700500488281, 47.62004976487241],
      //         [108.21945190429688, 47.6603004033596],
      //         [108.19953918457031, 47.714381682734256],
      //         [108.20159912109374, 47.75040471827431],
      //         [108.21258544921875, 47.782711868678575],
      //         [108.23524475097656, 47.82375912844477],
      //         [108.24829101562499, 47.84265762816535],
      //         [108.27713012695312, 47.868919717114395],
      //         [108.35334777832031, 47.91818280779197],
      //         [108.45291137695312, 47.90529605906089],
      //         [108.45325469970703, 47.90483575868255],
      //         [108.43883514404297, 47.86523461231467],
      //         [108.4357452392578, 47.83597479903542],
      //         [108.43677520751953, 47.805084340797606],
      //         [108.4457015991211, 47.7483271435476],
      //         [108.44192504882812, 47.716922585179084],
      //         [108.42544555664062, 47.676253276991204],
      //         [108.40072631835938, 47.632081940263305],
      //         [108.35163116455078, 47.56355410390809],
      //         [108.3526611328125, 47.56355410390809]
      //       ]
      //     ]
      //   }
      // },
      {
        type: "Feature",
        properties: {
          TYPE: "States",
          color: "#3867D6",
          aimag_city_ID: 40257,
          name: "Баянхонгор",
          schedule_type: "Y",
          sub_coverage: "73",
          ebs_coverage: "56"
        },
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [97.56769180297852, 46.597444318795134],
              [97.63824462890625, 46.67205646734499],
              [97.69866943359375, 46.758679967095574],
              [97.7288818359375, 46.85079989251277],
              [97.734375, 46.9446372241817],
              [97.75360107421874, 46.9933677428342],
              [97.84423828125, 47.05889641405164],
              [97.8607177734375, 47.08882558740757],
              [97.86895751953125, 47.15236927446393],
              [97.9046630859375, 47.184112659842015],
              [97.96234130859375, 47.18971246448421],
              [98.052978515625, 47.172911278266604],
              [98.13812255859375, 47.200910301521674],
              [98.19580078125, 47.2270293988673],
              [98.2562255859375, 47.27922900257082],
              [98.2781982421875, 47.359292508710745],
              [98.272705078125, 47.42065432071321],
              [98.28369140625, 47.4596655525415],
              [98.37432861328125, 47.52461999690649],
              [98.40625762939453, 47.563090772707994],
              [98.4166431427002, 47.61490053602275],
              [98.4166431427002, 47.614958395343585],
              [98.48281860351562, 47.667237034505156],
              [98.55697631835936, 47.69682297134991],
              [98.79043579101562, 47.72362073407563],
              [98.800048828125, 47.646886969413],
              [98.83575439453125, 47.55521351530769],
              [98.93463134765625, 47.50421439972969],
              [98.97171020507812, 47.47266286861342],
              [99.05685424804688, 47.454094290400015],
              [99.21203613281249, 47.454094290400015],
              [99.26696777343749, 47.46152250874388],
              [99.33837890625, 47.489368981370724],
              [99.3768310546875, 47.517200697839414],
              [99.40567016601562, 47.5561403129434],
              [99.46884155273436, 47.5774521343697],
              [99.66110229492188, 47.60153331738691],
              [99.7174072265625, 47.62004976487241],
              [99.75860595703125, 47.619124098197325],
              [99.75723266601561, 47.53018378265907],
              [99.76821899414062, 47.4921528170241],
              [99.86572265625, 47.408573553097554],
              [99.89456176757812, 47.35650163792198],
              [99.90005493164062, 47.25593196573788],
              [99.91928100585938, 47.19251214527278],
              [99.920654296875, 47.121541375281744],
              [99.942626953125, 47.05889641405164],
              [100.0799560546875, 47.11219605898181],
              [100.23239135742188, 47.140227084105625],
              [100.447998046875, 47.184112659842015],
              [100.42053222656249, 47.089760603235646],
              [100.43426513671875, 47.041118014219414],
              [100.43838500976562, 47.00273390667881],
              [100.46859741210938, 46.97181933529799],
              [100.55992126464844, 46.95213711682888],
              [100.60729980468749, 46.931040990245364],
              [100.67459106445312, 46.86582490125156],
              [100.69381713867186, 46.83717984670198],
              [100.75561523437499, 46.830133640447386],
              [100.86685180664062, 46.83905867900795],
              [100.99113464355469, 46.82637528602131],
              [101.06666564941406, 46.82637528602131],
              [101.12091064453125, 46.83389173208538],
              [101.17652893066405, 46.850330293243495],
              [101.20536804199219, 46.836240405913],
              [101.22905731201172, 46.827197448507164],
              [101.26012802124023, 46.82367380645847],
              [101.28849506378174, 46.82616974343502],
              [101.28851652145386, 46.82616974343502],
              [101.436767578125, 46.638122462379656],
              [101.49856567382812, 46.580574564531695],
              [101.47933959960938, 46.54658317951774],
              [101.49993896484375, 46.50689964492765],
              [101.524658203125, 46.46056554578543],
              [101.4971923828125, 46.3810438458062],
              [101.49169921875, 46.195042108660154],
              [101.42578124999999, 46.115133713265415],
              [101.2994384765625, 46.06179698466216],
              [101.27197265625, 45.96642454131025],
              [101.29394531249999, 45.84410779560204],
              [101.3214111328125, 45.75985868785574],
              [101.2445068359375, 45.6178796835697],
              [101.2335205078125, 45.55252525134013],
              [101.151123046875, 45.47939202177826],
              [101.129150390625, 45.089035564831036],
              [101.1895751953125, 44.999767019181306],
              [101.3653564453125, 44.94536144236941],
              [101.5576171875, 44.867549659447214],
              [101.5850830078125, 44.809121700077355],
              [101.5521240234375, 44.766236875162335],
              [101.53564453124999, 44.64911632343077],
              [101.5960693359375, 44.574817404670306],
              [101.62353515625, 44.535674532413196],
              [101.68395996093749, 44.382765762252404],
              [101.7828369140625, 44.083146046165986],
              [101.80789947509764, 44.048917712794136],
              [101.8077278137207, 44.04897940326519],
              [101.74713134765625, 43.99676629896825],
              [101.4093017578125, 43.89789239125797],
              [101.2774658203125, 43.84839376489157],
              [101.15936279296875, 43.83650797709095],
              [100.58258056640625, 43.83254552160589],
              [100.40130615234375, 43.810747313446996],
              [100.23101806640625, 43.8503744993026],
              [100.01678466796875, 44.04219306625442],
              [99.91790771484375, 44.07377376789347],
              [99.7833251953125, 44.14476875978378],
              [99.64324951171875, 44.14082683077555],
              [99.55810546875, 44.125056482685146],
              [99.45373535156249, 44.10730980734024],
              [99.41528320312499, 44.050089820756796],
              [99.44549560546874, 43.8543357707896],
              [99.4482421875, 43.51668853502909],
              [99.49218749999999, 43.203173782105814],
              [99.580078125, 42.85985981506279],
              [99.5965576171875, 42.73289174571287],
              [99.59687948226929, 42.57930224162034],
              [99.5969009399414, 42.57937729240967],
              [99.50111389160155, 42.56623017635374],
              [98.52264404296875, 42.64204079304428],
              [98.184814453125, 42.656181890253855],
              [97.78913497924803, 42.709308508300325],
              [97.789306640625, 42.70968691975666],
              [97.789306640625, 42.98857645832184],
              [97.745361328125, 43.28520334369384],
              [97.84423828125, 43.40504748787035],
              [97.8662109375, 43.50075243569041],
              [97.91015624999999, 43.667871610117494],
              [97.9541015625, 43.94537239244209],
              [97.93212890625, 44.07969327425713],
              [97.91015624999999, 44.33956524809713],
              [97.987060546875, 44.41024041296011],
              [98.10791015625, 44.449467536006935],
              [98.184814453125, 44.6061127451739],
              [98.184814453125, 44.91813929958515],
              [98.360595703125, 45.166547157856016],
              [98.470458984375, 45.43700828867389],
              [98.404541015625, 45.78284835197676],
              [98.20678710937499, 45.98932892799953],
              [97.745361328125, 46.27863122156088],
              [97.657470703125, 46.532413816559455],
              [97.56752014160155, 46.59756227061819],
              [97.56769180297852, 46.597444318795134]
            ]
          ]
        }
      },
      {
        type: "Feature",
        properties: {
          TYPE: "States",
          color: "#5F27CD",
          aimag_city_ID: 40256,
          name: "Баян-Өлгий",
          schedule_type: "Y",
          sub_coverage: "80",
          ebs_coverage: "92"
          
        },
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [91.04844331741333, 46.719850466635805],
              [91.04838967323302, 46.71986517728791],
              [90.98602294921875, 46.76808718675661],
              [90.98602294921875, 46.794418663019734],
              [90.93658447265625, 46.81885778879603],
              [90.94757080078124, 46.875213396722685],
              [90.90911865234374, 46.916503267244835],
              [90.92010498046875, 46.93901161506044],
              [90.81024169921875, 46.991494313050424],
              [90.76080322265624, 46.98587362966408],
              [90.7305908203125, 47.02146130911727],
              [90.648193359375, 47.11499982620772],
              [90.56304931640625, 47.20650833393471],
              [90.5108642578125, 47.301584511330795],
              [90.48065185546874, 47.320206883852414],
              [90.50811767578125, 47.3425450696602],
              [90.4998779296875, 47.39834920035926],
              [90.45867919921875, 47.409502941311075],
              [90.47241210937499, 47.48565697095909],
              [90.4010009765625, 47.535746978239125],
              [90.3460693359375, 47.62467785241324],
              [90.37353515625, 47.646886969413],
              [90.252685546875, 47.70421683390384],
              [90.12359619140625, 47.72823964536174],
              [90.06866455078125, 47.78178908571311],
              [90.07415771484375, 47.857402894658236],
              [89.9560546875, 47.88503897004144],
              [89.95880126953125, 47.833439707627605],
              [89.76104736328125, 47.82975208084834],
              [89.73358154296875, 47.864773955792245],
              [89.7528076171875, 47.879512933970496],
              [89.72259521484375, 47.89608927375508],
              [89.65118408203125, 47.907137219641115],
              [89.6429443359375, 47.94394667836211],
              [89.59075927734375, 47.97521412341618],
              [89.58251953125, 48.028509034432986],
              [89.40948486328125, 48.03034580796616],
              [89.28863525390625, 48.01381248943335],
              [89.23370361328125, 47.97521412341618],
              [89.1595458984375, 47.9954359160952],
              [89.11560058593749, 47.98256841921402],
              [89.05517578125, 47.98624517426206],
              [88.93157958984375, 48.10559716402152],
              [88.846435546875, 48.10926514749487],
              [88.81622314453125, 48.09642606004488],
              [88.692626953125, 48.17524408990215],
              [88.66241455078125, 48.1642534885474],
              [88.59100341796875, 48.22101291025667],
              [88.59374999999999, 48.25211235426607],
              [88.57177734375, 48.272225451004324],
              [88.604736328125, 48.334343174592014],
              [88.49212646484374, 48.40185599006367],
              [88.4344482421875, 48.38909015961602],
              [88.34930419921875, 48.42920055556841],
              [88.35205078124999, 48.46017328524599],
              [88.2147216796875, 48.500227605781035],
              [88.17626953125, 48.4965876108066],
              [88.13232421875, 48.52933815687993],
              [88.0059814453125, 48.552978164400734],
              [87.95379638671874, 48.59477574898104],
              [88.0279541015625, 48.63290858589532],
              [88.0059814453125, 48.65105695744788],
              [88.08563232421875, 48.7127125814524],
              [87.95928955078125, 48.77067246880509],
              [87.93731689453125, 48.75618876280552],
              [87.8631591796875, 48.801436476249386],
              [87.7972412109375, 48.81952414194507],
              [87.77526855468749, 48.87013489622034],
              [87.74505615234375, 48.87013489622034],
              [87.75054931640625, 48.92430395329745],
              [87.8082275390625, 48.94415123418794],
              [87.87689208984375, 48.956777213851424],
              [87.90435791015625, 48.982019588328214],
              [87.83294677734375, 49.04214406667087],
              [87.82848358154297, 49.05114522570259],
              [87.8353500366211, 49.0600322713146],
              [87.85457611083984, 49.070717120839156],
              [87.83775329589842, 49.08196184888935],
              [87.85388946533203, 49.11905139208892],
              [87.8353500366211, 49.13702422419125],
              [87.83294677734375, 49.14600819722733],
              [87.82985687255858, 49.149601330435864],
              [87.81818389892578, 49.145109873210956],
              [87.81166076660156, 49.1536432938359],
              [87.82093048095703, 49.16509373263508],
              [87.81452536582947, 49.17018664326377],
              [87.81453609466553, 49.1701726139265],
              [87.81972885131836, 49.17418484235879],
              [87.82281875610352, 49.17788814921708],
              [87.83105850219727, 49.176429303833146],
              [87.84736633300781, 49.17766371426438],
              [87.85423278808594, 49.175980419679945],
              [87.86144256591797, 49.17811258315209],
              [87.86985397338867, 49.174633742795336],
              [87.87483215332031, 49.17137912239958],
              [87.87946701049805, 49.172276970087815],
              [87.8847885131836, 49.17194027911297],
              [87.89302825927734, 49.176092641099864],
              [87.90384292602538, 49.17755149640637],
              [87.91156768798828, 49.174297067849565],
              [87.91912078857422, 49.1729503451679],
              [87.92736053466797, 49.17452151806784],
              [87.93130874633789, 49.17990801799526],
              [87.94332504272461, 49.18136676084692],
              [87.9465866088867, 49.18058128926962],
              [87.94916152954102, 49.18125455138487],
              [87.95190811157227, 49.18349869228674],
              [87.9568862915039, 49.18551833208798],
              [87.96598434448241, 49.18383530464464],
              [87.97611236572266, 49.18652812107726],
              [87.98091888427734, 49.184284117560004],
              [87.98778533935547, 49.182825460701736],
              [88.0030632019043, 49.1999900051565],
              [88.00083160400389, 49.208626117966396],
              [88.00649642944336, 49.20952328994859],
              [88.01130294799805, 49.2140089056539],
              [88.01336288452148, 49.218381989099505],
              [88.01971435546875, 49.2207365660853],
              [88.01816940307617, 49.22645435746312],
              [88.0305290222168, 49.2327319545609],
              [88.05507659912108, 49.23990537453461],
              [88.05730819702148, 49.24293134862419],
              [88.05524826049805, 49.25111175765098],
              [88.05164337158203, 49.25223225608003],
              [88.05164337158203, 49.25626583984384],
              [88.06297302246092, 49.26018706362073],
              [88.07344436645508, 49.25917877869041],
              [88.08202743530273, 49.26108329959868],
              [88.08125495910645, 49.258842679135945],
              [88.0840015411377, 49.2554255370738],
              [88.08940887451172, 49.254641241581595],
              [88.09507369995117, 49.255873700331406],
              [88.10099601745605, 49.25503339088543],
              [88.10666084289551, 49.25592972045248],
              [88.11103820800781, 49.26158742518368],
              [88.11567306518555, 49.265116160083664],
              [88.11661720275879, 49.267972570183545],
              [88.12331199645996, 49.272620884054454],
              [88.12339782714844, 49.27530886569056],
              [88.12580108642577, 49.27822068050448],
              [88.12949180603027, 49.279620530283694],
              [88.13172340393065, 49.279620530283694],
              [88.13575744628906, 49.27956453705538],
              [88.13764572143553, 49.280964348691164],
              [88.14013481140137, 49.27995648831844],
              [88.14957618713379, 49.281748225852034],
              [88.1539535522461, 49.286843123686374],
              [88.16253662109375, 49.28919443744276],
              [88.1597900390625, 49.31706575342583],
              [88.17214965820311, 49.326912087086605],
              [88.15841674804688, 49.337651296668845],
              [88.165283203125, 49.350177413469],
              [88.1597900390625, 49.36270034165353],
              [88.121337890625, 49.37432592004196],
              [88.1158447265625, 49.38952445158216],
              [88.1707763671875, 49.41276017251568],
              [88.18588256835938, 49.41633392300034],
              [88.17352294921875, 49.44044993310386],
              [88.1707763671875, 49.453842594330744],
              [88.21884155273436, 49.47972469948566],
              [88.24081420898438, 49.47704787438876],
              [88.25866699218749, 49.48150916826499],
              [88.3026123046875, 49.47169378524674],
              [88.319091796875, 49.47347854665752],
              [88.32321166992188, 49.485077910741936],
              [88.33145141601562, 49.49399862898744],
              [88.36715698242186, 49.48418574950772],
              [88.385009765625, 49.48686218444083],
              [88.40835571289062, 49.49489061140408],
              [88.44406127929688, 49.472586174080966],
              [88.49624633789062, 49.47347854665752],
              [88.52645874023438, 49.48597005571962],
              [88.55255126953125, 49.48686218444083],
              [88.58688354492188, 49.50024216453777],
              [88.61297607421875, 49.49667452747045],
              [88.63220214843749, 49.49578257756505],
              [88.67340087890625, 49.46544660818596],
              [88.73245239257812, 49.450271575719945],
              [88.74893188476561, 49.46187643504186],
              [88.79974365234375, 49.459198634468564],
              [88.8409423828125, 49.44134289100633],
              [88.86566162109375, 49.45830600175986],
              [88.85330200195312, 49.46723159720791],
              [88.868408203125, 49.46990895880499],
              [88.87527465820312, 49.47704787438876],
              [88.86016845703125, 49.489538473066474],
              [88.88214111328124, 49.50826839701879],
              [88.87527465820312, 49.54659778073743],
              [88.88076782226562, 49.55639814511971],
              [88.9013671875, 49.55728898983402],
              [88.92608642578125, 49.54303352434694],
              [88.93569946289062, 49.513618487218466],
              [88.96041870117186, 49.4984583785148],
              [88.97140502929688, 49.46455408928758],
              [89.00299072265625, 49.4690165211973],
              [89.03732299804688, 49.48597005571962],
              [89.07852172851562, 49.48418574950772],
              [89.12246704101562, 49.50202588554],
              [89.14581298828125, 49.50113403316642],
              [89.16229248046874, 49.515401720585665],
              [89.20074462890625, 49.522534003908106],
              [89.23507690429688, 49.55461640694068],
              [89.197998046875, 49.58756804188785],
              [89.197998046875, 49.61070993807422],
              [89.18563842773436, 49.621387109259516],
              [89.24057006835936, 49.647180616086885],
              [89.3353271484375, 49.596470070892686],
              [89.384765625, 49.603590524348704],
              [89.4287109375, 49.621387109259516],
              [89.4561767578125, 49.66051672522669],
              [89.53857421875, 49.688954878870334],
              [89.7198486328125, 49.73513141624827],
              [89.7198486328125, 49.78835749241401],
              [89.659423828125, 49.78835749241401],
              [89.6209716796875, 49.80254124506291],
              [89.62646484375, 49.84506757956392],
              [89.62646484375, 49.898172936240364],
              [89.593505859375, 49.91939873027269],
              [89.71435546875, 49.94415035164548],
              [90.0054931640625, 49.993615462541136],
              [90.02540588378906, 50.00023639420785],
              [90.02540588378906, 50.00023639420785],
              [90.04600524902344, 49.983461596534674],
              [90.07965087890625, 49.97418880321091],
              [90.12908935546874, 49.917630270866894],
              [90.16754150390625, 49.807859081298325],
              [90.21697998046875, 49.742231602580716],
              [90.3021240234375, 49.692508478893274],
              [90.40924072265625, 49.648069803718805],
              [90.5108642578125, 49.5822260446217],
              [90.59051513671875, 49.47169378524674],
              [90.59051513671875, 49.42884000063522],
              [90.62072753906249, 49.40203735979645],
              [90.6646728515625, 49.33407182046059],
              [90.74432373046875, 49.30005381244688],
              [90.81298828125, 49.30721745093609],
              [90.8953857421875, 49.28393181795616],
              [90.9393310546875, 49.1888842152458],
              [91.00250244140624, 49.09904896047899],
              [91.02447509765625, 49.04506962208049],
              [91.11785888671875, 48.97661158387714],
              [91.20849609375, 48.942347261978476],
              [91.2744140625, 48.8737479930069],
              [91.31977558135986, 48.77369880939393],
              [91.33822917938232, 48.671919512374565],
              [91.33827209472655, 48.671862832349746],
              [91.26033782958983, 48.702517412636006],
              [91.2205123901367, 48.7127125814524],
              [91.19510650634766, 48.71384525048727],
              [91.16901397705078, 48.710447166883384],
              [91.14086151123047, 48.70342373350562],
              [91.10172271728516, 48.68257422428195],
              [91.04679107666016, 48.65967514645313],
              [90.96576690673828, 48.63404305042837],
              [90.94310760498047, 48.630412674131264],
              [90.90808868408203, 48.62950503924253],
              [90.88233947753906, 48.633135480842654],
              [90.83427429199219, 48.64470577018957],
              [90.81436157226562, 48.6478814638068],
              [90.73917388916016, 48.65241782207755],
              [90.71891784667969, 48.63880752291323],
              [90.77934265136719, 48.58432959223574],
              [90.79891204833984, 48.56229474861628],
              [90.81024169921875, 48.54525091510785],
              [90.81710815429688, 48.525018116559266],
              [90.83324432373047, 48.453114986767304],
              [90.83427429199219, 48.412795581099964],
              [90.82019805908203, 48.34871995385989],
              [90.81745147705078, 48.29895447218437],
              [90.82054138183594, 48.272225451004324],
              [90.83427429199219, 48.22375773926874],
              [90.85487365722655, 48.11889235864144],
              [90.86585998535156, 48.09527955700514],
              [90.88680267333984, 48.068444087227796],
              [90.9286880493164, 48.0406764393366],
              [90.94757080078124, 48.02414643492423],
              [90.98533630371094, 47.97521412341618],
              [91.05949401855469, 47.83989242396357],
              [91.07460021972656, 47.78155838741233],
              [91.08455657958984, 47.76494541923992],
              [91.0982894897461, 47.75109722475338],
              [91.11648559570312, 47.73955424703249],
              [91.1367416381836, 47.731010795533955],
              [91.17107391357422, 47.72246594225631],
              [91.1703872680664, 47.661225340769356],
              [91.17382049560545, 47.645036570200254],
              [91.1916732788086, 47.61287541946062],
              [91.21742248535156, 47.59273570820418],
              [91.25656127929688, 47.545712894408624],
              [91.26136779785156, 47.528561072779034],
              [91.26205444335938, 47.49493650511712],
              [91.2527847290039, 47.45989767565368],
              [91.2366485595703, 47.36905939398393],
              [91.28986358642578, 47.33510005753562],
              [91.35612487792969, 47.258961160390896],
              [91.40041351318358, 47.22749569440931],
              [91.44847869873047, 47.20697480997098],
              [91.46427154541016, 47.159139675920336],
              [91.46873474121094, 47.13041790553795],
              [91.46358489990234, 47.10565336099383],
              [91.44882202148438, 47.06310621972502],
              [91.44092559814453, 47.00835281693705],
              [91.44332885742188, 46.971350795196734],
              [91.45465850830077, 46.94369966371151],
              [91.49311065673828, 46.90688744465985],
              [91.51542663574219, 46.89234316754865],
              [91.5707015991211, 46.86605963365364],
              [91.63524627685547, 46.826845094695884],
              [91.70665740966797, 46.79629898997745],
              [91.76055908203125, 46.76926297371478],
              [91.86492919921875, 46.72950714675161],
              [91.92672729492188, 46.702673044474004],
              [91.87179565429688, 46.63717954716511],
              [91.83677673339842, 46.62256226021187],
              [91.77841186523438, 46.616431255357995],
              [91.56829833984375, 46.613601326659726],
              [91.50238037109375, 46.56735822330717],
              [91.43096923828124, 46.54280504427768],
              [91.34994506835938, 46.55886030311719],
              [91.31973266601562, 46.60133659339023],
              [91.27304077148438, 46.66263249079177],
              [91.16386413574219, 46.68995749641134],
              [91.10275268554688, 46.71209003858017],
              [91.05352878570557, 46.7231529074099],
              [91.04844331741333, 46.719850466635805]
            ]
          ]
        }
      },
      {
        type: "Feature",
        properties: {
          TYPE: "States",
          color: "#3867D6",
          aimag_city_ID: 40258,
          name: "Булган",
          schedule_type: "Y",
          sub_coverage: "73",
          ebs_coverage: "73"
        },
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [101.6658067703247, 48.962750894091165],
              [101.63246154785156, 49.06351915895547],
              [101.634521484375, 49.127813961593255],
              [101.7169189453125, 49.2355341957325],
              [101.788330078125, 49.24270720875383],
              [101.8377685546875, 49.30363576187125],
              [102.00256347656249, 49.38594874941847],
              [102.1563720703125, 49.40739905889802],
              [102.29919433593749, 49.439556958940855],
              [102.40905761718749, 49.50024216453777],
              [102.48870849609375, 49.596470070892686],
              [102.5079345703125, 49.62672481765915],
              [102.53402709960938, 49.64184514921545],
              [102.57247924804686, 49.64273443429918],
              [102.64389038085938, 49.635619698841445],
              [102.67822265625, 49.64362370314076],
              [102.78482437133789, 49.69173115108819],
              [102.70843505859375, 49.742231602580716],
              [102.5628662109375, 49.786584231227586],
              [102.48596191406249, 49.80254124506291],
              [102.20718383789062, 49.8787077521734],
              [102.13302612304688, 49.90348026046574],
              [102.10281372070312, 49.92912409759547],
              [102.10418701171874, 49.96977255919705],
              [102.12753295898436, 50.004208515595614],
              [102.21817016601562, 50.07388786455134],
              [102.22091674804686, 50.08710669808234],
              [102.24838256835938, 50.11089141378986],
              [102.35687255859375, 50.18393346184497],
              [102.37884521484375, 50.217337177613686],
              [102.39532470703125, 50.2691545281742],
              [102.41455078125, 50.29986701674912],
              [102.50106811523438, 50.35772808048406],
              [102.645263671875, 50.41114309193372],
              [102.645263671875, 50.41125248716129],
              [102.67822265625, 50.396262987870436],
              [102.74414062499999, 50.40501655606602],
              [102.76336669921875, 50.38050249104245],
              [102.85675048828125, 50.34020186462779],
              [102.9034423828125, 50.331436330838834],
              [102.9254150390625, 50.30863837348728],
              [102.974853515625, 50.305130024910035],
              [103.04351806640625, 50.31214646324747],
              [103.07922363281249, 50.32091555536218],
              [103.150634765625, 50.31214646324747],
              [103.18084716796874, 50.331436330838834],
              [103.20831298828125, 50.33844888725473],
              [103.27972412109375, 50.28758439955972],
              [103.25775146484374, 50.25247373417045],
              [103.27423095703124, 50.23842221881728],
              [103.26599121093749, 50.20679116099068],
              [103.29071044921875, 50.189209286782585],
              [103.35113525390625, 50.20327530414826],
              [103.42803955078125, 50.21382209771753],
              [103.524169921875, 50.1962428134763],
              [103.53515625, 50.182174724020506],
              [103.59283447265625, 50.17162093714734],
              [103.6065673828125, 50.14698636755676],
              [103.6669921875, 50.14522626967904],
              [103.721923828125, 50.12938247393472],
              [103.76312255859375, 50.168102490105014],
              [103.798828125, 50.15754559464304],
              [103.84002685546875, 50.1962428134763],
              [103.875732421875, 50.1962428134763],
              [103.88397216796875, 50.182174724020506],
              [103.94439697265624, 50.18393346184497],
              [103.9581298828125, 50.16634316943973],
              [104.0185546875, 50.154026111358014],
              [104.1448974609375, 50.14522626967904],
              [104.2767333984375, 50.20503326494332],
              [104.2767333984375, 50.2349086926556],
              [104.30694580078125, 50.24281376239237],
              [104.34505462646484, 50.25351645493256],
              [104.36067581176758, 50.26465567878524],
              [104.36067581176758, 50.26454594544213],
              [104.45114135742186, 50.21557967003726],
              [104.52117919921874, 50.14962639292665],
              [104.54864501953125, 50.10032188683107],
              [104.55551147460938, 50.05008477838258],
              [104.61456298828124, 50.0086215988794],
              [104.60220336914062, 49.993615462541136],
              [104.58023071289062, 49.98743510374161],
              [104.51156616210938, 49.983020075472226],
              [104.51568603515625, 49.90082667133223],
              [104.53216552734375, 49.85303710579436],
              [104.58297729492188, 49.81229016526221],
              [104.688720703125, 49.73779410787209],
              [104.732666015625, 49.686289508355735],
              [104.67361450195312, 49.64451295574021],
              [104.67086791992188, 49.54481568504451],
              [104.66537475585938, 49.48775429690567],
              [104.64065551757812, 49.435984899682005],
              [104.61181640625, 49.3993562906839],
              [104.5953369140625, 49.3653834114396],
              [104.54864501953125, 49.22836014090129],
              [104.53628540039062, 49.15296965617039],
              [104.51911926269531, 49.106017014812096],
              [104.51946258544922, 49.106017014812096],
              [104.44427490234375, 49.127813961593255],
              [104.39071655273438, 49.1314084139986],
              [104.33303833007812, 49.11882669045988],
              [104.2822265625, 49.080162863460984],
              [104.10232543945312, 49.12062427498834],
              [104.05838012695312, 49.095452162534826],
              [103.99932861328124, 49.03876821195109],
              [103.99932861328124, 48.98562459864604],
              [104.1558837890625, 48.957678947281124],
              [104.24514770507812, 48.94595514118904],
              [104.30694580078125, 48.927913136732556],
              [104.34677124023438, 48.90805939965008],
              [104.37801361083984, 48.899258060699516],
              [104.37801361083984, 48.89948375541988],
              [104.47723388671875, 48.83941303819501],
              [104.54452514648436, 48.786961645533275],
              [104.51568603515625, 48.73264383158835],
              [104.51980590820312, 48.66103577846602],
              [104.52821731567383, 48.625760872755635],
              [104.52838897705078, 48.62564740882851],
              [104.53834533691406, 48.53479452317522],
              [104.534912109375, 48.42191010942875],
              [104.50332641601562, 48.355336473558054],
              [104.43603515624999, 48.32429984442754],
              [104.39895629882812, 48.29781249243716],
              [104.36325073242188, 48.25668420754852],
              [104.33303833007812, 48.194471974688206],
              [104.28634643554688, 48.143181585289554],
              [104.33990478515625, 48.10559716402152],
              [104.37835693359375, 48.058807426879575],
              [104.39346313476562, 47.98624517426206],
              [104.44976806640625, 47.96785877999253],
              [104.55001831054688, 47.94946583788702],
              [104.66537475585938, 47.94762618352869],
              [104.68597412109375, 47.954064687296885],
              [104.70932006835938, 47.933826688535746],
              [104.710693359375, 47.90989883771289],
              [104.69146728515625, 47.897930761804936],
              [104.69558715820312, 47.82883013320963],
              [104.72991943359375, 47.7263921299974],
              [104.7601318359375, 47.66168780332917],
              [104.64614868164061, 47.65521295468833],
              [104.52392578125, 47.65336285044987],
              [104.51019287109375, 47.628380027447136],
              [104.4854736328125, 47.59875528481801],
              [104.38385009765625, 47.60060732292067],
              [104.28909301757812, 47.58486290927609],
              [104.15313720703125, 47.525547335516556],
              [104.07073974609375, 47.470806305936264],
              [104.095458984375, 47.41229100755385],
              [104.13803100585938, 47.386263315961884],
              [104.17098999023436, 47.35650163792198],
              [104.16961669921875, 47.32951560950956],
              [104.14764404296874, 47.29809208596965],
              [104.14764404296874, 47.29809208596965],
              [104.04876708984375, 47.27922900257082],
              [103.93890380859375, 47.26432008025478],
              [103.86474609375, 47.25593196573788],
              [103.77685546875, 47.26338813315705],
              [103.67712020874023, 47.25068871930782],
              [103.67729187011719, 47.250805241535076],
              [103.6669921875, 47.331377157798244],
              [103.4088134765625, 47.47637579720936],
              [103.1890869140625, 47.71345768748889],
              [103.084716796875, 47.724544549099676],
              [103.0133056640625, 47.83528342275264],
              [102.843017578125, 47.90529605906089],
              [102.83752441406249, 48.02299832104887],
              [102.755126953125, 48.1367666796927],
              [102.6287841796875, 48.25394114463431],
              [102.48596191406249, 48.25759852914997],
              [102.37060546875, 48.32703913063476],
              [102.337646484375, 48.42191010942875],
              [102.2772216796875, 48.44742209577057],
              [102.2332763671875, 48.494767515307295],
              [102.2003173828125, 48.6746400785837],
              [102.12890625, 48.81048112472012],
              [102.03826904296875, 48.77248263837077],
              [101.9586181640625, 48.77429274267509],
              [101.93389892578125, 48.832181625698475],
              [101.876220703125, 48.86290791986464],
              [101.81304931640625, 48.866521538507754],
              [101.74713134765625, 48.89000369970676],
              [101.66559219360352, 48.963089005540155],
              [101.6658067703247, 48.962750894091165]
            ]
          ]
        }
      },
      {
        type: "Feature",
        properties: {
          TYPE: "States",
          color: "#FA8231",
          aimag_city_ID: 40262,
          name: "Дорнод",
          schedule_type: "Y",
          sub_coverage: "74",
          ebs_coverage: "86"
        },
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [112.69432067871094, 47.79378398661931],
              [112.60986328125, 47.929226038300406],
              [112.54119873046875, 48.0762438718672],
              [112.510986328125, 48.24113823848045],
              [112.5164794921875, 48.32156041109599],
              [112.54119873046875, 48.39456162202509],
              [112.5164794921875, 48.47838371535879],
              [112.48077392578124, 48.5147849720974],
              [112.2967529296875, 48.741700879765396],
              [112.23907470703124, 48.83941303819501],
              [112.23907470703124, 48.89000369970676],
              [112.28851318359375, 48.963089005540155],
              [112.291259765625, 48.9991410647952],
              [112.26654052734375, 49.034266715880854],
              [112.2198486328125, 49.081062364320736],
              [112.19375610351562, 49.14039340493346],
              [112.18551635742186, 49.2202880838794],
              [112.16629028320312, 49.30184481970431],
              [112.15393066406249, 49.32064646618636],
              [112.08114624023438, 49.359122687528746],
              [111.99600219726562, 49.392206057422044],
              [111.99600219726562, 49.392206057422044],
              [112.20062255859374, 49.44848596885694],
              [112.4615478515625, 49.52877389852215],
              [112.686767578125, 49.50737665842872],
              [112.950439453125, 49.58578744112238],
              [113.0438232421875, 49.60715036117516],
              [113.2196044921875, 49.84860975344834],
              [113.4613037109375, 49.930008124606914],
              [113.4722900390625, 49.97242235423708],
              [113.6370849609375, 49.993615462541136],
              [113.7579345703125, 50.078294547389426],
              [113.9117431640625, 50.12057809796008],
              [114.334716796875, 50.28933925329178],
              [114.53247070312499, 50.233151832472245],
              [114.7137451171875, 50.25071752130677],
              [114.90600585937499, 50.20854899229104],
              [115.08178710937499, 50.10648772767335],
              [115.24658203125, 49.983020075472226],
              [115.51574707031249, 49.905249238801304],
              [115.72998046875, 49.88755653624285],
              [116.07604980468749, 50.01832895590886],
              [116.2298583984375, 50.035973672195496],
              [116.630859375, 49.930008124606914],
              [116.707763671875, 49.83798245308484],
              [116.03485107421875, 48.87013489622034],
              [116.070556640625, 48.821332549646634],
              [115.78765869140625, 48.51842366030161],
              [115.80963134765625, 48.26308411537845],
              [115.521240234375, 48.15326053170714],
              [115.56655883789064, 47.924624978768314],
              [115.93597412109374, 47.68203210030427],
              [115.960693359375, 47.68757916850813],
              [116.11175537109376, 47.824220149350246],
              [116.24908447265624, 47.879512933970496],
              [116.444091796875, 47.837127072368524],
              [116.66931152343749, 47.88688085106898],
              [116.87255859374999, 47.89424772020999],
              [117.0703125, 47.82790816919327],
              [117.35595703124999, 47.64318610543658],
              [117.48779296875, 47.78363463526376],
              [117.8173828125, 48.026672195436014],
              [118.02612304687499, 48.00830020485928],
              [118.18542480468751, 48.04870994288686],
              [118.43261718749999, 47.98992166741417],
              [118.5479736328125, 47.99359789867388],
              [118.75671386718749, 47.76886840424207],
              [119.1302490234375, 47.66168780332917],
              [119.1412353515625, 47.54316365431733],
              [119.33898925781251, 47.48008846346322],
              [119.3280029296875, 47.428087261714275],
              [119.56970214843749, 47.253135632244216],
              [119.70703125, 47.178512264439085],
              [119.794921875, 47.02146130911727],
              [119.91577148437499, 46.897739085507],
              [119.92675781249999, 46.7210346612957],
              [119.67956542968749, 46.604167162931844],
              [119.42138671875, 46.638122462379656],
              [119.2181396484375, 46.649436163350245],
              [119.08630371093749, 46.66074749832071],
              [118.9874267578125, 46.758679967095574],
              [118.85559082031249, 46.7774927637683],
              [118.795166015625, 46.694667307773116],
              [118.63586425781249, 46.717268685073954],
              [118.56994628906249, 46.694667307773116],
              [118.39965820312499, 46.73233101286786],
              [118.2843017578125, 46.74738913515841],
              [118.037109375, 46.63057868059483],
              [117.850341796875, 46.53997127029103],
              [117.69653320312499, 46.51351558059737],
              [117.630615234375, 46.543749602738565],
              [117.60314941406249, 46.604167162931844],
              [117.42187500000001, 46.58529390583601],
              [117.4383544921875, 46.53619267489863],
              [117.3614501953125, 46.41892578708076],
              [117.37243652343749, 46.3583020562222],
              [117.0703125, 46.3583020562222],
              [116.817626953125, 46.3810438458062],
              [116.74621582031249, 46.32417161725694],
              [116.57592773437499, 46.2890709079757],
              [116.56562805175781, 46.25537204270996],
              [116.54228210449219, 46.23732751752229],
              [116.54159545898438, 46.23685258143992],
              [116.34246826171874, 46.511625395437925],
              [116.2847900390625, 46.5739667965278],
              [116.19415283203125, 46.592843997427394],
              [115.6805419921875, 46.581518465658014],
              [115.5596923828125, 46.543749602738565],
              [115.224609375, 46.54563867035857],
              [114.89501953124999, 46.49839225859763],
              [114.6807861328125, 46.70596917928676],
              [114.52285766601562, 46.76714653871027],
              [114.43634033203125, 46.825435656353896],
              [114.4171142578125, 46.89680070399431],
              [114.42398071289062, 47.00086080525212],
              [114.4720458984375, 47.12621341795227],
              [114.18090820312499, 47.253135632244216],
              [114.071044921875, 47.32393057095941],
              [113.9666748046875, 47.364873807434094],
              [113.8128662109375, 47.468949677672484],
              [113.675537109375, 47.5153457091302],
              [113.61785888671875, 47.51349065484327],
              [113.17565917968749, 47.55984733956309],
              [113.10150146484375, 47.58764167941513],
              [112.9669189453125, 47.613569753973955],
              [112.87353515625, 47.60616304386874],
              [112.74169921875, 47.57652571374621],
              [112.70050048828125, 47.724544549099676],
              [112.69432067871094, 47.79378398661931]
            ]
          ]
        }
      },
      {
        type: "Feature",
        properties: {
          TYPE: "States",
          color: "#FF6348",
          aimag_city_ID: 40261,
          name: "Дорноговь",
          schedule_type: "Y",
          sub_coverage: "91",
          ebs_coverage: "91"
        },
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [108.55899810791016, 45.94112327491896],
              [108.56826782226561, 45.95878764035642],
              [108.599853515625, 45.98837476770814],
              [108.62457275390625, 46.08275685027116],
              [108.66989135742188, 46.18363372751015],
              [108.720703125, 46.214050815339526],
              [108.83331298828124, 46.3023549043942],
              [108.90884399414062, 46.38246489326114],
              [108.92669677734374, 46.42365918046987],
              [108.95965576171875, 46.464349400461124],
              [108.95828247070312, 46.464349400461124],
              [109.12170410156249, 46.37725420510028],
              [109.149169921875, 46.29001987172955],
              [109.21508789062499, 46.22545288226939],
              [109.324951171875, 46.16841886922939],
              [109.5172119140625, 46.16841886922939],
              [109.6160888671875, 46.14939437647686],
              [109.8797607421875, 46.26724020382508],
              [109.9346923828125, 46.33934333161126],
              [110.0115966796875, 46.46056554578543],
              [109.97863769531249, 46.60039303734547],
              [110.0225830078125, 46.63057868059483],
              [110.12695312499999, 46.604167162931844],
              [110.2093505859375, 46.51351558059737],
              [110.379638671875, 46.384833223492784],
              [110.511474609375, 46.229253045075275],
              [110.5609130859375, 46.0998999106273],
              [110.950927734375, 46.06560846138691],
              [111.05529785156249, 46.15700496290803],
              [111.1651611328125, 46.23685258143992],
              [111.16653442382812, 46.230203044669985],
              [111.36566162109375, 46.17412493440401],
              [111.5057373046875, 46.115133713265415],
              [111.57989501953125, 46.09418614922648],
              [111.61834716796875, 46.06179698466216],
              [111.70074462890625, 45.922497984579934],
              [111.7144775390625, 45.72727377526009],
              [111.68426513671875, 45.533288879467456],
              [111.7529296875, 45.49287107405929],
              [111.80511474609375, 45.41002023463975],
              [111.9012451171875, 45.321254361171476],
              [111.9232177734375, 45.27681919090837],
              [111.91223144531249, 45.21687321093267],
              [111.91223144531249, 45.17235628126675],
              [111.939697265625, 45.08758121648526],
              [111.95343017578125, 45.071944633095136],
              [111.95274353027344, 45.07303569638012],
              [111.785888671875, 45.00365115687189],
              [111.544189453125, 44.653024159812],
              [111.55517578125, 44.574817404670306],
              [111.46728515624999, 44.4808302785626],
              [111.4013671875, 44.33170718680922],
              [111.52221679687499, 44.284536706018905],
              [111.52221679687499, 44.18220395771566],
              [111.654052734375, 44.05601169578525],
              [111.87377929687499, 43.92163712834673],
              [111.961669921875, 43.79488907226601],
              [111.939697265625, 43.691707903073805],
              [111.77490234375, 43.65197548731187],
              [111.566162109375, 43.476840397778915],
              [111.412353515625, 43.48481212891603],
              [111.324462890625, 43.42100882994726],
              [111.005859375, 43.33316939281735],
              [110.72021484375, 43.08493742707592],
              [110.643310546875, 42.94838139765314],
              [110.45654296875, 42.84375132629021],
              [110.445556640625, 42.79540065303723],
              [110.14892578125, 42.68243539838623],
              [110.10498046875, 42.633958722673164],
              [109.896240234375, 42.633958722673164],
              [109.6875, 42.577354839557856],
              [109.522705078125, 42.4639928001706],
              [109.2919921875, 42.439674178149424],
              [109.017333984375, 42.45588764197166],
              [108.83056640625, 42.382894009614056],
              [108.533935546875, 42.439674178149424],
              [108.270263671875, 42.439674178149424],
              [108.2427978515625, 42.45791402988027],
              [108.0120849609375, 42.43359304732316],
              [107.9791259765625, 42.413318349422475],
              [107.9791259765625, 42.412304442420684],
              [107.98393249511719, 42.42877840024783],
              [107.9791259765625, 42.589488572714245],
              [108.00933837890625, 42.70060440808085],
              [108.0120849609375, 42.85381962412133],
              [108.0340576171875, 42.93631775765237],
              [108.017578125, 43.0287452513488],
              [108.00384521484375, 43.17113228474479],
              [107.95166015624999, 43.257205668363206],
              [107.9296875, 43.31118965238512],
              [107.93792724609375, 43.52266348752663],
              [107.9571533203125, 43.61619382369188],
              [107.95440673828125, 43.69766549666678],
              [107.85552978515625, 43.90778718292443],
              [107.83355712890625, 43.98886243884903],
              [107.7923583984375, 44.109281923355645],
              [107.64678955078125, 44.30419567985762],
              [107.64816284179686, 44.36313311380771],
              [107.68798828125, 44.555249259710656],
              [107.68661499023436, 44.554026032095756],
              [107.71270751953125, 44.57383915375391],
              [107.74017333984375, 44.60904581939581],
              [107.76489257812499, 44.66083904265621],
              [107.79510498046875, 44.75746105408348],
              [107.84042358398438, 44.86852295681338],
              [107.91183471679688, 44.86852295681338],
              [108.02169799804686, 44.87630874326681],
              [108.24966430664062, 44.87630874326681],
              [108.41583251953125, 44.88506649401471],
              [108.41583251953125, 44.92786297463683],
              [108.42269897460938, 44.968684437948376],
              [108.4405517578125, 45.02889163330817],
              [108.46595764160155, 45.06770141120143],
              [108.511962890625, 45.08564202775547],
              [108.58474731445312, 45.101638366018676],
              [108.65272521972655, 45.10793872360393],
              [108.52294921875, 45.36372498305678],
              [108.5174560546875, 45.41773242370463],
              [108.468017578125, 45.590978249451936],
              [108.47763061523438, 45.68795490838444],
              [108.49411010742188, 45.769438866203934],
              [108.53530883789062, 45.89000815866184],
              [108.55899810791016, 45.941362020083915],
              [108.55899810791016, 45.94112327491896]
            ]
          ]
        }
      },
      {
        type: "Feature",
        properties: {
          TYPE: "States",
          color: "#FF6348",
          aimag_city_ID: 40263,
          name: "Дундговь",
          schedule_type: "Y",
          sub_coverage: "50",
          ebs_coverage: "63"
        },
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [103.7823486328125, 45.168967697945575],
              [103.7823486328125, 45.27488643704894],
              [103.798828125, 45.33284041773058],
              [103.77685546875, 45.37144349133922],
              [103.6834716796875, 45.413876460821086],
              [103.623046875, 45.521743896993634],
              [103.623046875, 45.625563438215956],
              [103.71093749999999, 45.7176863579072],
              [103.8262939453125, 45.74836030216746],
              [104.073486328125, 45.74836030216746],
              [104.12841796875, 45.81348649679971],
              [104.1888427734375, 45.98169518512228],
              [104.2437744140625, 46.01603873833416],
              [104.3096923828125, 46.13036330589103],
              [104.43603515624999, 46.12274903582433],
              [104.5513916015625, 46.137976523476574],
              [104.6063232421875, 46.22165245637913],
              [104.65576171875, 46.3810438458062],
              [104.65353012084961, 46.380451731783644],
              [104.776611328125, 46.46056554578543],
              [104.85626220703124, 46.49650154751426],
              [104.96612548828124, 46.59095657312452],
              [105.22979736328125, 46.526745036021474],
              [105.39459228515624, 46.437856895024225],
              [105.4742431640625, 46.543749602738565],
              [105.65826416015625, 46.71350244599995],
              [105.7598876953125, 46.73609593770121],
              [105.84228515625, 46.72480037466717],
              [105.91094970703125, 46.6795944656402],
              [106.05926513671875, 46.63435070293566],
              [106.1224365234375, 46.617374532060765],
              [106.16363525390625, 46.57585481240773],
              [106.68548583984375, 46.574910812684244],
              [106.79946899414062, 46.55980458220511],
              [106.89834594726562, 46.526745036021474],
              [107.03704833984375, 46.43217825300941],
              [107.1002197265625, 46.403776166694634],
              [107.369384765625, 46.41892578708076],
              [107.53692626953125, 46.464822363804686],
              [107.60215759277344, 46.527689873863785],
              [107.63031005859375, 46.542332758884484],
              [107.67494201660156, 46.553666474384535],
              [107.74841308593749, 46.55980458220511],
              [107.78274536132812, 46.56641407568593],
              [107.81845092773438, 46.58576581737151],
              [107.83561706542969, 46.62350543010974],
              [107.84042358398438, 46.68336307047754],
              [107.85072326660155, 46.72291755083757],
              [107.85621643066406, 46.761972680830375],
              [107.9571533203125, 46.77514152351191],
              [107.95663833618164, 46.77455369740659],
              [108.06976318359375, 46.68430518061324],
              [108.19610595703125, 46.64660795992221],
              [108.12057495117188, 46.544694144765536],
              [108.1219482421875, 46.48231911886259],
              [108.16726684570312, 46.43691049578133],
              [108.19198608398438, 46.39998810407942],
              [108.20571899414062, 46.337447097476925],
              [108.15765380859375, 46.27388525189855],
              [108.11370849609375, 46.193140876238004],
              [108.14529418945312, 46.10370875598026],
              [108.21121215820312, 45.99982360564013],
              [108.31146240234375, 45.95019486846407],
              [108.42338562011719, 45.93467676685795],
              [108.55899810791016, 45.94112327491896],
              [108.55899810791016, 45.941362020083915],
              [108.53530883789062, 45.89000815866184],
              [108.49411010742188, 45.769438866203934],
              [108.47763061523438, 45.68795490838444],
              [108.468017578125, 45.590978249451936],
              [108.5174560546875, 45.41773242370463],
              [108.52294921875, 45.36372498305678],
              [108.65272521972655, 45.10793872360393],
              [108.58474731445312, 45.101638366018676],
              [108.511962890625, 45.08564202775547],
              [108.46595764160155, 45.06770141120143],
              [108.4405517578125, 45.02889163330817],
              [108.42269897460938, 44.968684437948376],
              [108.41583251953125, 44.92786297463683],
              [108.41583251953125, 44.88506649401471],
              [108.24966430664062, 44.87630874326681],
              [108.02169799804686, 44.87630874326681],
              [107.91183471679688, 44.86852295681338],
              [107.84042358398438, 44.86852295681338],
              [107.79510498046875, 44.75746105408348],
              [107.76489257812499, 44.66083904265621],
              [107.74017333984375, 44.60904581939581],
              [107.71270751953125, 44.57383915375391],
              [107.68661499023436, 44.554026032095756],
              [107.68730163574217, 44.554270679675696],
              [107.57812499999999, 44.520989167323734],
              [107.49160766601561, 44.49650533109348],
              [107.45040893554688, 44.491607329696045],
              [107.41333007812499, 44.49258696288604],
              [107.33779907226562, 44.512176171071054],
              [107.29660034179686, 44.51609322284931],
              [107.24716186523438, 44.50923820945519],
              [107.18536376953125, 44.491607329696045],
              [106.93130493164062, 44.457309801319305],
              [106.78848266601562, 44.43083793524685],
              [106.68136596679686, 44.38865427337759],
              [106.57699584960938, 44.351350365612305],
              [106.50421142578125, 44.308126684886126],
              [106.42318725585936, 44.29240108529005],
              [106.3641357421875, 44.265854585146684],
              [106.292724609375, 44.21863119293724],
              [106.22817993164061, 44.19795903948531],
              [106.13067626953125, 44.18220395771566],
              [106.06063842773438, 44.17826452922576],
              [105.96176147460938, 44.19303602884215],
              [105.80520629882812, 44.288469027276506],
              [105.76263427734375, 44.31205742666618],
              [105.74066162109375, 44.34644018791789],
              [105.7159423828125, 44.41122141189896],
              [105.5731201171875, 44.55916341529184],
              [105.50445556640625, 44.67744217165251],
              [105.42068481445312, 44.687206496456966],
              [105.12680053710938, 44.687206496456966],
              [104.99633789062499, 44.66962952692013],
              [104.81781005859374, 44.68525376315066],
              [104.71481323242188, 44.715513732021336],
              [104.359130859375, 44.77403648591524],
              [104.26712036132812, 44.83249999349062],
              [104.20944213867186, 44.94924926661153],
              [104.16824340820312, 45.097760877277096],
              [104.10919189453125, 45.20139301126898],
              [104.01031494140625, 45.18397275067566],
              [103.7823486328125, 45.17235628126675],
              [103.7823486328125, 45.168967697945575]
            ]
          ]
        }
      },
      {
        type: "Feature",
        properties: {
          TYPE: "States",
          color: "#5F27CD",
          aimag_city_ID: 40259,
          name: "Говь-Алтай",
          schedule_type: "Y",
          sub_coverage: "90",
          ebs_coverage: "93"
        },
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [93.21740627288818, 44.99686891680278],
              [93.21195602416992, 45.00498626838617],
              [93.15032958984375, 45.24201954967743],
              [93.153076171875, 45.50057194157226],
              [93.13934326171875, 45.54867850352087],
              [93.10638427734375, 45.61403741135093],
              [93.0816650390625, 45.66780526567164],
              [93.0816650390625, 45.72535642341016],
              [93.12286376953124, 45.77135470445036],
              [93.24371337890625, 45.82114340079471],
              [93.37554931640625, 45.891919851282076],
              [93.40576171875, 46.044642082970135],
              [93.46618652343749, 46.17222297845542],
              [93.5430908203125, 46.24824991289166],
              [93.61724853515625, 46.36777895261494],
              [93.6309814453125, 46.41892578708076],
              [93.61175537109375, 46.479482189368646],
              [93.55133056640625, 46.5607488448596],
              [93.52111816406249, 46.632464724627475],
              [93.6199951171875, 46.62492015414768],
              [93.68865966796875, 46.59850587595948],
              [93.76556396484375, 46.549416608392264],
              [93.78204345703125, 46.579630646972596],
              [93.8287353515625, 46.621147474556096],
              [93.91937255859375, 46.70031853924921],
              [93.98529052734375, 46.72291755083757],
              [94.12261962890625, 46.72291755083757],
              [94.251708984375, 46.741742832123364],
              [94.405517578125, 46.800059446787316],
              [94.33959960937499, 46.832012719114765],
              [94.27093505859375, 46.89210855010365],
              [94.20501708984375, 46.99524110694596],
              [94.12261962890625, 47.10191431533551],
              [94.06494140625, 47.2270293988673],
              [94.04296874999999, 47.28295557691231],
              [93.9935302734375, 47.31648293428332],
              [93.8788604736328, 47.33603074146188],
              [93.77311706542969, 47.38998234483188],
              [93.74908447265625, 47.428087261714275],
              [93.70719909667969, 47.47127045275428],
              [93.63990783691406, 47.514418190183974],
              [93.53948593139648, 47.557472555826074],
              [93.61441612243652, 47.65161386351681],
              [93.6143946647644, 47.65159940883532],
              [93.65432739257812, 47.66538735632654],
              [93.88092041015625, 47.71530566159559],
              [94.0869140625, 47.76702233051035],
              [94.185791015625, 47.78548011929362],
              [94.23248291015625, 47.77809779004784],
              [94.3011474609375, 47.73932336136857],
              [94.47006225585938, 47.68388118858139],
              [94.67468261718749, 47.669086647137576],
              [94.7735595703125, 47.67648444221321],
              [94.97131347656249, 47.65613798222679],
              [95.01663208007812, 47.63948497925488],
              [95.0482177734375, 47.59690318115471],
              [95.0701904296875, 47.54223662718361],
              [95.24871826171875, 47.568187190520135],
              [95.53024291992188, 47.535746978239125],
              [95.59478759765624, 47.51349065484327],
              [95.75065612792967, 47.43319679984749],
              [95.80284118652344, 47.42669366522116],
              [95.91682434082031, 47.37789454155521],
              [95.98342895507812, 47.332773275955894],
              [96.02119445800781, 47.30949982141431],
              [96.05552673339844, 47.301584511330795],
              [96.10771179199219, 47.29739357321423],
              [96.15577697753905, 47.26804770458176],
              [96.19903564453125, 47.23402340128701],
              [96.23954772949219, 47.201376826785406],
              [96.26701354980469, 47.16637604771991],
              [96.2896728515625, 47.09116309620141],
              [96.28898620605467, 47.05702528260841],
              [96.28005981445312, 46.998987638154624],
              [96.29310607910156, 46.933385414813976],
              [96.30889892578125, 46.89163931213447],
              [96.34185791015625, 46.82731489926434],
              [96.40296936035156, 46.849860689868386],
              [96.49429321289062, 46.87662152939774],
              [96.52656555175781, 46.89163931213447],
              [96.59042358398438, 46.922131240914],
              [96.64535522460938, 46.933385414813976],
              [96.69342041015624, 46.92682076739938],
              [96.73049926757812, 46.90524554642923],
              [96.74972534179688, 46.86113003765812],
              [96.81976318359375, 46.79911935722295],
              [96.85684204101562, 46.74738913515841],
              [96.90078735351562, 46.70314393319737],
              [96.92413330078125, 46.65791988638765],
              [96.9378662109375, 46.60133659339023],
              [97.0257568359375, 46.586237724798856],
              [97.0751953125, 46.581518465658014],
              [97.13836669921874, 46.57679879569828],
              [97.18231201171875, 46.57868671298067],
              [97.2344970703125, 46.60039303734547],
              [97.34813690185547, 46.67559003496991],
              [97.39208221435547, 46.693254407491],
              [97.41302490234375, 46.66357496238209],
              [97.42538452148438, 46.64872912635656],
              [97.44152069091797, 46.63670808339624],
              [97.4593734741211, 46.62350543010974],
              [97.48374938964844, 46.61147878308617],
              [97.50640869140625, 46.60463891014406],
              [97.52906799316406, 46.600628927897176],
              [97.56769180297852, 46.597532782686486],
              [97.56752014160155, 46.59756227061819],
              [97.657470703125, 46.532413816559455],
              [97.745361328125, 46.27863122156088],
              [98.20678710937499, 45.98932892799953],
              [98.404541015625, 45.78284835197676],
              [98.470458984375, 45.43700828867389],
              [98.360595703125, 45.166547157856016],
              [98.184814453125, 44.91813929958515],
              [98.184814453125, 44.6061127451739],
              [98.10791015625, 44.449467536006935],
              [97.987060546875, 44.41024041296011],
              [97.91015624999999, 44.33956524809713],
              [97.93212890625, 44.07969327425713],
              [97.9541015625, 43.94537239244209],
              [97.91015624999999, 43.667871610117494],
              [97.8662109375, 43.50075243569041],
              [97.84423828125, 43.40504748787035],
              [97.745361328125, 43.28520334369384],
              [97.789306640625, 42.98857645832184],
              [97.789306640625, 42.70968691975666],
              [97.78947830200194, 42.70943464570875],
              [97.1630859375, 42.79540065303723],
              [96.99829101562499, 42.76314586689494],
              [96.383056640625, 42.72280375732727],
              [96.361083984375, 42.90816007196054],
              [95.877685546875, 43.24520272203356],
              [95.855712890625, 43.40504748787035],
              [95.51513671875, 44.01652134387754],
              [95.3173828125, 44.05601169578525],
              [95.361328125, 44.2294565683017],
              [95.4052734375, 44.29240108529005],
              [94.98779296875, 44.26093725039923],
              [94.7021484375, 44.33956524809713],
              [94.6142578125, 44.457309801319305],
              [94.427490234375, 44.535674532413196],
              [94.32861328125, 44.512176171071054],
              [94.32861328125, 44.574817404670306],
              [94.21875, 44.66865287227321],
              [93.702392578125, 44.84808025602074],
              [93.6968994140625, 44.89479576469787],
              [93.4991455078125, 44.96285457777543],
              [93.37554931640625, 44.984227835166486],
              [93.2904052734375, 44.984227835166486],
              [93.21736335754395, 44.9969144378685],
              [93.21740627288818, 44.99686891680278]
            ]
          ]
        }
      },
      {
        type: "Feature",
        properties: {
          TYPE: "States",
          color: "#FF6348",
          aimag_city_ID: 40314,
          name: "Говьсүмбэр",
          schedule_type: "Y",
          sub_coverage: "83",
          ebs_coverage: "100"
        },
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [107.9568099975586, 46.77467126314099],
              [108.00315856933594, 46.793478474902564],
              [108.04779052734375, 46.803819640791566],
              [108.20159912109374, 46.81509864599243],
              [108.47900390625, 46.78031411656016],
              [108.70697021484375, 46.86113003765812],
              [108.7591552734375, 46.894923891703606],
              [108.82095336914062, 46.98025235521883],
              [108.82232666015625, 46.98399993718925],
              [108.88275146484375, 46.922131240914],
              [108.91571044921875, 46.856434763486966],
              [108.9788818359375, 46.83389173208538],
              [109.00634765625, 46.81509864599243],
              [108.99810791015625, 46.779373682055635],
              [108.96514892578124, 46.68147880091785],
              [108.9678955078125, 46.60228013300284],
              [108.9788818359375, 46.50406399740093],
              [108.95862579345703, 46.463876433008494],
              [108.95965576171875, 46.464349400461124],
              [108.92669677734374, 46.42365918046987],
              [108.90884399414062, 46.38246489326114],
              [108.83331298828124, 46.3023549043942],
              [108.720703125, 46.214050815339526],
              [108.66989135742188, 46.18363372751015],
              [108.62457275390625, 46.08275685027116],
              [108.599853515625, 45.98837476770814],
              [108.56826782226561, 45.95878764035642],
              [108.55899810791016, 45.94112327491896],
              [108.55899810791016, 45.94112327491896],
              [108.42338562011719, 45.93467676685795],
              [108.31146240234375, 45.95019486846407],
              [108.21121215820312, 45.99982360564013],
              [108.14529418945312, 46.10370875598026],
              [108.11370849609375, 46.193140876238004],
              [108.15765380859375, 46.27388525189855],
              [108.20571899414062, 46.337447097476925],
              [108.19198608398438, 46.39998810407942],
              [108.16726684570312, 46.43691049578133],
              [108.1219482421875, 46.48231911886259],
              [108.12057495117188, 46.544694144765536],
              [108.19610595703125, 46.64660795992221],
              [108.06976318359375, 46.68430518061324],
              [107.95663833618164, 46.77455369740659],
              [107.9568099975586, 46.77467126314099]
            ]
          ]
        }
      },
      {
        type: "Feature",
        properties: {
          TYPE: "States",
          color: "#FA8231",
          aimag_city_ID: 40271,
          name: "Хэнтий",
          schedule_type: "Y",
          sub_coverage: "94",
          ebs_coverage: "96"
        },
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [108.52140426635742, 49.12410690961098],
              [108.533935546875, 49.224772722794825],
              [108.57152938842772, 49.33116330436252],
              [108.57513427734374, 49.330492083934836],
              [108.6492919921875, 49.350177413469],
              [108.819580078125, 49.34302002289675],
              [108.929443359375, 49.350177413469],
              [109.0557861328125, 49.32154160369879],
              [109.1766357421875, 49.35733376286064],
              [109.390869140625, 49.31796095602274],
              [109.53369140625, 49.221185044221336],
              [109.75341796875, 49.22836014090129],
              [110.0994873046875, 49.18170338770663],
              [110.22033691406249, 49.15296965617039],
              [110.3411865234375, 49.2140089056539],
              [110.3851318359375, 49.25346477497736],
              [110.5938720703125, 49.1888842152458],
              [110.7861328125, 49.17452151806784],
              [111.082763671875, 49.27138884351881],
              [111.2860107421875, 49.30721745093609],
              [111.39038085937499, 49.35733376286064],
              [111.53869628906249, 49.32154160369879],
              [111.68701171875, 49.38594874941847],
              [111.78039550781249, 49.38952445158216],
              [111.939697265625, 49.37522008143603],
              [111.99600219726562, 49.392206057422044],
              [111.99600219726562, 49.392206057422044],
              [112.08114624023438, 49.359122687528746],
              [112.15393066406249, 49.32064646618636],
              [112.16629028320312, 49.30184481970431],
              [112.18551635742186, 49.2202880838794],
              [112.19375610351562, 49.14039340493346],
              [112.2198486328125, 49.081062364320736],
              [112.26654052734375, 49.034266715880854],
              [112.291259765625, 48.9991410647952],
              [112.28851318359375, 48.963089005540155],
              [112.23907470703124, 48.89000369970676],
              [112.23907470703124, 48.83941303819501],
              [112.2967529296875, 48.741700879765396],
              [112.48077392578124, 48.5147849720974],
              [112.5164794921875, 48.47838371535879],
              [112.54119873046875, 48.39456162202509],
              [112.5164794921875, 48.32156041109599],
              [112.510986328125, 48.24113823848045],
              [112.54119873046875, 48.0762438718672],
              [112.60986328125, 47.929226038300406],
              [112.69432067871094, 47.79378398661931],
              [112.68402099609374, 47.79286140021344],
              [112.5494384765625, 47.79286140021344],
              [112.39562988281249, 47.76148371616669],
              [112.2802734375, 47.711609647845975],
              [112.1484375, 47.667237034505156],
              [112.0770263671875, 47.60986653003798],
              [112.049560546875, 47.49864785970502],
              [112.04887390136719, 47.381614160856806],
              [112.06535339355469, 47.31648293428332],
              [111.796875, 47.37975438400816],
              [111.67053222656249, 47.32951560950956],
              [111.54693603515625, 47.253135632244216],
              [111.46453857421875, 47.133687832776076],
              [111.434326171875, 47.023333688184934],
              [111.42059326171874, 46.96713374957251],
              [111.3848876953125, 46.90899838277448],
              [111.37939453125, 46.854556538855746],
              [111.39038085937499, 46.79253827035979],
              [111.29974365234375, 46.73233101286786],
              [111.24481201171875, 46.66074749832071],
              [111.22558593749999, 46.57585481240773],
              [111.24755859375, 46.49082901981415],
              [111.26129150390625, 46.43217825300941],
              [111.2200927734375, 46.32417161725694],
              [111.16687774658203, 46.23139052104091],
              [111.1651611328125, 46.23685258143992],
              [111.05529785156249, 46.15700496290803],
              [110.950927734375, 46.06560846138691],
              [110.5609130859375, 46.0998999106273],
              [110.511474609375, 46.229253045075275],
              [110.379638671875, 46.384833223492784],
              [110.2093505859375, 46.51351558059737],
              [110.12695312499999, 46.604167162931844],
              [110.0225830078125, 46.63057868059483],
              [109.97863769531249, 46.60039303734547],
              [110.0115966796875, 46.46056554578543],
              [109.9346923828125, 46.33934333161126],
              [109.8797607421875, 46.26724020382508],
              [109.6160888671875, 46.14939437647686],
              [109.5172119140625, 46.16841886922939],
              [109.324951171875, 46.16841886922939],
              [109.21508789062499, 46.22545288226939],
              [109.149169921875, 46.29001987172955],
              [109.12170410156249, 46.37725420510028],
              [108.95828247070312, 46.464349400461124],
              [108.95862579345703, 46.463876433008494],
              [108.9788818359375, 46.50406399740093],
              [108.9678955078125, 46.60228013300284],
              [108.96514892578124, 46.68147880091785],
              [108.99810791015625, 46.779373682055635],
              [109.00634765625, 46.81509864599243],
              [108.9788818359375, 46.83389173208538],
              [108.91571044921875, 46.856434763486966],
              [108.88275146484375, 46.922131240914],
              [108.82232666015625, 46.98399993718925],
              [108.82095336914062, 46.98118927533914],
              [108.7261962890625, 47.02146130911727],
              [108.643798828125, 47.10378387099161],
              [108.599853515625, 47.22329888685773],
              [108.5394287109375, 47.26432008025478],
              [108.5064697265625, 47.349989032003215],
              [108.3526611328125, 47.56448075401425],
              [108.35163116455078, 47.56355410390809],
              [108.40072631835938, 47.632081940263305],
              [108.42544555664062, 47.676253276991204],
              [108.44192504882812, 47.716922585179084],
              [108.4457015991211, 47.7483271435476],
              [108.43677520751953, 47.805084340797606],
              [108.4357452392578, 47.83597479903542],
              [108.43883514404297, 47.86523461231467],
              [108.45325469970703, 47.90483575868255],
              [108.45222473144531, 47.90483575868255],
              [108.54595184326172, 47.92209422156047],
              [108.6712646484375, 47.96050238891509],
              [108.775634765625, 48.028509034432986],
              [108.8909912109375, 48.08358376568458],
              [108.929443359375, 48.17524408990215],
              [108.96240234375, 48.21735290928554],
              [109.00360107421874, 48.23930899024905],
              [109.04205322265625, 48.32156041109599],
              [109.017333984375, 48.40732607972984],
              [108.95965576171875, 48.525700252688765],
              [108.91845703124999, 48.66557095325139],
              [108.87451171875, 48.74713432542004],
              [108.8580322265625, 48.80686346108517],
              [108.7481689453125, 48.963990624864145],
              [108.52088928222655, 49.124668601968175],
              [108.52140426635742, 49.12410690961098]
            ]
          ]
        }
      },
      {
        type: "Feature",
        properties: {
          TYPE: "States",
          color: "#5F27CD",
          aimag_city_ID: 40164,
          name: "Ховд",
          schedule_type: "Y",
          sub_coverage: "67",
          ebs_coverage: "64"
        },
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [91.04844331741333, 46.719850466635805],
              [91.05352878570557, 46.7231529074099],
              [91.10275268554688, 46.71209003858017],
              [91.16386413574219, 46.68995749641134],
              [91.27304077148438, 46.66263249079177],
              [91.31973266601562, 46.60133659339023],
              [91.34994506835938, 46.55886030311719],
              [91.43096923828124, 46.54280504427768],
              [91.50238037109375, 46.56735822330717],
              [91.56829833984375, 46.613601326659726],
              [91.77841186523438, 46.616431255357995],
              [91.83677673339842, 46.62256226021187],
              [91.87179565429688, 46.63717954716511],
              [91.92672729492188, 46.702673044474004],
              [91.86492919921875, 46.72950714675161],
              [91.76055908203125, 46.76926297371478],
              [91.70665740966797, 46.79629898997745],
              [91.63524627685547, 46.826845094695884],
              [91.5707015991211, 46.86605963365364],
              [91.51542663574219, 46.89234316754865],
              [91.49311065673828, 46.90688744465985],
              [91.45465850830077, 46.94369966371151],
              [91.44332885742188, 46.971350795196734],
              [91.44092559814453, 47.00835281693705],
              [91.44882202148438, 47.06310621972502],
              [91.46358489990234, 47.10565336099383],
              [91.46873474121094, 47.13041790553795],
              [91.46427154541016, 47.159139675920336],
              [91.44847869873047, 47.20697480997098],
              [91.40041351318358, 47.22749569440931],
              [91.35612487792969, 47.258961160390896],
              [91.28986358642578, 47.33510005753562],
              [91.2366485595703, 47.36905939398393],
              [91.2527847290039, 47.45989767565368],
              [91.26205444335938, 47.49493650511712],
              [91.26136779785156, 47.528561072779034],
              [91.25656127929688, 47.545712894408624],
              [91.21742248535156, 47.59273570820418],
              [91.1916732788086, 47.61287541946062],
              [91.17382049560545, 47.645036570200254],
              [91.1703872680664, 47.661225340769356],
              [91.17107391357422, 47.72246594225631],
              [91.1367416381836, 47.731010795533955],
              [91.11648559570312, 47.73955424703249],
              [91.0982894897461, 47.75109722475338],
              [91.08455657958984, 47.76494541923992],
              [91.07460021972656, 47.78155838741233],
              [91.05949401855469, 47.83989242396357],
              [90.98533630371094, 47.97521412341618],
              [90.94757080078124, 48.02414643492423],
              [90.9286880493164, 48.0406764393366],
              [90.88680267333984, 48.068444087227796],
              [90.86585998535156, 48.09527955700514],
              [90.85487365722655, 48.11889235864144],
              [90.83427429199219, 48.22375773926874],
              [90.82054138183594, 48.272225451004324],
              [90.81745147705078, 48.29895447218437],
              [90.82019805908203, 48.34871995385989],
              [90.83427429199219, 48.412795581099964],
              [90.83324432373047, 48.453114986767304],
              [90.81710815429688, 48.525018116559266],
              [90.81024169921875, 48.54525091510785],
              [90.79891204833984, 48.56229474861628],
              [90.77934265136719, 48.58432959223574],
              [90.71891784667969, 48.63880752291323],
              [90.73917388916016, 48.65241782207755],
              [90.81436157226562, 48.6478814638068],
              [90.83427429199219, 48.64470577018957],
              [90.88233947753906, 48.633135480842654],
              [90.90808868408203, 48.62950503924253],
              [90.94310760498047, 48.630412674131264],
              [90.96576690673828, 48.63404305042837],
              [91.04679107666016, 48.65967514645313],
              [91.10172271728516, 48.68257422428195],
              [91.14086151123047, 48.70342373350562],
              [91.16901397705078, 48.710447166883384],
              [91.19510650634766, 48.71384525048727],
              [91.2205123901367, 48.7127125814524],
              [91.26033782958983, 48.702517412636006],
              [91.33827209472655, 48.671862832349746],
              [91.33827209472655, 48.671855747342164],
              [91.45156860351562, 48.738983936650705],
              [91.549072265625, 48.80686346108517],
              [91.59576416015625, 48.8583905296204],
              [91.66305541992188, 48.915279853443806],
              [91.73171997070312, 48.943249256234296],
              [91.82647705078125, 48.96128571799032],
              [91.94183349609375, 48.96939999849952],
              [91.93634033203125, 48.935130721045326],
              [91.93359375, 48.89812957181126],
              [91.94732666015625, 48.827661462789415],
              [91.95968627929688, 48.8032455364465],
              [91.99264526367186, 48.783342285295475],
              [92.03521728515625, 48.77881771792104],
              [92.08053588867188, 48.78243740444988],
              [92.12310791015625, 48.78243740444988],
              [92.15194702148438, 48.77157756174562],
              [92.17117309570312, 48.748945343432936],
              [92.23297119140625, 48.62110864256238],
              [92.36480712890625, 48.62383195130112],
              [92.47329711914062, 48.62020084032983],
              [92.59277343749999, 48.60567378414743],
              [92.64907836914062, 48.59568400838304],
              [92.7685546875, 48.56024979174331],
              [92.8231430053711, 48.557295819191374],
              [92.86571502685545, 48.564339622814806],
              [92.88768768310547, 48.57342695234659],
              [92.91275024414061, 48.590007119230734],
              [92.94158935546875, 48.61588855676401],
              [92.96012878417969, 48.62587433642767],
              [92.97351837158203, 48.63063958030254],
              [92.99892425537108, 48.635404374196476],
              [93.0270767211914, 48.63676566123227],
              [93.05591583251953, 48.63563125791999],
              [93.08269500732422, 48.63109338958398],
              [93.12835693359375, 48.61861214713695],
              [93.15067291259766, 48.60703587322351],
              [93.16852569580078, 48.59409454371403],
              [93.19015502929688, 48.57183678756225],
              [93.22071075439453, 48.51774142617034],
              [93.25675964355469, 48.47610776896956],
              [93.27323913574219, 48.463815894066066],
              [93.3120346069336, 48.447649823664435],
              [93.3343505859375, 48.43990650238739],
              [93.37177276611328, 48.43284538647477],
              [93.42950463294981, 48.42429528259198],
              [93.42950999736786, 48.42429528259198],
              [93.45245361328125, 48.39615735440508],
              [93.47305297851562, 48.377689389945736],
              [93.4830093383789, 48.36537369040198],
              [93.4881591796875, 48.350773448467265],
              [93.4891891479492, 48.32429984442754],
              [93.48335266113281, 48.3005532009148],
              [93.43666076660156, 48.19950665733754],
              [93.3892822265625, 48.10926514749487],
              [93.30413818359375, 48.056053763981225],
              [93.240966796875, 48.00094957553023],
              [93.18225860595703, 47.96601978044179],
              [93.2684326171875, 47.864773955792245],
              [93.39340209960938, 47.77348330190447],
              [93.51150512695312, 47.7097615426664],
              [93.61443758010864, 47.65159940883532],
              [93.61441612243652, 47.65161386351681],
              [93.53948593139648, 47.557472555826074],
              [93.63990783691406, 47.514418190183974],
              [93.70719909667969, 47.47127045275428],
              [93.74908447265625, 47.428087261714275],
              [93.77311706542969, 47.38998234483188],
              [93.8788604736328, 47.33603074146188],
              [93.9935302734375, 47.31648293428332],
              [94.04296874999999, 47.28295557691231],
              [94.06494140625, 47.2270293988673],
              [94.12261962890625, 47.10191431533551],
              [94.20501708984375, 46.99524110694596],
              [94.27093505859375, 46.89210855010365],
              [94.33959960937499, 46.832012719114765],
              [94.405517578125, 46.800059446787316],
              [94.251708984375, 46.741742832123364],
              [94.12261962890625, 46.72291755083757],
              [93.98529052734375, 46.72291755083757],
              [93.91937255859375, 46.70031853924921],
              [93.8287353515625, 46.621147474556096],
              [93.78204345703125, 46.579630646972596],
              [93.76556396484375, 46.549416608392264],
              [93.68865966796875, 46.59850587595948],
              [93.6199951171875, 46.62492015414768],
              [93.52111816406249, 46.632464724627475],
              [93.55133056640625, 46.5607488448596],
              [93.61175537109375, 46.479482189368646],
              [93.6309814453125, 46.41892578708076],
              [93.61724853515625, 46.36777895261494],
              [93.5430908203125, 46.24824991289166],
              [93.46618652343749, 46.17222297845542],
              [93.40576171875, 46.044642082970135],
              [93.37554931640625, 45.891919851282076],
              [93.24371337890625, 45.82114340079471],
              [93.12286376953124, 45.77135470445036],
              [93.0816650390625, 45.72535642341016],
              [93.0816650390625, 45.66780526567164],
              [93.10638427734375, 45.61403741135093],
              [93.13934326171875, 45.54867850352087],
              [93.153076171875, 45.50057194157226],
              [93.15032958984375, 45.24201954967743],
              [93.21195602416992, 45.00498626838617],
              [93.21740627288818, 44.99686891680278],
              [93.21736335754395, 44.9969144378685],
              [93.13385009765625, 45.01336034905033],
              [93.043212890625, 45.017243565102596],
              [92.98278808593749, 45.00170912094224],
              [92.93060302734375, 45.021126517829614],
              [92.8839111328125, 45.0502402697946],
              [92.78228759765625, 45.052180659942316],
              [92.6422119140625, 45.017243565102596],
              [92.54608154296874, 45.023067895446175],
              [92.48565673828125, 44.999767019181306],
              [92.4114990234375, 45.01918507438176],
              [92.3236083984375, 45.009476869672895],
              [92.3016357421875, 45.02889163330817],
              [92.2357177734375, 45.01918507438176],
              [92.10113525390625, 45.07739974122637],
              [92.05718994140625, 45.08515722028692],
              [91.790771484375, 45.08127861241874],
              [91.68365478515625, 45.058001435398296],
              [91.6204833984375, 45.06964120886863],
              [91.57379150390625, 45.08127861241874],
              [91.5545654296875, 45.06770141120143],
              [91.49139404296875, 45.10066901851988],
              [91.4501953125, 45.1510532655634],
              [91.3897705078125, 45.11423838585088],
              [91.32659912109375, 45.125866704733575],
              [91.241455078125, 45.13361760070825],
              [91.18927001953124, 45.154927133618465],
              [91.15631103515625, 45.20139301126898],
              [91.11785888671875, 45.213003555993964],
              [91.04644775390625, 45.20719857986464],
              [90.99426269531249, 45.21493841637806],
              [90.8843994140625, 45.18784438050534],
              [90.86242675781249, 45.19945769007778],
              [90.89263916015625, 45.24588722974015],
              [90.8734130859375, 45.282617057517406],
              [90.8294677734375, 45.29421101337773],
              [90.7965087890625, 45.28648197278284],
              [90.81024169921875, 45.325116643332684],
              [90.76629638671875, 45.40037851725538],
              [90.77178955078125, 45.42929873257377],
              [90.67291259765625, 45.481317798141255],
              [90.66192626953125, 45.54098421805075],
              [90.71136474609375, 45.729191061299936],
              [90.84320068359375, 45.89383147810292],
              [91.021728515625, 46.02366774426006],
              [91.02447509765625, 46.06370275591751],
              [91.0052490234375, 46.0770413127077],
              [91.01074218749999, 46.12274903582433],
              [90.977783203125, 46.162712212065664],
              [90.94482421875, 46.21785176740299],
              [90.9503173828125, 46.2330529447983],
              [90.89263916015625, 46.3127900695348],
              [90.9722900390625, 46.36967413462374],
              [91.0052490234375, 46.45678142812658],
              [91.07391357421875, 46.5607488448596],
              [91.065673828125, 46.5739667965278],
              [91.01074218749999, 46.579630646972596],
              [91.01486206054688, 46.61029955626745],
              [91.04816436767578, 46.71997550705079],
              [91.04844331741333, 46.719850466635805]
            ]
          ]
        }
      },
      {
        type: "Feature",
        properties: {
          TYPE: "States",
          color: "#3867D6",
          aimag_city_ID: 40283,
          name: "Хөвсгөл",
          schedule_type: "Y",
          sub_coverage: "88",
          ebs_coverage: "82"
        },
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [99.0742778778076, 48.296270779257405],
              [99.0966796875, 48.35989909002194],
              [99.06372070312499, 48.494767515307295],
              [99.1021728515625, 48.63290858589532],
              [99.21203613281249, 48.76343113791796],
              [99.16259765625, 48.88639177703194],
              [99.1461181640625, 49.05227025601607],
              [99.1021728515625, 49.1170290407793],
              [98.97033691406249, 49.106241774469055],
              [98.800048828125, 49.1170290407793],
              [98.76708984374999, 49.03786794532644],
              [98.54736328125, 48.935130721045326],
              [98.4649658203125, 48.96939999849952],
              [98.42926025390625, 49.00094298321501],
              [98.36746215820312, 49.01985919086641],
              [98.29330444335938, 49.01625665778159],
              [98.19442749023438, 49.015355983769666],
              [98.13812255859375, 48.99824008113872],
              [98.09005737304688, 48.98652581047831],
              [97.90054321289062, 48.99733908118444],
              [97.85934448242188, 49.009050809382046],
              [97.76870727539062, 49.06576863405361],
              [97.66983032226562, 49.11343354595957],
              [97.52700805664062, 49.157460400911745],
              [97.40478515625, 49.17721484130587],
              [97.27294921875, 49.182601048138054],
              [97.174072265625, 49.167338606291075],
              [97.119140625, 49.224772722794825],
              [97.02850341796875, 49.28482762264301],
              [96.93374633789062, 49.32422691861083],
              [96.87881469726562, 49.36806633482156],
              [96.88156127929688, 49.439556958940855],
              [96.87538146972656, 49.508714260218404],
              [96.93168640136719, 49.542587974015504],
              [96.97425842285155, 49.57821916282],
              [97.03674316406249, 49.61827317596852],
              [97.0751953125, 49.64762521193305],
              [97.10334777832031, 49.68451251348889],
              [97.12944030761719, 49.74888708373188],
              [97.13630676269531, 49.78392421773299],
              [97.12971925735474, 49.79240250154501],
              [97.12970852851868, 49.79238864935048],
              [97.22900390625, 49.745781306155735],
              [97.3663330078125, 49.777716951563754],
              [97.5091552734375, 49.816720843571765],
              [97.57507324218749, 49.937079756975294],
              [97.7728271484375, 49.99714673955339],
              [97.8497314453125, 49.91232450346395],
              [97.88818359375, 49.92647191927194],
              [97.8826904296875, 49.986552130506176],
              [98.009033203125, 50.035973672195496],
              [98.0694580078125, 50.03950183762877],
              [98.118896484375, 50.138185230436896],
              [98.1793212890625, 50.169861746007314],
              [98.184814453125, 50.21557967003726],
              [98.30017089843749, 50.327929664466296],
              [98.2672119140625, 50.41901890273709],
              [98.3441162109375, 50.49595785216966],
              [98.228759765625, 50.55881484085447],
              [98.02001953125, 50.666872321810715],
              [97.943115234375, 50.80593472676908],
              [98.0364990234375, 50.86837814203458],
              [97.88818359375, 50.92381327191295],
              [97.833251953125, 51.01721046364217],
              [97.9541015625, 51.33061163769853],
              [98.06396484375, 51.45742980658965],
              [98.1243896484375, 51.47111882613948],
              [98.228759765625, 51.45400691005982],
              [98.23974609375, 51.600959780448605],
              [98.3660888671875, 51.7406361640977],
              [98.5308837890625, 51.80521825434942],
              [98.6846923828125, 51.83577752045248],
              [98.7615966796875, 51.87309959004367],
              [98.865966796875, 52.13348804077147],
              [98.9813232421875, 52.14360239845529],
              [99.06372070312499, 52.06262321411284],
              [99.26696777343749, 52.01531743663362],
              [99.283447265625, 51.957807388715544],
              [99.4482421875, 51.944264879028765],
              [99.755859375, 51.90361280788355],
              [99.86572265625, 51.77123920059794],
              [100.1513671875, 51.7508394806285],
              [100.65673828125, 51.72702815704774],
              [100.86547851562499, 51.59754765771458],
              [101.2554931640625, 51.5429188223739],
              [101.44226074218749, 51.44716034698012],
              [101.568603515625, 51.498484547170605],
              [101.6455078125, 51.47111882613948],
              [101.92565917968749, 51.406059404992746],
              [102.10693359375, 51.36492148825955],
              [102.183837890625, 51.29971080556154],
              [102.1234130859375, 51.27566243415853],
              [102.1563720703125, 51.10352194240416],
              [102.2332763671875, 50.968805734317804],
              [102.2113037109375, 50.8232885012118],
              [102.3431396484375, 50.719069112580804],
              [102.3101806640625, 50.666872321810715],
              [102.513427734375, 50.59369921413021],
              [102.6123046875, 50.49246334187725],
              [102.64509201049805, 50.41147127685875],
              [102.645263671875, 50.41114309193372],
              [102.50106811523438, 50.35772808048406],
              [102.41455078125, 50.29986701674912],
              [102.39532470703125, 50.2691545281742],
              [102.37884521484375, 50.217337177613686],
              [102.35687255859375, 50.18393346184497],
              [102.24838256835938, 50.11089141378986],
              [102.22091674804686, 50.08710669808234],
              [102.21817016601562, 50.07388786455134],
              [102.12753295898436, 50.004208515595614],
              [102.10418701171874, 49.96977255919705],
              [102.10281372070312, 49.92912409759547],
              [102.13302612304688, 49.90348026046574],
              [102.20718383789062, 49.8787077521734],
              [102.48596191406249, 49.80254124506291],
              [102.5628662109375, 49.786584231227586],
              [102.70843505859375, 49.742231602580716],
              [102.78482437133789, 49.69173115108819],
              [102.67822265625, 49.64362370314076],
              [102.64389038085938, 49.635619698841445],
              [102.57247924804686, 49.64273443429918],
              [102.53402709960938, 49.64184514921545],
              [102.5079345703125, 49.62672481765915],
              [102.48870849609375, 49.596470070892686],
              [102.40905761718749, 49.50024216453777],
              [102.29919433593749, 49.439556958940855],
              [102.1563720703125, 49.40739905889802],
              [102.00256347656249, 49.38594874941847],
              [101.8377685546875, 49.30363576187125],
              [101.788330078125, 49.24270720875383],
              [101.7169189453125, 49.2355341957325],
              [101.634521484375, 49.127813961593255],
              [101.63246154785156, 49.06351915895547],
              [101.6658067703247, 48.962750894091165],
              [101.66610717773438, 48.963089005540155],
              [101.60568237304686, 48.95948236523749],
              [101.53770446777344, 48.950915549215985],
              [101.44912719726561, 48.95407191575548],
              [101.36054992675781, 48.96939999849952],
              [101.28021240234375, 49.015355983769666],
              [101.14837646484375, 49.050920216584856],
              [100.94856262207031, 49.116130191506805],
              [100.88607788085938, 49.13095912169914],
              [100.70205688476562, 49.20503726723141],
              [100.64437866210938, 49.10444366870733],
              [100.535888671875, 49.034266715880854],
              [100.47683715820312, 48.9991410647952],
              [100.60317993164062, 48.95948236523749],
              [100.64163208007812, 48.923401616679705],
              [100.579833984375, 48.89271247049609],
              [100.48095703125, 48.866521538507754],
              [100.4315185546875, 48.823140892101684],
              [100.38619995117188, 48.75075629617738],
              [100.35736083984375, 48.675546901341384],
              [100.36148071289062, 48.629278127969414],
              [100.39581298828125, 48.58205840283824],
              [100.47546386718749, 48.50932644976633],
              [100.37796020507812, 48.4965876108066],
              [100.28182983398438, 48.4902169906863],
              [100.22552490234374, 48.4765629664158],
              [100.2117919921875, 48.45561965661709],
              [100.21041870117186, 48.412795581099964],
              [100.17196655273438, 48.390002110874164],
              [100.107421875, 48.38909015961602],
              [100.0469970703125, 48.40550278187468],
              [99.96185302734375, 48.44377831058805],
              [99.9041748046875, 48.463815894066066],
              [99.78607177734375, 48.486576276944774],
              [99.7833251953125, 48.420998730129426],
              [99.744873046875, 48.339820521006125],
              [99.70230102539062, 48.295985271707664],
              [99.61166381835938, 48.274967532852976],
              [99.35760498046875, 48.2420528380672],
              [99.26422119140625, 48.23565029755306],
              [99.22027587890624, 48.24571107288924],
              [99.1680908203125, 48.26674084583965],
              [99.07432079315186, 48.29617085179661],
              [99.0742778778076, 48.296270779257405]
            ]
          ]
        }
      },
      {
        type: "Feature",
        properties: {
          TYPE: "States",
          color: "#3867D6",
          aimag_city_ID: 40265,
          name: "Орхон",
          schedule_type: "Y",
          sub_coverage: "90",
          ebs_coverage: "68"
        },
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [104.37801361083984, 48.899258060699516],
              [104.34677124023438, 48.90805939965008],
              [104.30694580078125, 48.927913136732556],
              [104.24514770507812, 48.94595514118904],
              [104.1558837890625, 48.957678947281124],
              [103.99932861328124, 48.98562459864604],
              [103.99932861328124, 49.03876821195109],
              [104.05838012695312, 49.095452162534826],
              [104.10232543945312, 49.12062427498834],
              [104.2822265625, 49.080162863460984],
              [104.33303833007812, 49.11882669045988],
              [104.39071655273438, 49.1314084139986],
              [104.44427490234375, 49.127813961593255],
              [104.51946258544922, 49.106017014812096],
              [104.5184326171875, 49.105342729732754],
              [104.53765869140625, 49.06936758241039],
              [104.57061767578125, 49.026162996368946],
              [104.56100463867188, 48.99463598353408],
              [104.47723388671875, 48.942347261978476],
              [104.37698364257812, 48.89993514180326],
              [104.37801361083984, 48.899258060699516]
            ]
          ]
        }
      },
      {
        type: "Feature",
        properties: {
          TYPE: "States",
          color: "#FF6348",
          aimag_city_ID: 40268,
          name: "Сэлэнгэ",
          schedule_type: "Y",
          sub_coverage: "62",
          ebs_coverage: "80"
        },
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [104.51911926269531, 49.106017014812096],
              [104.53628540039062, 49.15296965617039],
              [104.54864501953125, 49.22836014090129],
              [104.5953369140625, 49.3653834114396],
              [104.61181640625, 49.3993562906839],
              [104.64065551757812, 49.435984899682005],
              [104.66537475585938, 49.48775429690567],
              [104.67086791992188, 49.54481568504451],
              [104.67361450195312, 49.64451295574021],
              [104.732666015625, 49.686289508355735],
              [104.688720703125, 49.73779410787209],
              [104.58297729492188, 49.81229016526221],
              [104.53216552734375, 49.85303710579436],
              [104.51568603515625, 49.90082667133223],
              [104.51156616210938, 49.983020075472226],
              [104.58023071289062, 49.98743510374161],
              [104.60220336914062, 49.993615462541136],
              [104.61456298828124, 50.0086215988794],
              [104.55551147460938, 50.05008477838258],
              [104.54864501953125, 50.10032188683107],
              [104.52117919921874, 50.14962639292665],
              [104.45114135742186, 50.21557967003726],
              [104.36067581176758, 50.26454594544213],
              [104.359130859375, 50.2612538275847],
              [104.3865966796875, 50.31390041107301],
              [104.51293945312499, 50.32091555536218],
              [104.556884765625, 50.31039245071915],
              [104.6502685546875, 50.33494273843299],
              [104.67773437499999, 50.366488762738264],
              [104.77111816406249, 50.3454604086048],
              [104.8590087890625, 50.3734961443035],
              [104.8974609375, 50.40851753069729],
              [105.018310546875, 50.39451208023374],
              [105.150146484375, 50.401515322782366],
              [105.27099609375, 50.440014664018136],
              [105.2764892578125, 50.47498691278631],
              [105.3314208984375, 50.48197825997291],
              [105.413818359375, 50.450509053586615],
              [105.523681640625, 50.46100111599232],
              [105.6170654296875, 50.440014664018136],
              [105.72143554687499, 50.433017111356605],
              [105.7928466796875, 50.447011182312195],
              [105.919189453125, 50.42251884281916],
              [106.0675048828125, 50.40501655606602],
              [106.094970703125, 50.34896578114507],
              [106.171875, 50.341954777309994],
              [106.2872314453125, 50.31039245071915],
              [106.4300537109375, 50.327929664466296],
              [106.4794921875, 50.317408112618686],
              [106.4959716796875, 50.3454604086048],
              [106.80908203125, 50.306884231551166],
              [107.017822265625, 50.20503326494332],
              [107.083740234375, 50.064191736659104],
              [107.28149414062499, 49.990083926193954],
              [107.3309326171875, 50.004208515595614],
              [107.46826171874999, 49.983020075472226],
              [107.7264404296875, 49.9759551873433],
              [107.80334472656249, 49.94061518401988],
              [107.9681396484375, 49.94061518401988],
              [107.9351806640625, 49.86277585341321],
              [107.9791259765625, 49.809631563563094],
              [107.9461669921875, 49.72092792670335],
              [108.017578125, 49.66762782262192],
              [108.292236328125, 49.53946900793534],
              [108.3636474609375, 49.42169406084924],
              [108.52844238281249, 49.32512199104001],
              [108.57118606567381, 49.33116330436252],
              [108.57152938842772, 49.33116330436252],
              [108.533935546875, 49.224772722794825],
              [108.52140426635742, 49.12410690961098],
              [108.52226257324219, 49.124668601968175],
              [108.44467163085938, 49.12511795127304],
              [108.3306884765625, 49.1170290407793],
              [108.23867797851562, 49.08915713896701],
              [108.16314697265625, 49.059469847170526],
              [108.08761596679688, 49.00995159747052],
              [107.99011230468749, 49.0549702248979],
              [107.91046142578125, 49.074765516185],
              [107.85552978515625, 49.0765646971094],
              [107.7923583984375, 49.0765646971094],
              [107.67288208007812, 49.05677012268616],
              [107.59185791015625, 49.017157315497165],
              [107.53143310546875, 48.97751295870069],
              [107.59597778320312, 48.89812957181126],
              [107.63168334960936, 48.879167148960214],
              [107.70584106445312, 48.857487002645485],
              [107.73056030273438, 48.83579746243093],
              [107.73468017578125, 48.81048112472012],
              [107.71270751953125, 48.75890477584505],
              [107.67013549804688, 48.68552087440201],
              [107.65090942382812, 48.64561313162894],
              [107.62619018554688, 48.63018576693964],
              [107.5836181640625, 48.62564740882851],
              [107.52319335937499, 48.63109338958398],
              [107.46826171874999, 48.60748989475176],
              [107.25128173828125, 48.558431982894355],
              [107.20184326171875, 48.59931688270807],
              [107.14279174804688, 48.615661584266824],
              [107.09335327148438, 48.61747733567233],
              [106.98348999023438, 48.60204136700653],
              [106.8695068359375, 48.60204136700653],
              [106.82418823242188, 48.57933284084407],
              [106.7596435546875, 48.532975799741635],
              [106.68823242187499, 48.516604348867475],
              [106.578369140625, 48.52933815687993],
              [106.47674560546875, 48.56024979174331],
              [106.46301269531249, 48.58296689084418],
              [106.44653320312499, 48.592050872804194],
              [106.171875, 48.68733411186308],
              [106.11145019531249, 48.66012869453836],
              [106.0675048828125, 48.64833511799889],
              [106.01394653320312, 48.669198799260045],
              [105.98785400390625, 48.68824070611104],
              [106.00982666015624, 48.731738037020946],
              [106.02630615234374, 48.76886223397753],
              [106.01943969726562, 48.80957673328024],
              [105.9686279296875, 48.84393214092012],
              [105.82992553710938, 48.865618158309374],
              [105.65139770507811, 48.83579746243093],
              [105.57037353515625, 48.816811408054306],
              [105.44540405273438, 48.75799945448167],
              [105.49346923828125, 48.64561313162894],
              [105.31768798828125, 48.59114254808809],
              [105.2490234375, 48.56024979174331],
              [105.15838623046875, 48.545705491847464],
              [105.06500244140625, 48.511146022547344],
              [104.99359130859375, 48.507506811647325],
              [104.8809814453125, 48.53843177405044],
              [104.52804565429688, 48.62564740882851],
              [104.52821731567383, 48.625760872755635],
              [104.51980590820312, 48.66103577846602],
              [104.51568603515625, 48.73264383158835],
              [104.54452514648436, 48.786961645533275],
              [104.47723388671875, 48.83941303819501],
              [104.37801361083984, 48.89948375541988],
              [104.37698364257812, 48.89993514180326],
              [104.47723388671875, 48.942347261978476],
              [104.56100463867188, 48.99463598353408],
              [104.57061767578125, 49.026162996368946],
              [104.53765869140625, 49.06936758241039],
              [104.5184326171875, 49.105342729732754],
              [104.51911926269531, 49.106017014812096]
            ],
            [
              [106.138916015625, 49.81849300131474],
              [106.138916015625, 49.73335620733158],
              [106.07299804687499, 49.67829251994456],
              [106.04278564453125, 49.61782831211117],
              [106.14990234375, 49.58578744112238],
              [106.24603271484375, 49.5519436777949],
              [106.380615234375, 49.52164252537975],
              [106.46026611328125, 49.56085220619188],
              [106.49871826171875, 49.60893018211529],
              [106.67449951171875, 49.56085220619188],
              [106.7596435546875, 49.52877389852215],
              [106.80084228515625, 49.47169378524674],
              [106.80084228515625, 49.37164333826142],
              [106.7816162109375, 49.26601230654397],
              [106.7926025390625, 49.2032427441791],
              [106.79809570312499, 49.13320554247907],
              [106.600341796875, 49.086459027368335],
              [106.45477294921874, 49.12961122037019],
              [106.38336181640625, 49.15656228453343],
              [106.35314941406249, 49.18349869228674],
              [106.20758056640625, 49.163746759588975],
              [106.14715576171875, 49.14219020768315],
              [106.094970703125, 49.10444366870733],
              [106.051025390625, 49.095452162534826],
              [105.80108642578125, 49.1314084139986],
              [105.82305908203124, 49.15296965617039],
              [105.89447021484375, 49.44848596885694],
              [105.89309692382811, 49.506484903584074],
              [105.8642578125, 49.54125129864412],
              [105.8587646484375, 49.577773933420914],
              [105.83953857421874, 49.60715036117516],
              [105.8367919921875, 49.683623991698084],
              [105.92468261718749, 49.742231602580716],
              [106.0235595703125, 49.786584231227586],
              [106.138916015625, 49.81849300131474]
            ]
          ]
        }
      },
  
      {
        type: "Feature",
        properties: {
          TYPE: "States",
          color: "#FF6348",
          aimag_city_ID: 40260,
          name: "Дархан-Уул",
          schedule_type: "Y",
          sub_coverage: "63",
          ebs_coverage: "82"
        },
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [106.138916015625, 49.81849300131474],
              [106.138916015625, 49.73335620733158],
              [106.07299804687499, 49.67829251994456],
              [106.04278564453125, 49.61782831211117],
              [106.14990234375, 49.58578744112238],
              [106.24603271484375, 49.5519436777949],
              [106.380615234375, 49.52164252537975],
              [106.46026611328125, 49.56085220619188],
              [106.49871826171875, 49.60893018211529],
              [106.67449951171875, 49.56085220619188],
              [106.7596435546875, 49.52877389852215],
              [106.80084228515625, 49.47169378524674],
              [106.80084228515625, 49.37164333826142],
              [106.7816162109375, 49.26601230654397],
              [106.7926025390625, 49.2032427441791],
              [106.79809570312499, 49.13320554247907],
              [106.600341796875, 49.086459027368335],
              [106.45477294921874, 49.12961122037019],
              [106.38336181640625, 49.15656228453343],
              [106.35314941406249, 49.18349869228674],
              [106.20758056640625, 49.163746759588975],
              [106.14715576171875, 49.14219020768315],
              [106.094970703125, 49.10444366870733],
              [106.051025390625, 49.095452162534826],
              [105.80108642578125, 49.1314084139986],
              [105.82305908203124, 49.15296965617039],
              [105.89447021484375, 49.44848596885694],
              [105.89309692382811, 49.506484903584074],
              [105.8642578125, 49.54125129864412],
              [105.8587646484375, 49.577773933420914],
              [105.83953857421874, 49.60715036117516],
              [105.8367919921875, 49.683623991698084],
              [105.92468261718749, 49.742231602580716],
              [106.0235595703125, 49.786584231227586],
              [106.138916015625, 49.81849300131474]
            ]
          ]
        }
      },
      {
        type: "Feature",
        properties: {
          TYPE: "States",
          color: "#FA8231",
          aimag_city_ID: 40267,
          name: "Сүхбаатар",
          schedule_type: "Y",
          sub_coverage: "79",
          ebs_coverage: "81"
        },
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [111.16687774658203, 46.23139052104091],
              [111.2200927734375, 46.32417161725694],
              [111.26129150390625, 46.43217825300941],
              [111.24755859375, 46.49082901981415],
              [111.22558593749999, 46.57585481240773],
              [111.24481201171875, 46.66074749832071],
              [111.29974365234375, 46.73233101286786],
              [111.39038085937499, 46.79253827035979],
              [111.37939453125, 46.854556538855746],
              [111.3848876953125, 46.90899838277448],
              [111.42059326171874, 46.96713374957251],
              [111.434326171875, 47.023333688184934],
              [111.46453857421875, 47.133687832776076],
              [111.54693603515625, 47.253135632244216],
              [111.67053222656249, 47.32951560950956],
              [111.796875, 47.37975438400816],
              [112.06535339355469, 47.31648293428332],
              [112.04887390136719, 47.381614160856806],
              [112.049560546875, 47.49864785970502],
              [112.0770263671875, 47.60986653003798],
              [112.1484375, 47.667237034505156],
              [112.2802734375, 47.711609647845975],
              [112.39562988281249, 47.76148371616669],
              [112.5494384765625, 47.79286140021344],
              [112.68402099609374, 47.79286140021344],
              [112.69363403320312, 47.79378398661931],
              [112.70050048828125, 47.724544549099676],
              [112.74169921875, 47.57652571374621],
              [112.87353515625, 47.60616304386874],
              [112.9669189453125, 47.613569753973955],
              [113.10150146484375, 47.58764167941513],
              [113.17565917968749, 47.55984733956309],
              [113.61785888671875, 47.51349065484327],
              [113.675537109375, 47.5153457091302],
              [113.8128662109375, 47.468949677672484],
              [113.9666748046875, 47.364873807434094],
              [114.071044921875, 47.32393057095941],
              [114.18090820312499, 47.253135632244216],
              [114.4720458984375, 47.12621341795227],
              [114.42398071289062, 47.00086080525212],
              [114.4171142578125, 46.89680070399431],
              [114.43634033203125, 46.825435656353896],
              [114.52285766601562, 46.76714653871027],
              [114.6807861328125, 46.70596917928676],
              [114.89501953124999, 46.49839225859763],
              [115.224609375, 46.54563867035857],
              [115.5596923828125, 46.543749602738565],
              [115.6805419921875, 46.581518465658014],
              [116.19415283203125, 46.592843997427394],
              [116.2847900390625, 46.5739667965278],
              [116.34246826171874, 46.511625395437925],
              [116.54159545898438, 46.23685258143992],
              [116.54228210449219, 46.23732751752229],
              [116.53404235839844, 46.22402775339081],
              [116.44203186035156, 46.14130697521705],
              [116.40907287597656, 46.12608040848373],
              [116.27655029296875, 45.97024259702345],
              [116.25457763671875, 45.93969078234],
              [116.25457763671875, 45.90529985724796],
              [116.23260498046874, 45.87853662114514],
              [116.25457763671875, 45.865150166790585],
              [116.27929687499999, 45.8421944579529],
              [116.27105712890625, 45.817315080406246],
              [116.27929687499999, 45.77135470445036],
              [116.21887207031249, 45.74548533557156],
              [116.21612548828124, 45.723918366334765],
              [116.18385314941406, 45.69323099673824],
              [116.14471435546875, 45.68027970958459],
              [116.13304138183594, 45.68027970958459],
              [116.11038208007811, 45.67308325436826],
              [116.06712341308594, 45.68459713847793],
              [116.03004455566405, 45.67932023569538],
              [116.02523803710938, 45.65484809235974],
              [115.9332275390625, 45.63516665067313],
              [115.88928222656249, 45.58521197809401],
              [115.69152832031251, 45.45435093922207],
              [115.37567138671874, 45.390735154248894],
              [114.95544433593749, 45.37530235052552],
              [114.7412109375, 45.43508099838452],
              [114.5489501953125, 45.38301927899065],
              [114.52423095703124, 45.29034662473615],
              [114.38690185546875, 45.14524196975275],
              [114.19464111328125, 45.04441870436415],
              [114.14245605468749, 44.984227835166486],
              [114.05181884765625, 44.92202896709906],
              [113.90075683593749, 44.914249368747086],
              [113.85955810546875, 44.86949623772188],
              [113.79638671875, 44.85197466334987],
              [113.62884521484375, 44.74673324024678],
              [113.5052490234375, 44.77208668197081],
              [113.13720703125, 44.793530904744074],
              [112.93395996093749, 44.83834308566651],
              [112.84881591796875, 44.84029065139799],
              [112.58514404296875, 44.929807512153914],
              [112.51922607421875, 45.01141864227728],
              [112.41485595703125, 45.07739974122637],
              [112.3736572265625, 45.061881623213026],
              [112.10723876953124, 45.07546020688359],
              [112.06054687499999, 45.09873027414909],
              [111.97265625, 45.086126831109596],
              [111.95343017578125, 45.07303569638012],
              [111.95343017578125, 45.071944633095136],
              [111.939697265625, 45.08758121648526],
              [111.91223144531249, 45.17235628126675],
              [111.91223144531249, 45.21687321093267],
              [111.9232177734375, 45.27681919090837],
              [111.9012451171875, 45.321254361171476],
              [111.80511474609375, 45.41002023463975],
              [111.7529296875, 45.49287107405929],
              [111.68426513671875, 45.533288879467456],
              [111.7144775390625, 45.72727377526009],
              [111.70074462890625, 45.922497984579934],
              [111.61834716796875, 46.06179698466216],
              [111.57989501953125, 46.09418614922648],
              [111.5057373046875, 46.115133713265415],
              [111.36566162109375, 46.17412493440401],
              [111.16653442382812, 46.230203044669985],
              [111.16687774658203, 46.23139052104091]
            ]
          ]
        }
      },
      {
        type: "Feature",
        properties: {
          TYPE: "States",
          color: "#FF6348",
          aimag_city_ID: 40269,
          name: "Төв", 
          schedule_type: "Y",
          sub_coverage: "95",
          ebs_coverage: "90"
        },
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [104.14764404296874, 47.29809208596965],
              [104.16961669921875, 47.32951560950956],
              [104.17098999023436, 47.35650163792198],
              [104.13803100585938, 47.386263315961884],
              [104.095458984375, 47.41229100755385],
              [104.07073974609375, 47.470806305936264],
              [104.15313720703125, 47.525547335516556],
              [104.28909301757812, 47.58486290927609],
              [104.38385009765625, 47.60060732292067],
              [104.4854736328125, 47.59875528481801],
              [104.51019287109375, 47.628380027447136],
              [104.52392578125, 47.65336285044987],
              [104.64614868164061, 47.65521295468833],
              [104.7601318359375, 47.66168780332917],
              [104.72991943359375, 47.7263921299974],
              [104.69558715820312, 47.82883013320963],
              [104.69146728515625, 47.897930761804936],
              [104.710693359375, 47.90989883771289],
              [104.70932006835938, 47.933826688535746],
              [104.68597412109375, 47.954064687296885],
              [104.66537475585938, 47.94762618352869],
              [104.55001831054688, 47.94946583788702],
              [104.44976806640625, 47.96785877999253],
              [104.39346313476562, 47.98624517426206],
              [104.37835693359375, 48.058807426879575],
              [104.33990478515625, 48.10559716402152],
              [104.28634643554688, 48.143181585289554],
              [104.33303833007812, 48.194471974688206],
              [104.36325073242188, 48.25668420754852],
              [104.39895629882812, 48.29781249243716],
              [104.43603515624999, 48.32429984442754],
              [104.50332641601562, 48.355336473558054],
              [104.534912109375, 48.42191010942875],
              [104.53834533691406, 48.53479452317522],
              [104.52838897705078, 48.62564740882851],
              [104.52804565429688, 48.62564740882851],
              [104.8809814453125, 48.53843177405044],
              [104.99359130859375, 48.507506811647325],
              [105.06500244140625, 48.511146022547344],
              [105.15838623046875, 48.545705491847464],
              [105.2490234375, 48.56024979174331],
              [105.31768798828125, 48.59114254808809],
              [105.49346923828125, 48.64561313162894],
              [105.44540405273438, 48.75799945448167],
              [105.57037353515625, 48.816811408054306],
              [105.65139770507811, 48.83579746243093],
              [105.82992553710938, 48.865618158309374],
              [105.9686279296875, 48.84393214092012],
              [106.01943969726562, 48.80957673328024],
              [106.02630615234374, 48.76886223397753],
              [106.00982666015624, 48.731738037020946],
              [105.98785400390625, 48.68824070611104],
              [106.01394653320312, 48.669198799260045],
              [106.0675048828125, 48.64833511799889],
              [106.11145019531249, 48.66012869453836],
              [106.171875, 48.68733411186308],
              [106.44653320312499, 48.592050872804194],
              [106.46301269531249, 48.58296689084418],
              [106.47674560546875, 48.56024979174331],
              [106.578369140625, 48.52933815687993],
              [106.68823242187499, 48.516604348867475],
              [106.7596435546875, 48.532975799741635],
              [106.82418823242188, 48.57933284084407],
              [106.8695068359375, 48.60204136700653],
              [106.98348999023438, 48.60204136700653],
              [107.09335327148438, 48.61747733567233],
              [107.14279174804688, 48.615661584266824],
              [107.20184326171875, 48.59931688270807],
              [107.25128173828125, 48.558431982894355],
              [107.46826171874999, 48.60748989475176],
              [107.52319335937499, 48.63109338958398],
              [107.5836181640625, 48.62564740882851],
              [107.62619018554688, 48.63018576693964],
              [107.65090942382812, 48.64561313162894],
              [107.67013549804688, 48.68552087440201],
              [107.71270751953125, 48.75890477584505],
              [107.73468017578125, 48.81048112472012],
              [107.73056030273438, 48.83579746243093],
              [107.70584106445312, 48.857487002645485],
              [107.63168334960936, 48.879167148960214],
              [107.59597778320312, 48.89812957181126],
              [107.53143310546875, 48.97751295870069],
              [107.59185791015625, 49.017157315497165],
              [107.67288208007812, 49.05677012268616],
              [107.7923583984375, 49.0765646971094],
              [107.85552978515625, 49.0765646971094],
              [107.91046142578125, 49.074765516185],
              [107.99011230468749, 49.0549702248979],
              [108.08761596679688, 49.00995159747052],
              [108.16314697265625, 49.059469847170526],
              [108.23867797851562, 49.08915713896701],
              [108.3306884765625, 49.1170290407793],
              [108.44467163085938, 49.12511795127304],
              [108.52226257324219, 49.124668601968175],
              [108.52088928222655, 49.124668601968175],
              [108.7481689453125, 48.963990624864145],
              [108.8580322265625, 48.80686346108517],
              [108.87451171875, 48.74713432542004],
              [108.91845703124999, 48.66557095325139],
              [108.95965576171875, 48.525700252688765],
              [109.017333984375, 48.40732607972984],
              [109.04205322265625, 48.32156041109599],
              [109.00360107421874, 48.23930899024905],
              [108.96240234375, 48.21735290928554],
              [108.929443359375, 48.17524408990215],
              [108.8909912109375, 48.08358376568458],
              [108.775634765625, 48.028509034432986],
              [108.6712646484375, 47.96050238891509],
              [108.54595184326172, 47.92209422156047],
              [108.45222473144531, 47.90483575868255],
              [108.45291137695312, 47.90529605906089],
              [108.35334777832031, 47.91818280779197],
              [108.27713012695312, 47.868919717114395],
              [108.24829101562499, 47.84265762816535],
              [108.23524475097656, 47.82375912844477],
              [108.21258544921875, 47.782711868678575],
              [108.20159912109374, 47.75040471827431],
              [108.19953918457031, 47.714381682734256],
              [108.21945190429688, 47.6603004033596],
              [108.22700500488281, 47.62004976487241],
              [108.25996398925781, 47.58856790334661],
              [108.30184936523438, 47.57281986733871],
              [108.3526611328125, 47.56355410390809],
              [108.3526611328125, 47.56448075401425],
              [108.5064697265625, 47.349989032003215],
              [108.5394287109375, 47.26432008025478],
              [108.599853515625, 47.22329888685773],
              [108.643798828125, 47.10378387099161],
              [108.7261962890625, 47.02146130911727],
              [108.82095336914062, 46.98118927533914],
              [108.82095336914062, 46.98025235521883],
              [108.7591552734375, 46.894923891703606],
              [108.70697021484375, 46.86113003765812],
              [108.47900390625, 46.78031411656016],
              [108.20159912109374, 46.81509864599243],
              [108.04779052734375, 46.803819640791566],
              [108.00315856933594, 46.793478474902564],
              [107.9568099975586, 46.77467126314099],
              [107.9571533203125, 46.77514152351191],
              [107.85621643066406, 46.761972680830375],
              [107.85072326660155, 46.72291755083757],
              [107.84042358398438, 46.68336307047754],
              [107.83561706542969, 46.62350543010974],
              [107.81845092773438, 46.58576581737151],
              [107.78274536132812, 46.56641407568593],
              [107.74841308593749, 46.55980458220511],
              [107.67494201660156, 46.553666474384535],
              [107.63031005859375, 46.542332758884484],
              [107.60215759277344, 46.527689873863785],
              [107.53692626953125, 46.464822363804686],
              [107.369384765625, 46.41892578708076],
              [107.1002197265625, 46.403776166694634],
              [107.03704833984375, 46.43217825300941],
              [106.89834594726562, 46.526745036021474],
              [106.79946899414062, 46.55980458220511],
              [106.68548583984375, 46.574910812684244],
              [106.16363525390625, 46.57585481240773],
              [106.1224365234375, 46.617374532060765],
              [106.05926513671875, 46.63435070293566],
              [105.91094970703125, 46.6795944656402],
              [105.84228515625, 46.72480037466717],
              [105.7598876953125, 46.73609593770121],
              [105.65826416015625, 46.71350244599995],
              [105.4742431640625, 46.543749602738565],
              [105.39459228515624, 46.437856895024225],
              [105.22979736328125, 46.526745036021474],
              [104.96612548828124, 46.59095657312452],
              [104.85626220703124, 46.49650154751426],
              [104.776611328125, 46.46056554578543],
              [104.65353012084961, 46.380451731783644],
              [104.6531867980957, 46.380570155101864],
              [104.60769653320311, 46.417979059090115],
              [104.56512451171875, 46.443534945303284],
              [104.43878173828125, 46.50595444552051],
              [104.403076171875, 46.52863469527167],
              [104.39208984375, 46.569246469250054],
              [104.39208984375, 46.65697731621612],
              [104.3756103515625, 46.70408569832255],
              [104.34814453125, 46.73233101286786],
              [104.32342529296875, 46.76996843356982],
              [104.23416137695312, 46.853617401905105],
              [104.2657470703125, 46.87990702860919],
              [104.30145263671875, 46.89680070399431],
              [104.34539794921875, 46.903369029728054],
              [104.35638427734375, 46.95869866086379],
              [104.3536376953125, 46.997114405385986],
              [104.33578491210938, 47.034566583590426],
              [104.31106567382811, 47.05608969226366],
              [104.249267578125, 47.09537035351024],
              [104.20120239257811, 47.124344650121884],
              [104.17098999023436, 47.1607737815166],
              [104.15313720703125, 47.21583707523794],
              [104.1448974609375, 47.25919416818626],
              [104.14833068847656, 47.29879058949732],
              [104.14764404296874, 47.29809208596965]
            ],
            [
              [106.35589599609375, 48.25851283439989],
              [106.58523559570312, 48.228332127214934],
              [106.78573608398438, 48.19813361113582],
              [106.875, 48.17341248658084],
              [106.92855834960938, 48.17707562779614],
              [107.09884643554688, 48.22009793454488],
              [107.14691162109375, 48.152344345643336],
              [107.19772338867188, 48.115683488677305],
              [107.26089477539062, 48.045955739960114],
              [107.33367919921875, 47.98532601005293],
              [107.38449096679688, 47.964180715412276],
              [107.44766235351562, 47.95774347215711],
              [107.53829956054686, 47.934746769467786],
              [107.57400512695312, 47.91081934432408],
              [107.56576538085938, 47.854638476529935],
              [107.54379272460938, 47.77809779004784],
              [107.51220703125, 47.669086647137576],
              [107.50808715820312, 47.65058757118734],
              [107.4078369140625, 47.63763431783611],
              [107.31582641601562, 47.63670896254457],
              [107.2320556640625, 47.65151268066222],
              [107.12356567382812, 47.711609647845975],
              [107.03704833984375, 47.732858147066494],
              [106.93817138671874, 47.73747623919626],
              [106.66488647460936, 47.711609647845975],
              [106.60858154296875, 47.7263921299974],
              [106.54129028320312, 47.73747623919626],
              [106.50283813476562, 47.76979141653638],
              [106.4959716796875, 47.81499895328108],
              [106.49871826171875, 47.86293128876346],
              [106.468505859375, 47.931066347509784],
              [106.4300537109375, 47.96601978044179],
              [106.42181396484375, 48.098260411732674],
              [106.3922882080078, 48.16516946195868],
              [106.37580871582031, 48.211862417203214],
              [106.35589599609375, 48.25851283439989]
            ],
            [
              [107.55958557128906, 47.352780247239586],
              [107.54310607910156, 47.32672316405059],
              [107.48405456542967, 47.3136897998641],
              [107.43873596191406, 47.311827628230446],
              [107.4078369140625, 47.320206883852414],
              [107.39067077636717, 47.33789206010502],
              [107.38174438476562, 47.353245435426906],
              [107.3858642578125, 47.37789454155521],
              [107.40852355957031, 47.39370111129245],
              [107.4462890625, 47.404855836246135],
              [107.49504089355469, 47.40810885284093],
              [107.52593994140625, 47.39788440990287],
              [107.545166015625, 47.37975438400816],
              [107.55958557128906, 47.352780247239586]
            ]
          ]
        }
      },
      {
        type: "Feature",
        properties: {
          TYPE: "States",
          color: "#FF6348",
          aimag_city_ID: 39983,
          name: "Улаанбаатар",
          schedule_type: "Y",
          sub_coverage: "92",
          ebs_coverage: "89"
        },
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [106.35589599609375, 48.25851283439989],
              [106.58523559570312, 48.228332127214934],
              [106.78573608398438, 48.19813361113582],
              [106.875, 48.17341248658084],
              [106.92855834960938, 48.17707562779614],
              [107.09884643554688, 48.22009793454488],
              [107.14691162109375, 48.152344345643336],
              [107.19772338867188, 48.115683488677305],
              [107.26089477539062, 48.045955739960114],
              [107.33367919921875, 47.98532601005293],
              [107.38449096679688, 47.964180715412276],
              [107.44766235351562, 47.95774347215711],
              [107.53829956054686, 47.934746769467786],
              [107.57400512695312, 47.91081934432408],
              [107.56576538085938, 47.854638476529935],
              [107.54379272460938, 47.77809779004784],
              [107.51220703125, 47.669086647137576],
              [107.50808715820312, 47.65058757118734],
              [107.4078369140625, 47.63763431783611],
              [107.31582641601562, 47.63670896254457],
              [107.2320556640625, 47.65151268066222],
              [107.12356567382812, 47.711609647845975],
              [107.03704833984375, 47.732858147066494],
              [106.93817138671874, 47.73747623919626],
              [106.66488647460936, 47.711609647845975],
              [106.60858154296875, 47.7263921299974],
              [106.54129028320312, 47.73747623919626],
              [106.50283813476562, 47.76979141653638],
              [106.4959716796875, 47.81499895328108],
              [106.49871826171875, 47.86293128876346],
              [106.468505859375, 47.931066347509784],
              [106.4300537109375, 47.96601978044179],
              [106.42181396484375, 48.098260411732674],
              [106.3922882080078, 48.16516946195868],
              [106.37580871582031, 48.211862417203214],
              [106.35589599609375, 48.25851283439989]
            ]
          ]
        }
      },
      {
        type: "Feature",
        properties: {
          TYPE: "States",
          color: "#FF6348",
          aimag_city_ID: 40163,
          name: "Өмнөговь",
          schedule_type: "Y",
          sub_coverage: "81",
          ebs_coverage: "100"
        },
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [99.59687948226929, 42.57930224162034],
              [99.5965576171875, 42.73289174571287],
              [99.580078125, 42.85985981506279],
              [99.49218749999999, 43.203173782105814],
              [99.4482421875, 43.51668853502909],
              [99.44549560546874, 43.8543357707896],
              [99.41528320312499, 44.050089820756796],
              [99.45373535156249, 44.10730980734024],
              [99.55810546875, 44.125056482685146],
              [99.64324951171875, 44.14082683077555],
              [99.7833251953125, 44.14476875978378],
              [99.91790771484375, 44.07377376789347],
              [100.01678466796875, 44.04219306625442],
              [100.23101806640625, 43.8503744993026],
              [100.40130615234375, 43.810747313446996],
              [100.58258056640625, 43.83254552160589],
              [101.15936279296875, 43.83650797709095],
              [101.2774658203125, 43.84839376489157],
              [101.4093017578125, 43.89789239125797],
              [101.74713134765625, 43.99676629896825],
              [101.8077278137207, 44.04897940326519],
              [101.80755615234375, 44.050089820756796],
              [102.06298828125, 44.11716972942086],
              [102.3870849609375, 44.156592967556605],
              [102.996826171875, 44.15462243076731],
              [102.93365478515625, 44.264871151101985],
              [102.93365478515625, 44.314022698837775],
              [102.9638671875, 44.36902359940364],
              [103.03802490234375, 44.439663223436106],
              [103.07922363281249, 44.555249259710656],
              [103.2440185546875, 44.80132682904856],
              [103.31817626953125, 44.824708282300236],
              [103.35113525390625, 44.88506649401471],
              [103.5736083984375, 44.96674121705534],
              [103.71093749999999, 45.06770141120143],
              [103.7823486328125, 45.17041997262667],
              [103.7823486328125, 45.17235628126675],
              [104.01031494140625, 45.18397275067566],
              [104.10919189453125, 45.20139301126898],
              [104.16824340820312, 45.097760877277096],
              [104.20944213867186, 44.94924926661153],
              [104.26712036132812, 44.83249999349062],
              [104.359130859375, 44.77403648591524],
              [104.71481323242188, 44.715513732021336],
              [104.81781005859374, 44.68525376315066],
              [104.99633789062499, 44.66962952692013],
              [105.12680053710938, 44.687206496456966],
              [105.42068481445312, 44.687206496456966],
              [105.50445556640625, 44.67744217165251],
              [105.5731201171875, 44.55916341529184],
              [105.7159423828125, 44.41122141189896],
              [105.74066162109375, 44.34644018791789],
              [105.76263427734375, 44.31205742666618],
              [105.80520629882812, 44.288469027276506],
              [105.96176147460938, 44.19303602884215],
              [106.06063842773438, 44.17826452922576],
              [106.13067626953125, 44.18220395771566],
              [106.22817993164061, 44.19795903948531],
              [106.292724609375, 44.21863119293724],
              [106.3641357421875, 44.265854585146684],
              [106.42318725585936, 44.29240108529005],
              [106.50421142578125, 44.308126684886126],
              [106.57699584960938, 44.351350365612305],
              [106.68136596679686, 44.38865427337759],
              [106.78848266601562, 44.43083793524685],
              [106.93130493164062, 44.457309801319305],
              [107.18536376953125, 44.491607329696045],
              [107.24716186523438, 44.50923820945519],
              [107.29660034179686, 44.51609322284931],
              [107.33779907226562, 44.512176171071054],
              [107.41333007812499, 44.49258696288604],
              [107.45040893554688, 44.491607329696045],
              [107.49160766601561, 44.49650533109348],
              [107.57812499999999, 44.520989167323734],
              [107.68730163574217, 44.554270679675696],
              [107.68798828125, 44.555249259710656],
              [107.64816284179686, 44.36313311380771],
              [107.64678955078125, 44.30419567985762],
              [107.7923583984375, 44.109281923355645],
              [107.83355712890625, 43.98886243884903],
              [107.85552978515625, 43.90778718292443],
              [107.95440673828125, 43.69766549666678],
              [107.9571533203125, 43.61619382369188],
              [107.93792724609375, 43.52266348752663],
              [107.9296875, 43.31118965238512],
              [107.95166015624999, 43.257205668363206],
              [108.00384521484375, 43.17113228474479],
              [108.017578125, 43.0287452513488],
              [108.0340576171875, 42.93631775765237],
              [108.0120849609375, 42.85381962412133],
              [108.00933837890625, 42.70060440808085],
              [107.9791259765625, 42.589488572714245],
              [107.98393249511719, 42.42877840024783],
              [107.9791259765625, 42.412304442420684],
              [107.97775268554688, 42.412304442420684],
              [107.95303344726562, 42.412304442420684],
              [107.91732788085938, 42.40115038362433],
              [107.82806396484375, 42.4112905190282],
              [107.56301879882812, 42.4112905190282],
              [107.4957275390625, 42.45588764197166],
              [107.4407958984375, 42.4639928001706],
              [107.2869873046875, 42.4112905190282],
              [107.24853515625, 42.362602921719954],
              [107.03979492187499, 42.313877566161864],
              [106.7706298828125, 42.293564192170095],
              [105.58959960937499, 41.898188430430444],
              [105.281982421875, 41.74672584176937],
              [105.216064453125, 41.74262728637672],
              [105.0018310546875, 41.582579601430346],
              [104.9139404296875, 41.64828831259535],
              [104.6722412109375, 41.64007838467894],
              [104.51293945312499, 41.65649719441145],
              [104.5184326171875, 41.87774145109676],
              [104.0679931640625, 41.80817277478235],
              [103.8482666015625, 41.795888098191426],
              [103.37585449218749, 41.89409955811395],
              [102.7056884765625, 42.14304156290942],
              [102.535400390625, 42.15933157601718],
              [102.4365234375, 42.14304156290942],
              [102.073974609375, 42.224449701009725],
              [101.7938232421875, 42.50450285299051],
              [101.62902832031249, 42.52069952914966],
              [100.8599853515625, 42.67031977251908],
              [100.32714843749999, 42.69051116998241],
              [100.2667236328125, 42.63799988907408],
              [99.9700927734375, 42.64204079304428],
              [99.5965576171875, 42.5808940890009],
              [99.59687948226929, 42.57930224162034]
            ]
          ]
        }
      },
      {
        type: "Feature",
        properties: {
          TYPE: "States",
          color: "#5F27CD",
          aimag_city_ID: 40270,
          name: "Увс",
          schedule_type: "Y",
          sub_coverage: "81",
          ebs_coverage: "81"
        },
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [90.02523154020308, 50.00028466848644],
              [90.02522617578506, 50.00028811664734],
              [90.00823974609375, 50.027152124306234],
              [90.00274658203125, 50.055375373800004],
              [90.06591796875, 50.0923932109388],
              [90.17303466796875, 50.10648772767335],
              [90.23895263671874, 50.10296448723353],
              [90.28564453124999, 50.1135334310997],
              [90.33233642578124, 50.15578588538455],
              [90.39825439453125, 50.198001033269506],
              [90.46142578125, 50.194484528931746],
              [90.48614501953125, 50.21206446065373],
              [90.48614501953125, 50.23051642081693],
              [90.5438232421875, 50.24281376239237],
              [90.59738159179686, 50.23842221881728],
              [90.64682006835938, 50.22436656050062],
              [90.67291259765625, 50.21030675884515],
              [90.7305908203125, 50.24457026654082],
              [90.70724487304688, 50.31390041107301],
              [90.77728271484375, 50.33932538402765],
              [90.79788208007812, 50.327052957438326],
              [90.82672119140625, 50.356851923320505],
              [90.85006713867188, 50.3454604086048],
              [90.87890625, 50.35422335480388],
              [90.9063720703125, 50.34721312721887],
              [90.90362548828125, 50.39451208023374],
              [90.90499877929688, 50.41289338526561],
              [90.97366333007812, 50.433017111356605],
              [91.09451293945312, 50.4373907029876],
              [91.1590576171875, 50.41989391200375],
              [91.29089355468749, 50.467994531475725],
              [91.32247924804686, 50.469742723755154],
              [91.40899658203125, 50.4478856743724],
              [91.39801025390625, 50.47498691278631],
              [91.40625, 50.48809484049477],
              [91.44332885742188, 50.49595785216966],
              [91.45431518554686, 50.54659920621419],
              [91.50375366210938, 50.56230444080573],
              [91.52984619140625, 50.582364626580485],
              [91.63421630859374, 50.58410858688503],
              [91.64657592773438, 50.58846820509024],
              [91.65069580078125, 50.641623049601776],
              [91.724853515625, 50.697327508282626],
              [91.80038452148438, 50.726024296594694],
              [91.85531616210938, 50.73558598902108],
              [91.95281982421875, 50.725154955049604],
              [92.07229614257812, 50.6964576343863],
              [92.27142333984375, 50.73819338460293],
              [92.30438232421874, 50.764259357116465],
              [92.29339599609375, 50.79725542144862],
              [92.32086181640625, 50.84237020615857],
              [92.30438232421874, 50.877044231111014],
              [92.35107421874999, 50.88397594225127],
              [92.4005126953125, 50.86317771513582],
              [92.40325927734375, 50.83196297053503],
              [92.4224853515625, 50.82155341392255],
              [92.4224853515625, 50.80419899467617],
              [92.4884033203125, 50.78510168548186],
              [92.56256103515625, 50.79725542144862],
              [92.61199951171875, 50.76078473271486],
              [92.603759765625, 50.74340774029213],
              [92.59277343749999, 50.726024296594694],
              [92.65045166015625, 50.7051556473569],
              [92.76031494140625, 50.73297844827752],
              [92.75482177734375, 50.767733723505344],
              [92.779541015625, 50.80767039437801],
              [92.90313720703125, 50.80246319809847],
              [92.92510986328125, 50.7903108164132],
              [92.95257568359375, 50.79899141148548],
              [93.0157470703125, 50.792047064406844],
              [93.021240234375, 50.71559113343383],
              [92.95806884765625, 50.666872321810715],
              [93.0047607421875, 50.60764573009064],
              [93.0487060546875, 50.61810290492478],
              [93.1365966796875, 50.583236614805905],
              [93.1365966796875, 50.60067298872855],
              [93.197021484375, 50.59892964194682],
              [93.251953125, 50.59021193935192],
              [93.2574462890625, 50.61287460808086],
              [93.31512451171875, 50.61287460808086],
              [93.33709716796875, 50.623330620643436],
              [93.35357666015625, 50.61287460808086],
              [93.416748046875, 50.62158811330541],
              [93.41125488281249, 50.63726835499671],
              [93.51837158203125, 50.61810290492478],
              [93.52111816406249, 50.60241627093303],
              [93.64471435546875, 50.60764573009064],
              [93.69415283203125, 50.58498054281802],
              [93.76007080078125, 50.59544275464843],
              [93.90289306640624, 50.59195560903164],
              [93.94958496093749, 50.581492622208735],
              [93.98529052734375, 50.60241627093303],
              [94.0374755859375, 50.59195560903164],
              [94.23797607421874, 50.59021193935192],
              [94.33135986328124, 50.543108443488386],
              [94.38079833984375, 50.226123744371364],
              [94.41375732421875, 50.20151727860476],
              [94.4659423828125, 50.199759188312086],
              [94.51263427734375, 50.17162093714734],
              [94.537353515625, 50.1135334310997],
              [94.58953857421875, 50.064191736659104],
              [94.60052490234375, 50.03773778732018],
              [94.76531982421875, 50.06066538593667],
              [94.9932861328125, 50.044793599665226],
              [95.01251220703125, 49.9812539506987],
              [95.08941650390625, 49.96182229868411],
              [95.42999267578125, 49.956521395491166],
              [95.50466537475585, 49.90668648593428],
              [95.50477266311646, 49.90668648593428],
              [95.49316406249999, 49.89109559573688],
              [95.49247741699219, 49.86498893140621],
              [95.49728393554688, 49.84728146865948],
              [95.44990539550781, 49.76618706168036],
              [95.41007995605469, 49.71648848390612],
              [95.42655944824219, 49.628059153387824],
              [95.45677185058594, 49.54481568504451],
              [95.47531127929688, 49.50202588554],
              [95.50483703613281, 49.47615556684203],
              [95.52818298339844, 49.46098385110948],
              [95.57624816894531, 49.449378780418314],
              [95.63117980957031, 49.41856738491886],
              [95.66551208496094, 49.39086527280011],
              [95.72593688964844, 49.359122687528746],
              [95.66070556640625, 49.2337407796959],
              [95.58654785156249, 49.19247423824004],
              [95.54809570312499, 49.10983779052439],
              [95.526123046875, 48.91708480384719],
              [95.50140380859374, 48.85387273165656],
              [95.44921875, 48.821332549646634],
              [95.32562255859375, 48.823140892101684],
              [95.23223876953125, 48.77067246880509],
              [95.11962890625, 48.70727541512677],
              [95.0592041015625, 48.68733411186308],
              [94.89166259765625, 48.68733411186308],
              [94.72686767578125, 48.680080770292875],
              [94.7021484375, 48.7181491602648],
              [94.537353515625, 48.71452483966839],
              [94.37530517578125, 48.73445537176822],
              [94.2132568359375, 48.777912755501845],
              [94.09790039062499, 48.79058074474321],
              [94.01275634765625, 48.741700879765396],
              [93.90838623046875, 48.70908786918211],
              [93.790283203125, 48.68733411186308],
              [93.71063232421875, 48.65468584817256],
              [93.67767333984375, 48.600225060468915],
              [93.66668701171875, 48.531157010976706],
              [93.60076904296875, 48.515694668649466],
              [93.57330322265625, 48.510236244324055],
              [93.50738525390625, 48.48475582205773],
              [93.45108032226561, 48.449243890328724],
              [93.42950463294981, 48.42428816283862],
              [93.42950463294981, 48.42429528259198],
              [93.37177276611328, 48.43284538647477],
              [93.3343505859375, 48.43990650238739],
              [93.3120346069336, 48.447649823664435],
              [93.27323913574219, 48.463815894066066],
              [93.25675964355469, 48.47610776896956],
              [93.22071075439453, 48.51774142617034],
              [93.19015502929688, 48.57183678756225],
              [93.16852569580078, 48.59409454371403],
              [93.15067291259766, 48.60703587322351],
              [93.12835693359375, 48.61861214713695],
              [93.08269500732422, 48.63109338958398],
              [93.05591583251953, 48.63563125791999],
              [93.0270767211914, 48.63676566123227],
              [92.99892425537108, 48.635404374196476],
              [92.97351837158203, 48.63063958030254],
              [92.96012878417969, 48.62587433642767],
              [92.94158935546875, 48.61588855676401],
              [92.91275024414061, 48.590007119230734],
              [92.88768768310547, 48.57342695234659],
              [92.86571502685545, 48.564339622814806],
              [92.8231430053711, 48.557295819191374],
              [92.7685546875, 48.56024979174331],
              [92.64907836914062, 48.59568400838304],
              [92.59277343749999, 48.60567378414743],
              [92.47329711914062, 48.62020084032983],
              [92.36480712890625, 48.62383195130112],
              [92.23297119140625, 48.62110864256238],
              [92.17117309570312, 48.748945343432936],
              [92.15194702148438, 48.77157756174562],
              [92.12310791015625, 48.78243740444988],
              [92.08053588867188, 48.78243740444988],
              [92.03521728515625, 48.77881771792104],
              [91.99264526367186, 48.783342285295475],
              [91.95968627929688, 48.8032455364465],
              [91.94732666015625, 48.827661462789415],
              [91.93359375, 48.89812957181126],
              [91.93634033203125, 48.935130721045326],
              [91.94183349609375, 48.96939999849952],
              [91.82647705078125, 48.96128571799032],
              [91.73171997070312, 48.943249256234296],
              [91.66305541992188, 48.915279853443806],
              [91.59576416015625, 48.8583905296204],
              [91.549072265625, 48.80686346108517],
              [91.45156860351562, 48.738983936650705],
              [91.33827209472655, 48.671855747342164],
              [91.33822917938232, 48.671919512374565],
              [91.31977558135986, 48.77369880939393],
              [91.2744140625, 48.8737479930069],
              [91.20849609375, 48.942347261978476],
              [91.11785888671875, 48.97661158387714],
              [91.02447509765625, 49.04506962208049],
              [91.00250244140624, 49.09904896047899],
              [90.9393310546875, 49.1888842152458],
              [90.8953857421875, 49.28393181795616],
              [90.81298828125, 49.30721745093609],
              [90.74432373046875, 49.30005381244688],
              [90.6646728515625, 49.33407182046059],
              [90.62072753906249, 49.40203735979645],
              [90.59051513671875, 49.42884000063522],
              [90.59051513671875, 49.47169378524674],
              [90.5108642578125, 49.5822260446217],
              [90.40924072265625, 49.648069803718805],
              [90.3021240234375, 49.692508478893274],
              [90.21697998046875, 49.742231602580716],
              [90.16754150390625, 49.807859081298325],
              [90.12908935546874, 49.917630270866894],
              [90.07965087890625, 49.97418880321091],
              [90.04600524902344, 49.983461596534674],
              [90.02523154020308, 50.00028466848644]
            ]
          ]
        }
      },
      {
        type: "Feature",
        properties: {
          TYPE: "States",
          color: "#3867D6",
          aimag_city_ID: 40266,
          name: "Өвөрхангай",
          schedule_type: "Y",
          sub_coverage: "95",
          ebs_coverage: "94"
        },
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [101.28849506378174, 46.826199106709744],
              [101.41342163085938, 46.84798223530896],
              [101.62628173828125, 46.85173907873366],
              [101.77047729492188, 46.862069043222895],
              [101.81991577148438, 46.87239702052007],
              [101.84738159179686, 46.88460024993555],
              [101.87759399414062, 46.92963428624288],
              [101.92291259765625, 46.961510504873075],
              [102.00256347656249, 46.98118927533914],
              [102.09869384765625, 46.99524110694596],
              [102.14263916015625, 47.01116205043071],
              [102.26486206054688, 47.02614213366051],
              [102.29644775390625, 47.02614213366051],
              [102.32940673828125, 47.01116205043071],
              [102.40493774414062, 46.95776134668866],
              [102.46536254882812, 46.930572093016316],
              [102.51205444335938, 46.953074529521054],
              [102.54226684570312, 47.00179736417431],
              [102.557373046875, 47.080409706315635],
              [102.58621215820312, 47.12901644660399],
              [102.64251708984374, 47.15517092451596],
              [102.68783569335938, 47.18877920473518],
              [102.72628784179688, 47.23915174981453],
              [102.72628784179688, 47.25779610603277],
              [102.72079467773436, 47.28202395793543],
              [102.68783569335938, 47.30903424774781],
              [102.65350341796875, 47.333238640473475],
              [102.6397705078125, 47.366734109127115],
              [102.65075683593749, 47.39091206104779],
              [102.68096923828124, 47.40764414848437],
              [102.71392822265625, 47.41322033016902],
              [102.755126953125, 47.37975438400816],
              [102.843017578125, 47.37231462056695],
              [102.886962890625, 47.357431944587034],
              [102.952880859375, 47.32579231609051],
              [103.03802490234375, 47.31275872224939],
              [103.13690185546875, 47.23262467463881],
              [103.1890869140625, 47.204642388766935],
              [103.34701538085938, 47.18737928434322],
              [103.44589233398436, 47.18457933279251],
              [103.52073669433594, 47.18737928434322],
              [103.59214782714844, 47.201843347946806],
              [103.63746643066406, 47.219101747038245],
              [103.67729187011719, 47.250805241535076],
              [103.67712020874023, 47.25068871930782],
              [103.77685546875, 47.26338813315705],
              [103.86474609375, 47.25593196573788],
              [103.93890380859375, 47.26432008025478],
              [104.04876708984375, 47.27922900257082],
              [104.14764404296874, 47.29809208596965],
              [104.14833068847656, 47.29879058949732],
              [104.1448974609375, 47.25919416818626],
              [104.15313720703125, 47.21583707523794],
              [104.17098999023436, 47.1607737815166],
              [104.20120239257811, 47.124344650121884],
              [104.249267578125, 47.09537035351024],
              [104.31106567382811, 47.05608969226366],
              [104.33578491210938, 47.034566583590426],
              [104.3536376953125, 46.997114405385986],
              [104.35638427734375, 46.95869866086379],
              [104.34539794921875, 46.903369029728054],
              [104.30145263671875, 46.89680070399431],
              [104.2657470703125, 46.87990702860919],
              [104.23416137695312, 46.853617401905105],
              [104.32342529296875, 46.76996843356982],
              [104.34814453125, 46.73233101286786],
              [104.3756103515625, 46.70408569832255],
              [104.39208984375, 46.65697731621612],
              [104.39208984375, 46.569246469250054],
              [104.403076171875, 46.52863469527167],
              [104.43878173828125, 46.50595444552051],
              [104.56512451171875, 46.443534945303284],
              [104.60769653320311, 46.417979059090115],
              [104.6531867980957, 46.380570155101864],
              [104.65576171875, 46.3810438458062],
              [104.6063232421875, 46.22165245637913],
              [104.5513916015625, 46.137976523476574],
              [104.43603515624999, 46.12274903582433],
              [104.3096923828125, 46.13036330589103],
              [104.2437744140625, 46.01603873833416],
              [104.1888427734375, 45.98169518512228],
              [104.12841796875, 45.81348649679971],
              [104.073486328125, 45.74836030216746],
              [103.8262939453125, 45.74836030216746],
              [103.71093749999999, 45.7176863579072],
              [103.623046875, 45.625563438215956],
              [103.623046875, 45.521743896993634],
              [103.6834716796875, 45.413876460821086],
              [103.77685546875, 45.37144349133922],
              [103.798828125, 45.33284041773058],
              [103.7823486328125, 45.27488643704894],
              [103.7823486328125, 45.168967697945575],
              [103.7823486328125, 45.17041997262667],
              [103.71093749999999, 45.06770141120143],
              [103.5736083984375, 44.96674121705534],
              [103.35113525390625, 44.88506649401471],
              [103.31817626953125, 44.824708282300236],
              [103.2440185546875, 44.80132682904856],
              [103.07922363281249, 44.555249259710656],
              [103.03802490234375, 44.439663223436106],
              [102.9638671875, 44.36902359940364],
              [102.93365478515625, 44.314022698837775],
              [102.93365478515625, 44.264871151101985],
              [102.996826171875, 44.15462243076731],
              [102.3870849609375, 44.156592967556605],
              [102.06298828125, 44.11716972942086],
              [101.80755615234375, 44.050089820756796],
              [101.80789947509764, 44.048917712794136],
              [101.7828369140625, 44.083146046165986],
              [101.68395996093749, 44.382765762252404],
              [101.62353515625, 44.535674532413196],
              [101.5960693359375, 44.574817404670306],
              [101.53564453124999, 44.64911632343077],
              [101.5521240234375, 44.766236875162335],
              [101.5850830078125, 44.809121700077355],
              [101.5576171875, 44.867549659447214],
              [101.3653564453125, 44.94536144236941],
              [101.1895751953125, 44.999767019181306],
              [101.129150390625, 45.089035564831036],
              [101.151123046875, 45.47939202177826],
              [101.2335205078125, 45.55252525134013],
              [101.2445068359375, 45.6178796835697],
              [101.3214111328125, 45.75985868785574],
              [101.29394531249999, 45.84410779560204],
              [101.27197265625, 45.96642454131025],
              [101.2994384765625, 46.06179698466216],
              [101.42578124999999, 46.115133713265415],
              [101.49169921875, 46.195042108660154],
              [101.4971923828125, 46.3810438458062],
              [101.524658203125, 46.46056554578543],
              [101.49993896484375, 46.50689964492765],
              [101.47933959960938, 46.54658317951774],
              [101.49856567382812, 46.580574564531695],
              [101.436767578125, 46.638122462379656],
              [101.28851652145386, 46.82616974343502],
              [101.28849506378174, 46.826199106709744]
            ]
          ]
        }
      },
      {
        type: "Feature",
        properties: {
          TYPE: "States",
          color: "#5F27CD",
          aimag_city_ID: 40264,
          name: "Завхан",
          schedule_type: "Y",
          sub_coverage: "97",
          ebs_coverage: "93"
        },
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [93.42950463294981, 48.42428816283862],
              [93.45108032226561, 48.449243890328724],
              [93.50738525390625, 48.48475582205773],
              [93.57330322265625, 48.510236244324055],
              [93.60076904296875, 48.515694668649466],
              [93.66668701171875, 48.531157010976706],
              [93.67767333984375, 48.600225060468915],
              [93.71063232421875, 48.65468584817256],
              [93.790283203125, 48.68733411186308],
              [93.90838623046875, 48.70908786918211],
              [94.01275634765625, 48.741700879765396],
              [94.09790039062499, 48.79058074474321],
              [94.2132568359375, 48.777912755501845],
              [94.37530517578125, 48.73445537176822],
              [94.537353515625, 48.71452483966839],
              [94.7021484375, 48.7181491602648],
              [94.72686767578125, 48.680080770292875],
              [94.89166259765625, 48.68733411186308],
              [95.0592041015625, 48.68733411186308],
              [95.11962890625, 48.70727541512677],
              [95.23223876953125, 48.77067246880509],
              [95.32562255859375, 48.823140892101684],
              [95.44921875, 48.821332549646634],
              [95.50140380859374, 48.85387273165656],
              [95.526123046875, 48.91708480384719],
              [95.54809570312499, 49.10983779052439],
              [95.58654785156249, 49.19247423824004],
              [95.66070556640625, 49.2337407796959],
              [95.72593688964844, 49.359122687528746],
              [95.66551208496094, 49.39086527280011],
              [95.63117980957031, 49.41856738491886],
              [95.57624816894531, 49.449378780418314],
              [95.52818298339844, 49.46098385110948],
              [95.50483703613281, 49.47615556684203],
              [95.47531127929688, 49.50202588554],
              [95.45677185058594, 49.54481568504451],
              [95.42655944824219, 49.628059153387824],
              [95.41007995605469, 49.71648848390612],
              [95.44990539550781, 49.76618706168036],
              [95.49728393554688, 49.84728146865948],
              [95.49247741699219, 49.86498893140621],
              [95.49316406249999, 49.89109559573688],
              [95.50477266311646, 49.90668648593428],
              [95.50479412078857, 49.906714124882555],
              [95.51925659179688, 49.902595746972025],
              [95.52680969238281, 49.89596137883285],
              [95.53573608398438, 49.89773063286785],
              [95.54466247558594, 49.91055578459883],
              [95.55564880371092, 49.916303883745705],
              [95.56594848632812, 49.948568946378046],
              [95.59341430664062, 49.94901078355837],
              [95.65521240234375, 49.960055395800424],
              [95.66070556640625, 49.97242235423708],
              [95.71563720703125, 49.97065584042108],
              [95.723876953125, 49.988318060767966],
              [95.7513427734375, 49.97242235423708],
              [95.78155517578124, 49.97418880321091],
              [95.77880859375, 49.986552130506176],
              [95.767822265625, 50.004208515595614],
              [95.77880859375, 50.03950183762877],
              [95.82550048828125, 50.04126582312201],
              [95.855712890625, 50.04655739071663],
              [95.9051513671875, 50.0236230514132],
              [95.90789794921875, 49.99538113346378],
              [95.9326171875, 49.986552130506176],
              [95.98480224609374, 49.96888926176223],
              [96.02325439453124, 50.004208515595614],
              [96.08642578125, 50.014799234787844],
              [96.141357421875, 49.98478613540783],
              [96.19903564453125, 49.983020075472226],
              [96.26220703125, 49.97772150663492],
              [96.28143310546875, 49.956521395491166],
              [96.31988525390624, 49.94415035164548],
              [96.33087158203125, 49.94415035164548],
              [96.3555908203125, 49.924703719314344],
              [96.34185791015625, 49.91232450346395],
              [96.4324951171875, 49.87162755709988],
              [96.46270751953125, 49.90701815226818],
              [96.50390625, 49.92293545449574],
              [96.51763916015625, 49.94415035164548],
              [96.55609130859375, 49.938847502925306],
              [96.58355712890625, 49.89463439573421],
              [96.60827636718749, 49.87162755709988],
              [96.64672851562499, 49.88755653624285],
              [96.64947509765625, 49.905249238801304],
              [96.690673828125, 49.914093157463206],
              [96.7291259765625, 49.924703719314344],
              [96.822509765625, 49.908787000867136],
              [96.866455078125, 49.89286502817229],
              [96.98181152343749, 49.89463439573421],
              [97.00927734375, 49.85392252759648],
              [97.01202392578125, 49.83266792683253],
              [97.04498291015624, 49.83266792683253],
              [97.1136474609375, 49.81849300131474],
              [97.12909698486328, 49.80852376975379],
              [97.12532043457031, 49.79988210788039],
              [97.12969779968262, 49.79237479715199],
              [97.12971925735474, 49.79240250154501],
              [97.13630676269531, 49.78392421773299],
              [97.12944030761719, 49.74888708373188],
              [97.10334777832031, 49.68451251348889],
              [97.0751953125, 49.64762521193305],
              [97.03674316406249, 49.61827317596852],
              [96.97425842285155, 49.57821916282],
              [96.93168640136719, 49.542587974015504],
              [96.87538146972656, 49.508714260218404],
              [96.88156127929688, 49.439556958940855],
              [96.87881469726562, 49.36806633482156],
              [96.93374633789062, 49.32422691861083],
              [97.02850341796875, 49.28482762264301],
              [97.119140625, 49.224772722794825],
              [97.174072265625, 49.167338606291075],
              [97.27294921875, 49.182601048138054],
              [97.40478515625, 49.17721484130587],
              [97.52700805664062, 49.157460400911745],
              [97.66983032226562, 49.11343354595957],
              [97.76870727539062, 49.06576863405361],
              [97.85934448242188, 49.009050809382046],
              [97.90054321289062, 48.99733908118444],
              [98.09005737304688, 48.98652581047831],
              [98.13812255859375, 48.99824008113872],
              [98.19442749023438, 49.015355983769666],
              [98.29330444335938, 49.01625665778159],
              [98.36746215820312, 49.01985919086641],
              [98.42926025390625, 49.00094298321501],
              [98.4649658203125, 48.96939999849952],
              [98.54736328125, 48.935130721045326],
              [98.76708984374999, 49.03786794532644],
              [98.800048828125, 49.1170290407793],
              [98.97033691406249, 49.106241774469055],
              [99.1021728515625, 49.1170290407793],
              [99.1461181640625, 49.05227025601607],
              [99.16259765625, 48.88639177703194],
              [99.21203613281249, 48.76343113791796],
              [99.1021728515625, 48.63290858589532],
              [99.06372070312499, 48.494767515307295],
              [99.0966796875, 48.35989909002194],
              [99.0742778778076, 48.296270779257405],
              [99.0743637084961, 48.296099474919146],
              [98.953857421875, 48.27588152743497],
              [98.8385009765625, 48.23747967660676],
              [98.7066650390625, 48.206371336358906],
              [98.6627197265625, 48.16608541901253],
              [98.54736328125, 48.111099041065366],
              [98.4814453125, 48.100094697973795],
              [98.35784912109375, 48.10559716402152],
              [98.27545166015625, 48.09459164290992],
              [98.2232666015625, 48.056053763981225],
              [98.18756103515625, 47.98440682947526],
              [98.20678710937499, 47.91450120703987],
              [98.2012939453125, 47.85187391101592],
              [98.17108154296875, 47.787325537803106],
              [98.19305419921875, 47.706065135695745],
              [98.25347900390624, 47.65428791076272],
              [98.32489013671875, 47.63023101663225],
              [98.41651439666748, 47.61481374692148],
              [98.4166431427002, 47.61490053602275],
              [98.40625762939453, 47.563090772707994],
              [98.37432861328125, 47.52461999690649],
              [98.28369140625, 47.4596655525415],
              [98.272705078125, 47.42065432071321],
              [98.2781982421875, 47.359292508710745],
              [98.2562255859375, 47.27922900257082],
              [98.19580078125, 47.2270293988673],
              [98.13812255859375, 47.200910301521674],
              [98.052978515625, 47.172911278266604],
              [97.96234130859375, 47.18971246448421],
              [97.9046630859375, 47.184112659842015],
              [97.86895751953125, 47.15236927446393],
              [97.8607177734375, 47.08882558740757],
              [97.84423828125, 47.05889641405164],
              [97.75360107421874, 46.9933677428342],
              [97.734375, 46.9446372241817],
              [97.7288818359375, 46.85079989251277],
              [97.69866943359375, 46.758679967095574],
              [97.63824462890625, 46.67205646734499],
              [97.56769180297852, 46.597444318795134],
              [97.56769180297852, 46.597532782686486],
              [97.52906799316406, 46.600628927897176],
              [97.50640869140625, 46.60463891014406],
              [97.48374938964844, 46.61147878308617],
              [97.4593734741211, 46.62350543010974],
              [97.44152069091797, 46.63670808339624],
              [97.42538452148438, 46.64872912635656],
              [97.41302490234375, 46.66357496238209],
              [97.39208221435547, 46.693254407491],
              [97.34813690185547, 46.67559003496991],
              [97.2344970703125, 46.60039303734547],
              [97.18231201171875, 46.57868671298067],
              [97.13836669921874, 46.57679879569828],
              [97.0751953125, 46.581518465658014],
              [97.0257568359375, 46.586237724798856],
              [96.9378662109375, 46.60133659339023],
              [96.92413330078125, 46.65791988638765],
              [96.90078735351562, 46.70314393319737],
              [96.85684204101562, 46.74738913515841],
              [96.81976318359375, 46.79911935722295],
              [96.74972534179688, 46.86113003765812],
              [96.73049926757812, 46.90524554642923],
              [96.69342041015624, 46.92682076739938],
              [96.64535522460938, 46.933385414813976],
              [96.59042358398438, 46.922131240914],
              [96.52656555175781, 46.89163931213447],
              [96.49429321289062, 46.87662152939774],
              [96.40296936035156, 46.849860689868386],
              [96.34185791015625, 46.82731489926434],
              [96.30889892578125, 46.89163931213447],
              [96.29310607910156, 46.933385414813976],
              [96.28005981445312, 46.998987638154624],
              [96.28898620605467, 47.05702528260841],
              [96.2896728515625, 47.09116309620141],
              [96.26701354980469, 47.16637604771991],
              [96.23954772949219, 47.201376826785406],
              [96.19903564453125, 47.23402340128701],
              [96.15577697753905, 47.26804770458176],
              [96.10771179199219, 47.29739357321423],
              [96.05552673339844, 47.301584511330795],
              [96.02119445800781, 47.30949982141431],
              [95.98342895507812, 47.332773275955894],
              [95.91682434082031, 47.37789454155521],
              [95.80284118652344, 47.42669366522116],
              [95.75065612792967, 47.43319679984749],
              [95.59478759765624, 47.51349065484327],
              [95.53024291992188, 47.535746978239125],
              [95.24871826171875, 47.568187190520135],
              [95.0701904296875, 47.54223662718361],
              [95.0482177734375, 47.59690318115471],
              [95.01663208007812, 47.63948497925488],
              [94.97131347656249, 47.65613798222679],
              [94.7735595703125, 47.67648444221321],
              [94.67468261718749, 47.669086647137576],
              [94.47006225585938, 47.68388118858139],
              [94.3011474609375, 47.73932336136857],
              [94.23248291015625, 47.77809779004784],
              [94.185791015625, 47.78548011929362],
              [94.0869140625, 47.76702233051035],
              [93.88092041015625, 47.71530566159559],
              [93.65432739257812, 47.66538735632654],
              [93.6143946647644, 47.65159940883532],
              [93.61443758010864, 47.65159940883532],
              [93.51150512695312, 47.7097615426664],
              [93.39340209960938, 47.77348330190447],
              [93.2684326171875, 47.864773955792245],
              [93.18225860595703, 47.96601978044179],
              [93.240966796875, 48.00094957553023],
              [93.30413818359375, 48.056053763981225],
              [93.3892822265625, 48.10926514749487],
              [93.43666076660156, 48.19950665733754],
              [93.48335266113281, 48.3005532009148],
              [93.4891891479492, 48.32429984442754],
              [93.4881591796875, 48.350773448467265],
              [93.4830093383789, 48.36537369040198],
              [93.47305297851562, 48.377689389945736],
              [93.45245361328125, 48.39615735440508],
              [93.42950999736786, 48.42429528259198],
              [93.42950463294981, 48.42428816283862]
            ]
          ]
        }
      }
    ]
  };
  