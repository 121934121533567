

const schedule = [
  {
    name: "Сүхбаатар",
    dateFrom: "2024.02.28",
    dateTo: "8:30-14:00",
    link: "",

  },
  {
    name: "Дорнод",
    dateFrom: "2024.02.28",
    dateTo: "8:30-14:00",
    link: "",
  },
  {
    name: "Хэнтий",
    dateFrom: "2024.02.28",
    dateTo: "8:30-14:00",
    link: "",
  },
  {
    name: "Дундговь",
    dateFrom: "2024.02.29",
    dateTo: "8:30-14:00",
    link: ""
  },
  {
    name: "Дорноговь",
    dateFrom: "2024.02.29",
    dateTo: "8:30-14:00",
    link: ""
  },
  {
    name: "Өмнөговь",
    dateFrom: "2024.02.29",
    dateTo: "8:30-14:00",
    link: ""
  },
  {
    name: "Говьсүмбэр",
    dateFrom: "2024.02.29",
    dateTo: "8:30-14:00",
    link: ""
  },
  {
    name: "Төв",
    dateFrom: "2024.03.01",
    dateTo: "8:30-14:00",
    link: ""
  },
  {
    name: "Орхон",
    dateFrom: "2024.03.01",
    dateTo: "8:30-14:00",
    link: ""
  },
  {
    name: "Булган",
    dateFrom: "2024.03.01",
    dateTo: "8:30-14:00",
    link: ""
  },
  {
    name: "Дархан-Уул",
    dateFrom: "2024.03.01",
    dateTo: "8:30-14:00",
    link: ""
  },
  {
    name: "Сэлэнгэ",
    dateFrom: "2024.03.01",
    dateTo: "8:30-14:00",
    link: ""
  },
  {
    name: "Хөвсгөл",
    dateFrom: "2024.03.04",
    dateTo: "8:30-14:00",
    link: "h"
  },
  {
    name: "Архангай",
    dateFrom: "2024.03.04",
    dateTo: "8:30-14:00",
    link: ""
  },
  {
    name: "Өвөрхангай",
    dateFrom: "2024.03.04",
    dateTo: "8:30-14:00",
    link: ""
  },
  {
    name: "Баянхонгор",
    dateFrom: "2024.03.04",
    dateTo: "8:30-14:00",
    link: ""
  },
  {
    name: "Завхан",
    dateFrom: "2024.03.04",
    dateTo: "8:30-14:00",
    link: ""
  },
  {
    name: "Увс",
    dateFrom: "2024.03.05",
    dateTo: "8:30-14:00",
    link: ""
  },
  {
    name: "Говь-Алтай",
    dateFrom: "2024.03.05",
    dateTo: "8:30-14:00",
    link: ""
  },
  {
    name: "Баян-Өлгий",
    dateFrom: "2024.03.05",
    dateTo: "8:30-14:00",
    link: ""
  },
  {
    name: "Ховд",
    dateFrom: "2024.03.05",
    dateTo: "8:30-14:00",
    link: ""
  },
    {
    name: "Нийслэл",
    dateFrom: "2024.03.06",
    dateTo: "8:30-14:00",
    link: ""
  },
];

export default schedule;
