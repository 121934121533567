import React, { useState } from "react";
import imgTeam1 from "../../../assets/img/team/team-1.jpg";
import { Avatar } from "antd";
import pdfBook from "../../../assets/files/book.pdf";
import bookIntro from "../../../assets/img/book-intro.png";

import bagshQR from "../../../assets/img/QR2023/iform_bagsh.jpeg";
import menejQR from "../../../assets/img/QR2023/iform_menej.jpeg";

import pdfsaid from "../../../assets/pdfs/Ажлын хэсэг ахлагч.pdf";
import imgsaid from "../../../assets/pdf-intro/said.png";

import pdfslabarUnelgee from "../../../assets/pdfs/Салбар уулзалт гүйцэтгэл, үр дүнгийн үнэлгээ.pdf";
import imgslabarUnelgee from "../../../assets/pdf-intro/uulzalt2.png";
//Салбар уулзалт гүйцэтгэл, үр дүнгийн үнэлгээ

import pdf1Tusuv from "../../../assets/pdfs/Нэгдсэн уулзалт төсвийн шинэчлэл, боловсролын салбарын бодлого, эрх зүйн шинэчлэл.pdf";
import tusuvIntro from "../../../assets/pdf-intro/Төсвийн_шинэчлэл,_боловсролын_салбарын_бодлого,_эрх_зүйн_шинэчлэлийн.png";

import pdfHurteemj from "../../../assets/pdfs/Нэгдсэн уулзалт хувьсах зардал, цалин хөлс.pdf";
import imgHurteemj from "../../../assets/pdf-intro/6.guitsetgel.png";

import pdfBagshiinHugjil from "../../../assets/pdfs/Салбар_уулзалт_төсвийн_санхүүжилт,_хувьсах_зардлын_бүрэлдэхүүн_1652585089.pdf";
import imgBagshiinHugjil from "../../../assets/pdf-intro/3.Bagshiin_hugjil.png";

import pdfEruulMend from "../../../assets/pdfs/Нэгдсэн уулзалт эрүүл мэндийн үйлчилгээ, цахим дата багц.pdf";
import imgEruulMend from "../../../assets/pdf-intro/4.Eruul_mend.png";

import pdfTsahim from "../../../assets/pdfs/ЦАХИМ ХЭРЭГЛЭЭ.pdf";
import imgTsahim from "../../../assets/pdf-intro/5.Tsahim hereglee.png";

import pdfGuitsetgel from "../../../assets/pdfs/Нэгдсэн_уулзалт_гүйцэтгэл,_үнэлгээний_журам,_тогтолцоо,_нэмэгдэл.pdf";
import imgGuitsetgel from "../../../assets/pdf-intro/6.guitsetgel.png";

import pdfBEG from "../../../assets/pdfs/Нэгдсэн уулзалт БЕГ-танилцуулга.pdf";
import imgBEG from "../../../assets/pdf-intro/7.Боловсролын ерөнхий газрын бүтэц, чиг үүрэг, үндсэн үйл ажиллагаа.png";

import pdfYosZui from "../../../assets/pdfs/Нэгдсэн сургалт БЕГ багш, удирдах ажилтны ёс зүйн манлайлал.pdf";
import imgYosZui from "../../../assets/pdf-intro/8.Боловсролын байгууллагын багш удирдах ажилтны ёс зүй, манлайлал.png";

import pdfHuuhedHamgaalal from "../../../assets/pdfs/Нэгдсэн сургалт БЕГ хүүхэд хамгаалал.pdf";
import imgHuuhedHamgaalal from "../../../assets/pdf-intro/9.Хүүхэд хамгаалалд хамтын оролцоог нэмэгдүүлэх нь.png";

import pdfUulzalt1 from "../../../assets/pdfs/Салбар_уулзалт_Багшийн_хөгжил,_хичээл_практикийн_1652850907.pdf";
import imgUulzalt1 from "../../../assets/pdf-intro/uulzalt1.png";

import pdfUulzalt2 from "../../../assets/pdfs/Салбар_уулзалт_төсвийн_санхүүжилт,_хувьсах_зардлын_бүрэлдэхүүн_1652585089.pdf";
import imgUulzalt2 from "../../../assets/pdf-intro/uulzalt2.png";

import pdfUulzalt3 from "../../../assets/pdfs/Салбар_уулзалт_төсвийн_санхүүжилт,_хувьсах_зардлын_бүрэлдэхүүн.pdf";
import imgUulzalt3 from "../../../assets/pdf-intro/uulzalt3.png";

import legal1 from "../../../assets/PDF2023/2_2022_07_22_БШУСайдын_А259_Багшийн_эрүүл_мэндийн_үзлэг_оношилгооны.pdf";
import legal2 from "../../../assets/PDF2023/2022-91 Хувьсах зардал.pdf";
import legal3 from "../../../assets/PDF2023/20220512 А170 (1) (1).pdf";
import legal4 from "../../../assets/PDF2023/ЗГ-107 Цалингийн доод тогтоол.pdf";
import legal5 from "../../../assets/PDF2023/Сургуулийн_өмнөх_болон_бага,_дунд_боловсролын,_мэргэжлийн_боловсролын.pdf";
import legal6 from "../../../assets/PDF2023/Төрийн_болон_орон_нутгийн_өмчит_сургуулийн_өмнөх_болон_ерөнхий_боловсролын.pdf";
// import moduleName from '../../../assets/pdfs/';

const materials = [
  {
    introImg: imgsaid,
    src: pdfsaid,
  },
  {
    introImg: bookIntro,
    src: pdfBook,
  },
  {
    introImg: tusuvIntro,
    src: pdf1Tusuv,
  },
  {
    introImg: imgHurteemj,
    src: pdfHurteemj,
  },
  {
    introImg: imgBagshiinHugjil,
    src: pdfBagshiinHugjil,
  },
  {
    introImg: imgEruulMend,
    src: pdfEruulMend,
  },
  {
    introImg: imgTsahim,
    src: pdfTsahim,
  },
  {
    introImg: imgGuitsetgel,
    src: imgGuitsetgel,
  },
  {
    introImg: imgBEG,
    src: pdfGuitsetgel,
  },
  {
    introImg: imgYosZui,
    src: pdfYosZui,
  },
  {
    introImg: imgHuuhedHamgaalal,
    src: pdfHuuhedHamgaalal,
  },
  {
    introImg: imgUulzalt1,
    src: pdfUulzalt1,
  },
  {
    introImg: imgUulzalt2,
    src: pdfUulzalt2,
  },
  {
    introImg: imgUulzalt3,
    src: pdfUulzalt3,
  },
];
const pdfLegal = [
  {
    id: "1",
    name: "БШУСайдын А259 Багшийн эрүүл мэндийн үзлэг оношилгооны тухай баримт бичиг",
    source: legal1,
  },
  {
    id: "2",
    name: "2022-91 Хувьсах зардал",
    source: legal2,
  },
  { id: "3", name: "Заавар батлах тухай", source: legal3 },
  {
    id: "4",
    name: "ЗГ-107 Цалингийн доод тогтоол",
    source: legal4,
  },
  {
    id: "5",
    name: "Сургуулийн өмнөх болон бага, дунд боловсролын мэргэжлийн боловсролын баримт бичиг",
    source: legal5,
  },
  {
    id: "6",
    name: "Төрийн болон орон нутгийн өмчит сургуулийн өмнөх болон ерөнхий боловсролын баримт бичиг",
    source: legal6,
  },
];

export default function Schedule() {
  const [scheduleKey, setScheduleKey] = useState("day-1");

  const eventDays = [
    // {
    //   id: "day-1",
    //   label: "Нэгдсэн уулзалт",
    // },
    // {
    //   id: "day-2",
    //   label: "Салбар уулзалт",
    // },
    {
      id: "day-1",
      label: "4-р сарын уулзалтын материал",
    },
    {
      id: "day-2",
      label: "Эрх зүйн баримт бичиг",
    },
    {
      id: "day-3",
      label: "Санал  асуулга",
    },
  ];

  // console.log("DATE: ", activeDate);

  return (
    <div className="container">
      <div class="section-title" data-aos="fade-up">
        <h2>Хөтөлбөр</h2>
        <p>Төсвийн шинэчлэл 2022 уулзалт</p>
      </div>
      <div className="schedule">
        {/* <h3 class="sub-heading">
          БОЛОВСРОЛЫН САЛБАРЫН САНХҮҮЖИЛТИЙН ШИНЭЧЛЭЛ СЭДЭВТ СУРГАЛТЫН ХӨТӨЛБӨР
        </h3> */}

        <ul
          class="nav nav-tabs "
          role="tablist"
          data-aos="fade-up"
          data-aos-delay="100"
          // className="w-100 d-flex justify-content-center mt-3"
        >
          {eventDays.map((el, i) => (
            <li
              class="nav-item row gy-3 align-middle"
              onClick={() => setScheduleKey(`day-${i + 1}`)}
            >
              <a
                class={`nav-link ${el.id === scheduleKey ? "active" : ""}`}
                role="tab"
                data-bs-toggle="tab"
              >
                {el.label}
              </a>
            </li>
          ))}
          {/* <li class="nav-item" onClick={() => setActiveDate("day1")}>
            <a
              class="nav-link active"
              //href="#"
              role="tab"
              data-bs-toggle="tab"
            >
              2021 оны 11 дүгээр сарын 15-ны өдөр
            </a>
          </li>
          <li class="nav-item" onClick={() => setActiveDate("day2")}>
            <a
              class="nav-link"
              // </li> ./ href="#"
              role="tab"
              data-bs-toggle="tab"
            >
              2021 оны 11 дүгээр сарын 16-ны өдөр
            </a>
          </li> */}
        </ul>

        <div
          class="tab-content row justify-content-center"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          {/* {scheduleKey === "day-1" && (
            <div
              role="tabpanel"
              class="col-xxl-9 tab-pane fade show active"
              id="day-1"
            >
              <div className="schedule-title">
                Нэгдсэн уулзалт /эхний хагас/
              </div>
              <div class="row schedule-item">
                <div class="col-md-2">
                  <time>5 минут</time>
                </div>
                <div class="col-md-10">
                  <div className="event-speaker">
                    <Avatar className="avatar bg-blue-500">ОТ</Avatar>
                    <div className="description">
                      <h4> */}
          {/* <a target="_blank" href={pdfsaid}> */}
          {/* Ажлын хэсгийн бүрэлдэхүүн, уулзалтын зорилго,
                          хөтөлбөрийн танилцуулга */}
          {/* </a> */}
          {/* </h4>
                      <p>Модератор – Орон нутгийн дэд бүрэлдэхүүний төлөөлөл</p>
                    </div>
                  </div>
                </div>
              </div> */}
          {/* <div class="row schedule-item">
                <div class="col-md-2">
                  <time className="mb-5">3 минут</time>
                </div>
                <div class="col-md-10">
                  <h4 className="mb-0">
                    Төсвийн шинэчлэлийн видео танилцуулга
                  </h4>
                </div>
              </div> */}
          {/* <div class="row schedule-item">
                <div class="col-md-2">
                  <time>20 минут </time>
                </div>
                <div class="col-md-10">
                  <div className="event-speaker">
                    <Avatar className="avatar bg-indigo-500">АА</Avatar>
                    <div className="description">
                      <h4>
                        <a target="_blank" href={pdfsaid}>
                          Төсвийн шинэчлэл, боловсролын салбарын бодлого, эрх
                          зүйн шинэчлэлийн талаарх товч мэдээлэл
                        </a>
                      </h4>
                      <p>Ажлын хэсгийн ахлагч </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row schedule-item">
                <div class="col-md-2">
                  <time>15 минут</time>
                </div>
                <div class="col-md-10">
                  <div className="event-speaker">
                    <Avatar className="avatar bg-pink-500">ТГ</Avatar>
                    <div className="description">
                      <h4>
                        <a target="_blank" href={pdfHurteemj}>
                          Хувьсах зардал, цалин хөлсний илтгэл
                        </a>
                      </h4>
                      <p> Ажлын хэсгийн төсөв, санхүү хариуцсан гишүүн </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row schedule-item">
                <div class="col-md-2">
                  <time>15 минут</time>
                </div>
                <div class="col-md-10">
                  <div className="event-speaker">
                    <Avatar className="avatar bg-indigo-500">ХГ</Avatar>
                    <div className="description">
                      <h4>
                        <a target="_blank" href={pdfBagshiinHugjil}>
                          Багшийн хөгжил, хичээл практикийн зардлын шинэчлэлийн
                          танилцуулга
                        </a>
                      </h4>
                      <p>
                        Ажлын хэсгийн багшийн хөгжил, хичээл практик хариуцсан
                        гишүүн
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row schedule-item">
                <div class="col-md-2">
                  <time>15 минут</time>
                </div>
                <div class="col-md-10">
                  <div className="event-speaker">
                    <Avatar className="avatar bg-blue-500">БГ</Avatar>
                    <div className="description">
                      <h4>
                        <a target="_blank" href={pdfEruulMend}>
                          Эрүүл мэндийн үйлчилгээ
                        </a>
                        <a target="_blank" href={pdfTsahim} className="ms-2">
                          Цахим дата багцын танилцуулга
                        </a>
                      </h4>
                      <p>
                        Ажлын хэсгийн багш багц хариуцсан ажлын хэсгийн гишүүн
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row schedule-item">
                <div class="col-md-2">
                  <time>15 минут</time>
                </div>
                <div class="col-md-10">
                  <div className="event-speaker">
                    <Avatar className="avatar bg-blue-500">ҮГ</Avatar>
                    <div className="description">
                      <h4>
                        <a target="_blank" href={pdfGuitsetgel}>
                          Гүйцэтгэл, үнэлгээний журам, тогтолцоо, нэмэгдэл,
                          нэмэгдэл хөлсний танилцуулга
                        </a>
                      </h4>
                      <p>
                        Ажлын хэсгийн үнэлгээ хариуцсан ажлын хэсгийн гишүүн
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row schedule-item">
                <div class="col-md-2">
                  <time>15 минут</time>
                </div>
                <div class="col-md-10">
                  <h4 className="mb-0">Завсарлага </h4>
                </div>
              </div>
              <div class="row schedule-item">
                <div class="col-md-2">
                  <time>15 минут</time>
                </div>
                <div class="col-md-10">
                  <div className="event-speaker">
                    <Avatar className="avatar  bg-green-400">СБ</Avatar>
                    <div className="description">
                      <h4>
                        <a target="_blank" href={pdfBEG}>
                          Боловсролын ерөнхий газрын бүтэц, чиг үүрэг, үндсэн
                          үйл ажиллагаа
                        </a>
                      </h4>
                      <p>БЕГ-ын сургагч багш</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row schedule-item">
                <div class="col-md-2">
                  <time>15 минут </time>
                </div>
                <div class="col-md-10">
                  <div className="event-speaker">
                    <Avatar className="avatar bg-green-400">СБ</Avatar>
                    <div className="description">
                      <h4>
                        <a target="_blank" href={pdfYosZui}>
                          Боловсролын байгууллагын багш удирдах ажилтны ёс зүй,
                          манлайлал
                        </a>
                      </h4>
                      <p>БЕГ-ын сургагч багш</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row schedule-item">
                <div class="col-md-2">
                  <time>15 минут </time>
                </div>
                <div class="col-md-10">
                  <div className="event-speaker">
                    <Avatar className="avatar bg-green-400">СБ</Avatar>
                    <div className="description">
                      <h4>
                        <a target="_blank" href={pdfHuuhedHamgaalal}>
                          Хүүхэд хамгаалалд хамтын оролцоог нэмэгдүүлэх нь
                        </a>
                      </h4>
                      <p>БЕГ-ын сургагч багш</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row schedule-item">
                <div class="col-md-2">
                  <time>15 минут </time>
                </div>
                <div class="col-md-10">
                  <div className="event-speaker">
                    <Avatar className="avatar bg-blue-500">ОТ</Avatar>
                    <div className="description">
                      <h4>Асуулт, хариулт хэлэлцүүлэг</h4>
                      <p>Модератор – Орон нутгийн дэд бүрэлдэхүүний төлөөлөл</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row schedule-item">
                <div class="col-md-2">
                  <time className="mb-5">-</time>
                </div>
                <div class="col-md-10">
                  <h4 className="mb-0">Нэгдсэн уулзалтын төгсгөл</h4>
                </div>
              </div>
            </div>
          )} */}

          {/* {scheduleKey === "day-2" && (
            <div
              role="tabpanel"
              class="col-xxl-9 tab-pane fade show active"
              id="day-1"
            >
              <div className="schedule-title">
                
                Салбар уулзалт /сүүлийн хагас/
              </div>
              <div className="schedule-title">Баг тус бүрээр</div>
              <div class="row schedule-item">
                <div class="col-md-2">
                  <time>1 цаг</time>
                </div>
                <div class="col-md-10">
                  <div className="event-speaker">
                    <Avatar className="avatar bg-blue-500">ХГ</Avatar>
                    <div className="description">
                      <h4><a target="_blank" href={pdfUulzalt1}>Багшийн хөгжил, хичээл практикийн зардал</a></h4>
                      <p>
                        Ажлын хэсгийн багшийн хөгжил, хичээл практик хариуцсан
                        гишүүн
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row schedule-item">
                <div class="col-md-2">
                  <time>1 цаг</time>
                </div>
                <div class="col-md-10">
                  <div className="event-speaker">
                    <Avatar className="avatar bg-blue-500">ТГ</Avatar>
                    <div className="description">
                      <h4><a target="_blank" href={pdfUulzalt2}>Төсвийн санхүүжилт, хувьсах зардлын бүрэлдэхүүн</a></h4>
                      <p>Ажлын хэсгийн төсөв, санхүү хариуцсан гишүүн</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row schedule-item">
                <div class="col-md-2">
                  <time>1 цаг</time>
                </div>
                <div class="col-md-10">
                  <div className="event-speaker">
                    <Avatar className="avatar bg-blue-500">БГ</Avatar>
                    <div className="description">
                      <h4> */}
          {/* <a target="_blank" href={pdfUulzalt3}> */}
          {/* Эрүүл мэндийн үйлчилгээ,
                         Цахим дата багцын танилцуулга */}
          {/* </a> */}

          {/* </h4>
                      <p>
                        Ажлын хэсгийн багш багц хариуцсан ажлын хэсгийн гишүүн
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row schedule-item">
                <div class="col-md-2">
                  <time>1 цаг</time>
                </div>
                <div class="col-md-10">
                  <div className="event-speaker">
                    <Avatar className="avatar bg-blue-500">ҮГ</Avatar>
                    <div className="description">
                      <h4>
                        <a target="_blank" href={pdfslabarUnelgee}>
                        Гүйцэтгэл, үр дүнгийн үнэлгээний видео танилцуулга
                        </a>
                      </h4>
                      <p>
                        Ажлын хэсгийн үнэлгээ хариуцсан ажлын хэсгийн гишүүн
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="schedule-title">Бүх багт ижил сэдвээр</div>
              <div class="row schedule-item">
                <div class="col-md-2">
                  <time>15 минут </time>
                </div>
                <div class="col-md-10">
                  <div className="event-speaker">
                    <Avatar className="avatar bg-pink-500">СБ</Avatar>
                    <div className="description">
                      <h4>Мэргэжлийн хөгжлийн бүлгийн ажиллах аргачлал</h4>
                      <p>БЕГ-ын сургагч багш </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row schedule-item">
                <div class="col-md-2">
                  <time>45 минут </time>
                </div>
                <div class="col-md-10">
                  <div className="event-speaker">
                    <Avatar className="avatar bg-pink-500">СБ</Avatar>
                    <div className="description">
                      <h4>
                        Мэргэжлийн хөгжлийн бүлгийн ажиллах аргачлалыг уншиж,
                        судлах, хэлэлцэх
                      </h4>
                      <p>БЕГ-ын сургагч багш </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row schedule-item">
                <div class="col-md-2">
                  <time>1 цаг </time>
                </div>
                <div class="col-md-10">
                  <div className="event-speaker">
                    <Avatar className="avatar bg-pink-500">СБ</Avatar>
                    <div className="description">
                      <h4>
                        Судлагдахуун ба суралцахуйн асуудлыг илрүүлэх,
                        мэргэжлийн хөгжлийн бүлгийн бүтэц, зохион байгуулалтыг
                        тодорхойлох
                      </h4>
                      <p>БЕГ-ын сургагч багш </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row schedule-item">
                <div class="col-md-2">
                  <time>30 минут</time>
                </div>
                <div class="col-md-10">
                  <div className="event-speaker">
                    <Avatar className="avatar bg-pink-500">СБ</Avatar>
                    <div className="description">
                      <h4>Дүгнэлт, ярилцлага</h4>
                      <p>БЕГ-ын сургагч багш </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row schedule-item">
                <div class="col-md-2">
                  <time>10-15 минут</time>
                </div>
                <div class="col-md-10">
                  <div className="event-speaker">
                    <Avatar className="avatar bg-blue-500">БГ</Avatar>
                    <div className="description">
                      <h4>
                        Нэгдсэн цахим санал асуулга хүсэлтийн бүртгэл
                        /Оролцогчид гар утсаар судалгааг бөглөнө/
                      </h4>
                      <p>Ажлын хэсгийн бүх гишүүд</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )} */}

          {scheduleKey === "day-1" && (
            <div
              role="tabpanel"
              class="question-tab col-lg-9 tab-pane fade show active"
            >
              <div className="mt-4 row gy-4">
                {/* <a target="_blank" href={pdfBook}>
                  <img src={bookIntro} width={180}></img>
                </a> */}
                {materials.map((el, i) => (
                  <div className="col-sm-3" key={i}>
                    <a target="_blank" href={el.src}>
                      <img src={el.introImg} className="w-100"></img>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          )}

          {scheduleKey === "day-2" && (
            <div
              role="tabpanel"
              class="question-tab  tab-pane fade show active"
            >
              <div className="d-flex flex-column">
                {/* <a target="_blank" href={pdfBook}>
                  <img src={bookIntro} width={180}></img>
                </a> */}
                {pdfLegal.map((el, i) => (
                  <div
                    className="col-sm-2 w-100 p-2"
                    class="border rounded my-3 p-2"
                    key={i}
                  >
                    <h5 class="text-primary font-weight-bold">
                      {el.id}. {el.name}
                    </h5>
                    <div class="d-flex flex-row-reverse ">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        class="border rounded px-2 "
                        href={el.source}
                      >
                        Дэлгэрэнгүй
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {scheduleKey === "day-3" && (
            <div
              role="tabpanel"
              class="question-tab row gy-2 tab-pane fade show active"
            >
              <div class="d-flex flex-row justify-content-around mt-3">
                <div className="d-flex flex-column w-25 mx-2 text-center">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={
                      "https://iform.esis.edu.mn/form/63719ae9f90a4528a713d4ef"
                    }
                  >
                    <div style={{ height: "80px" }}>
                      <h5>Захирал, менежерийн хариулах асуумж</h5>
                    </div>
                  </a>
                  <img src={menejQR} alt="" />
                </div>
                <div className="d-flex flex-column w-25 mx-2 text-center">
                  <div>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={
                        "https://iform.esis.edu.mn/form/63719b7cf90a4528a713d4f0"
                      }
                    >
                      <div style={{ height: "80px" }}>
                        <h5>Багшийн хариулах асуумж</h5>
                      </div>
                    </a>
                  </div>
                  <img src={bagshQR} alt="" />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
